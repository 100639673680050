import React, { useState } from "react";
import tw from "twin.macro";
import PropTypes from "prop-types";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, TextField } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ImportedButton = tw(PrimaryButtonBase)`bg-primary-500 font-bold`

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ScheduleDialog(props) {
  const {
    open,
    setOpen,
    name,
    setName,
    setEmail,
    email,
    setOpenCalendly,
    setUrl,
  } = props;

  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [notCompany, setNotCompany] = useState(false);

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  const notAllowedDomains = [
    "gmail.com",
    "hotmail.com",
    "outlook.com",
    "aol.com",
    "yahoo.com",
    "icloud.com",
    "live.com",
    "msn.com",
    "mail.com",
    "yandex.com",
  ];
  const emailDomain = email.split("@")[1];

  const handleClose = () => {
    setOpen(false);
    setNotCompany(false);
    setInvalidEmail(false);
    setInvalidName(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const onNext = () => {
    if (name === "") {
      // mostrar mensaje de error por falta de nombre
      setInvalidName(true);
      setSnackbarMessage("Please enter a name.");
      setOpenSnackbar(true);
      setSnackbarSeverity("warning");
      return;
    }

    setInvalidName(false);

    if (!validateEmail(email)) {
      // mostrar mensaje de error
      setInvalidEmail(true);
      setSnackbarMessage("Invalid email. Please Enter a valid one.");
      setOpenSnackbar(true);
      setSnackbarSeverity("warning");
      return;
    }
    if (notAllowedDomains.includes(emailDomain)) {
      // Mostrar un mensaje de error
      setNotCompany(true);
    } else {
      // Mostrar el popup para seleccionar la fecha de la cita
      setOpenCalendly(true);
      handleClose();
      const prefill = {
        email: email,
        full_name: name,
      };
      setUrl(
        `https://calendly.com/d/cpqt-ffk-q2sname=${name}&email=${email}`
      );
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Hire Now
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            padding={1.5}
          >
            {notCompany ? (
              <Typography variant="h6">
                Unfortunately, we only accept scheduling requests from companies
                and not from individuals.
              </Typography>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography>
                    In order for you to begin the hiring process with us, we
                    will require some of your personal information. Could you
                    please provide us with the necessary details?
                  </Typography>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                  <TextField
                    id="name"
                    label="Name"
                    fullWidth
                    error={invalidName}
                    onChange={(e) => setName(e.currentTarget.value)}
                  />
                </Grid>
                <Grid item xs={12} marginY={2}>
                  <TextField
                    id="email"
                    label="Email"
                    fullWidth
                    error={invalidEmail}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    type="email"
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <ImportedButton  onClick={onNext}>
                    Next
                  </ImportedButton>
                </Grid>{" "}
              </>
            )}
          </Grid>
        </DialogContent>
      </BootstrapDialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
