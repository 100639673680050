import React from "react";
import Slider from "react-slick";
import YouTubeVideo from "./YouTubeVideo";
import "./YoutubeVideoCarousel.css";

const YouTubeVideoCarousel = ({ videoIds }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="youtube-video-carousel">
      <Slider {...settings}>
        {videoIds.map((videoId) => (
          <YouTubeVideo key={videoId} videoId={videoId} />
        ))}
      </Slider>
    </div>
  );
};

export default YouTubeVideoCarousel;
