import React from "react";
import { useState, useEffect } from "react";
import "./PopUp.css"
import tw from "twin.macro";
import { PrimaryButton as ButtonBase } from "components/misc/Buttons.js";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";

const Container = tw.div`fixed bottom-0 h-72 w-64 xl:h-96 xl:w-80 bg-gray-200 p-20 m-8 mb-40 rounded-xl border-4 shadow border-primary-500 flex justify-center`
const PopUpDiv = tw.div`absolute top-0 flex flex-wrap justify-center gap-1 items-stretch`
const YoutubeVideo = tw.iframe`h-24 w-48 xl:h-40 xl:w-64 m-6 mb-2 xl:mb-4 border border-8 rounded-xl border-gray-400`
const Text = tw.p`text-center text-xs xl:text-sm font-medium xl:mx-2`
const Button = tw(ButtonBase)`mt-1 xl:mt-3` 



export default function PopUp(props) {

    const {setOpenCalendly} = props;
    const {setUrl} = props;
    
    const onNext = () => {
        setOpenCalendly(true);
        setUrl(
        `https://calendly.com/d/cpqt-ffk-q2s`
        );
    }


    //ESPERA PARA LA RENDERIZACION DEL POPUP
    const [waiting, setWaiting] = useState(true);

    useEffect(() => {
        setTimeout(() => {
          setWaiting(false);
        },3000);
      },[]);

    function handleClose() {
        setWaiting(true)
    }

    return (
        <div className="Div">
            <Container className={waiting? "":"PopUp"}>
                <PopUpDiv>
                    <DialogTitle sx={{ m: 0, p: 0.2}}>
                        <IconButton
                        onClick={handleClose}
                        aria-label="close"
                        sx={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        >
                          <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <YoutubeVideo
                        title="YouTube video player"
                        src={`https://www.youtube.com/embed/L7ZN9Wd8YPQ`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <Text>
                      Discover how our clients have achieved amazing results with our service. 
                      <br />Watch our video and schedule a call to learn how we can help you too.
                    </Text>
                    <Button onClick={onNext}>
                        Book a call
                    </Button>
                </PopUpDiv>
            </Container>
       </div>
    )
}