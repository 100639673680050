import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container as ImportedContainer } from "components/misc/Layouts.js";
import { SectionHeading as ImportedHeading} from "components/misc/Headings.js";
import Slider from "react-slick";
import './FeaturedAplicants.css'
import camila from "assets/images/featured-candidates/Camila Fliess.jpg";
import jennifer from "assets/images/featured-candidates/Jennifer Lin.jpg";
import malena from "assets/images/featured-candidates/Malena Ruffo.jpg";
import matias from "assets/images/featured-candidates/Matias weis.jpg";
import solenne from "assets/images/featured-candidates/Solenne Piaget.jpg";
import tamara from "assets/images/featured-candidates/Tamara Di Vizio.jpg";
import valeria from "assets/images/featured-candidates/Valeria Franco.jpg";
import ibeliza from "assets/images/featured-candidates/Ibeliza Vera.jpg";
import shantal from "assets/images/featured-candidates/Shantal.jpg";
import yardena from "assets/images/featured-candidates/Yardena.jpg";
import argentina from "assets/images/featured-candidates/Paises/argentina.png";
import ecuador from "assets/images/featured-candidates/Paises/ecuador.png";
import mexico from "assets/images/featured-candidates/Paises/mexico.png";
import peru from "assets/images/featured-candidates/Paises/peru.png";

const Container = tw(ImportedContainer)`mx-8 sm:mx-10`;
const Heading = tw(ImportedHeading)``;
const Candidates = tw.div`flex flex-col p-2 lg:flex-row items-center`;
const CandidateContainer = tw.div`mx-auto items-center`;
const Candidate = tw.div`text-center max-w-xs mx-auto flex flex-col items-center `;
const Image = tw.img`rounded-full border-4 border-primary-500`;
const Flag = tw.img`rounded-full border-4 border-primary-500`;
const CandidateName = tw.p`mt-5 text-gray-900 font-semibold text-4xl tracking-wide`;
const Role = tw.p`mt-3 text-gray-900 font-semibold text-lg md:text-2xl tracking-wide`;
const Languages = tw.p`mt-3 text-gray-900 text-sm md:text-lg tracking-wide`;


const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
export default ({
  heading = "Virtustant’s Featured Candidates",
  candidates = [
    {
        imageSrc:tamara,
        name: "Tamara",
        flag:argentina,
        role:'Management & Marketing',
        language:'Speaks Spanish, English, Portuguese, Italian'
    },
    {
      imageSrc:valeria,
      name: "Valeria",
      flag:ecuador,
      role:'Analyst',
      language: 'Speaks Spanish, English, German'
    },
    {
      imageSrc:camila,
      name: "Camila",
      flag:argentina,
      role:'Social Media Manager',
      language:'Speaks Spanish, English, French'
    },
    {
      imageSrc:shantal,
      name: "Shantal",
      flag:mexico,
      role:'Web Designer',
      language:'Speaks Spanish, English, Italian'
    },
    {
      imageSrc:jennifer,
      name: "Jennifer",
      flag:argentina,
      role:'Financial Specialist',
      language:'Speaks French, Spanish, English, Mandarin'
    },
    {
      imageSrc:yardena,
      name: "Yardena",
      flag:peru,
      role:'Web Developer',
      language:'Speaks Spanish, English, Portuguese'
    },
    {
        imageSrc:matias,
        name: "Matias",
        flag:argentina,
        role:'Project Manager',
        language:'Speaks Spanish, English, Portuguese, Hebrew'
    },
    {
      imageSrc:ibeliza,
      name: "Ibeliza",
      flag:ecuador,
      role:'Senior Account Manager',
      language: 'Speaks Spanish, English, French'
    },
    {
        imageSrc:solenne,
        name: "Solenne",
        flag:argentina,
        role:'Marketing Manager',
        language:'Speaks French, Spanish, English, German'
    },
    {
        imageSrc:malena,
        name: "Malena",
        flag:argentina,
        role:'Multimedia Designer',
        language: 'Speaks Spanish, English, Italian, Latin'
    },
  ]
}) => {
  return (
    <div className="Featured-Applicants">
        <Container>
            <Heading className="Applicant-Heading">{heading}</Heading>
            <Candidates className="Candidate">
                <Slider className="Applicant-Slider" {...settings}>
                  {candidates.map((candidate, index) => (
                      <CandidateContainer >
                      <Candidate key={index}>
                          <Image className="Applicant-Image" src={candidate.imageSrc} />
                          <Flag className="Applicant-Flag" src={candidate.flag} />
                          <CandidateName>{candidate.name}</CandidateName>
                          <Role>{candidate.role}</Role>
                          <Languages>{candidate.language}</Languages>
                      </Candidate>
                      </CandidateContainer>
                  ))}
                </Slider>
            </Candidates>
        </Container>
    </div>
    
  );
};
