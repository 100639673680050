import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import { useState } from "react";
import { CurrentPage } from "./components/context/CurentPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/Footer.js";
import ContactUs from "pages/ContactUs.js";
import Pricing from "./pages/Pricing.js";
import GenericNotFound from "./pages/GenericNotFound";
import Blog from "./pages/Blogs";
import ThankYou from "./pages/ThankYou.js";

import BlogEmployeeRetainment from "pages/blogs/BlogEmployee-Retainment.js"
import BlogWhyInternationalRemoteHiring from "pages/blogs/BlogInternationalRemoteHiring";
import Jobs from "./pages/Jobs.js";
import JobDescription from "./components/jobs/JobDescription.js"
import Referrals from "pages/Referrals"
import AdsPage from "pages/AdsPage";
//Hire Best Remote Templates
import AccountExecutive from "./pages/HireBestApplicant/AccountExecutive";
import AccountManager from "./pages/HireBestApplicant/AccountManager";
import SalesRepresentative from "./pages/HireBestApplicant/SalesRepresentative";
import ProjectManager from "./pages/HireBestApplicant/ProjectManager";
import VirtualAssistant from "./pages/HireBestApplicant/VirtualAssistant";
// about-us 
import Comparison from "pages/about-us/ComparissonChart";
import OurTeam from "pages/about-us/OurTeam";
import Services from "pages/about-us/Services";
import HowItWorks from "pages/about-us/HowItWorks";
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy";

export default function App() {

  const [currentPage, setCurrentPage] = useState('Home');

  return (
    <>
      <GlobalStyles />
      <AnimationRevealPage>
        <CurrentPage.Provider value={{ currentPage, setCurrentPage }}>
          <Router>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/contactUs" element={<ContactUs />} />
              <Route exact path="/referrals" element={<Referrals />} />
              <Route exact path="/blog" element={<Blog />} />
              <Route exact path="/comparisonChart" element={<Comparison />} />
              <Route exact path="/ourTeam" element={<OurTeam />} />
              <Route exact path="/services" element={<Services />} />
              <Route exact path="/howitworks" element={<HowItWorks />} />
              <Route exact path="/ads-form" element={<AdsPage />} />
              <Route exact path="/pricing" element={<Pricing />} />
              <Route exact path="/jobs" element={<Jobs />} />
              <Route exact path="/jobs/jobDescription/:id" element={<JobDescription />} />
              <Route exact path="*" element={<GenericNotFound />} />
              <Route exact path="/thank-you" element={<ThankYou />} />
              <Route exact path="/hire-the-best-remote/Sales-Representative" element={<SalesRepresentative />} />
              <Route exact path="/hire-the-best-remote/Account-Manager" element={<AccountManager />} />
              <Route exact path="/hire-the-best-remote/Account-Executive" element={<AccountExecutive />} />
              <Route exact path="/hire-the-best-remote/Project-Manager" element={<ProjectManager />} />
              <Route exact path="/hire-the-best-remote/Virtual-Assistant" element={<VirtualAssistant />} />



         
                        < Route exact path="/blog/remote-employee-retainment" element={<BlogEmployeeRetainment />} />
                        < Route exact path="/blog/international-remote-hiring" element={<BlogWhyInternationalRemoteHiring />} />
    
{/* Ruta para /Jobs */}
        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />

          </Routes>
          </Router>
          <Footer />
        </CurrentPage.Provider>
      </AnimationRevealPage>
    </>
  );
}
