import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import Header from "../../components/headers/Header";
import ContactForm from "components/contact-us/NewContactForm.js";
import PresentationCard from "components/HireBestApplicant/PresentationCard";
import CDesc01 from "components/HireBestApplicant/CDesc01";
import CDesc02 from "components/HireBestApplicant/CDesc02";
import CDesc03 from "components/HireBestApplicant/CDesc03";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";


import FastRecruitmentIcon from "assets/images/icons/FastRecruitmentIcon.png";
import { PopupModal } from "react-calendly";

const Container = tw.div`relative`;

const collapseBreakPointCssMap = {
  sm: {
    mobileText: tw`sm:hidden`,
    desktopText: tw`sm:block`,
    mobileTextContainer: tw`sm:hidden`,
  },
  md: {
    mobileText: tw`md:hidden`,
    desktopText: tw`md:block`,
    mobileTextContainer: tw`md:hidden`,
  },
  lg: {
    mobileText: tw`lg:hidden`,
    desktopText: tw`lg:block`,
    mobileTextContainer: tw`lg:hidden`,
  },
  xl: {
    mobileText: tw`xl:hidden`,
    desktopText: tw`hidden xl:block`,
    mobileTextContainer: tw`xl:hidden`,
  },
};

export default function SalesRepresentative(props) {
  const collapseBreakpointClass = "xl"
 



  const defaultCards = [
    {
      imageSrc: FastRecruitmentIcon,
      title: "Keep your capital liquid, reducing salary budgeting while keeping quality high. ",
      DesktopDescription: "We serve 100+ companies with our unique pool of vetted talent that can diversify and help grow your systems. Reduce expenses while gaining profits, with the help of tried and tested remote employees.",
    
    },
    
  ];

  const cards = defaultCards;

  const [openCalendly, setOpenCalendly] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
      console.log(url);
    }, [url]);
  return (
      <>
        <Header roundedHeaderButton={true} />

      <Container>
      <PresentationCard setOpenCalendly={setOpenCalendly} setUrl={setUrl}/>
      <CDesc01/>
      <CDesc02/>
      <CDesc03/>
    </Container>
<ContactForm setOpenCalendly={setOpenCalendly} setUrl={setUrl}/>
              <PopupModal
              open={openCalendly}
              onModalClose={() => setOpenCalendly(false)}
              rootElement={document.getElementById("root")}
              url={url}
              ></PopupModal>
              </>
  );
  
}  
  