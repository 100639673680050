import React from "react";
import Header from "../../components/headers/Header.js";
import ComparissonChart from "components/about-us/Comparisson2.js"
import { useEffect } from "react";
import { useContext } from 'react';
import { CurrentPage } from '../../components/context/CurentPage';

export default () => {

  const {currentPage, setCurrentPage} = useContext(CurrentPage);

  useEffect(() => {
    setCurrentPage("About-us");
    console.log(currentPage);  
  });

  return (
    <>
      <Header roundedHeaderButton={true} />
      <ComparissonChart />
    </>
  );
};