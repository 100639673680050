import React from "react";
import Header from "../../components/headers/Header.js";
import HowItWorks from "components/about-us/HowItWorks.js"
import { PopupModal } from "react-calendly";
import ScheduleDialog from "components/Home/ScheduleDialog";
import { useState, useEffect } from "react";
import { useContext } from 'react';
import { CurrentPage } from '../../components/context/CurentPage';

export default () => { 
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openCalendly, setOpenCalendly] = useState(false);
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const {currentPage, setCurrentPage} = useContext(CurrentPage);

  useEffect(() => {
    setCurrentPage("About-us");
    console.log(currentPage);  
  });

  return (
    <>
      <Header roundedHeaderButton={true} />
      <HowItWorks setDialogOpen={setDialogOpen}/>
      <ScheduleDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        email={email}
        setEmail={setEmail}
        name={name}
        setName={setName}
        setOpenCalendly={setOpenCalendly}
        setUrl={setUrl}
       ></ScheduleDialog>
       <PopupModal
            open={openCalendly}
            onModalClose={() => setOpenCalendly(false)}
            rootElement={document.getElementById("root")}
            url={url}
       ></PopupModal>
    </>
  );
};