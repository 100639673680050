import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
//blogs images
import ArtificialInteligence from "assets/images/blogs/NewArtificialInteligence.png";
import OutsourcingBestPractices from "assets/images/blogs/NewOutsourcingBestPractices.png";
import Outsourcing from "assets/images/blogs/NewOutsourcing.png";
import LatinAmerica from "assets/images/blogs/NewLatinAmerica.png";
import QualitiesEmployees from "assets/images/blogs/NewQualitiesEmployees.png";
import VirtualAssistance from "assets/images/blogs/NewVirtualAssistance.png";
import RemoteWork from "assets/images/blogs/NewRemoteWork.png";
import ChoosingCompany from "assets/images/blogs/NewChoosingCompany.png";
import MultilingualEmployees from "assets/images/blogs/NewMultilingualEmployees.png";
import Communication  from "assets/images/blogs/NewCommunication.png";
import SocialMedia from "assets/images/blogs/NewSocialMedia.png";
import EmpoyeeRetainment from "assets/images/blogs/Remote-Employee-Retainment.png"
import WhyInternationalRemoteHiring from "assets/images/blogs/WhyInternationalRemoteHiring.jpg";

const HeadingContainer = tw.div`text-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto`;

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`;
const Post = tw.a`flex flex-col h-full bg-gray-300 rounded shadow-xl border-2 border-secondary-900`;
const PostImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 sm:h-80 bg-center bg-contain bg-no-repeat rounded-t`}
`;
const PostText = tw.div`flex-1 px-6 py-8` 
const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;
const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;
const AuthorImage = tw.img`w-12 h-12 rounded-full mr-3`;
const AuthorTextInfo = tw.div`text-xs text-gray-600`;
const AuthorName = tw.div`font-semibold mt-2 text-sm`;
const AuthorProfile = tw.div`pt-1 font-medium`;

const PostContainer = styled.div`
  ${tw`relative z-20 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${props => props.featured && css`
    ${tw`w-full sm:w-full lg:w-2/3`}
    ${Post} {
      ${tw`sm:flex-row items-center sm:pr-3`}
    }
    ${PostImage} {
      ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
    }
    ${PostText} {
      ${tw`pl-8 pr-5`}
    }
    ${PostTitle} {
      ${tw`text-2xl`}
    }
    ${PostDescription} {
      ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
    }
    ${AuthorInfo} {
      ${tw`mt-8 flex items-center`}
    }
    ${AuthorName} {
      ${tw`mt-0 font-bold text-gray-700`}
    }
  `}
`;

const DecoratorBlob1 = tw(SvgDotPatternIcon)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`
const DecoratorBlob2 = tw(SvgDotPatternIcon)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`

export default ({
  subheading = "",
  heading = "Our Latest Posts",
  description = "Interesting posts to deepen your knowlegde about remote hiring.",
  posts = [
    // {
    //   postImageSrc: ArtificialInteligence,
    //   title: "How to implement AI in your Company",
    //   description:
    //     "Supercharge your company with AI. Streamline operations, drive innovation, and make data-driven decisions. Embrace the power of Artificial Intelligence for business success.",
    //   url:"/blog/How-to-implement-AI-in-your-company",
    //   featured: true
    // },
    // {
    //   postImageSrc: MultilingualEmployees,
    //   title: "Unlocking the Power of Multibillingual Employees",
    //   authorName: "Around the Globe",
    //   url: "/blog/Multilingual-Employees"
    // },
    // {
    //   postImageSrc: Outsourcing,
    //   title: "How to Ensure Quality and Consistency",
    //   authorName: "Outsourcing best practices",
    //   url: "/blog/Benefits-of-Outsourcing"
    // },
    // {
    //   postImageSrc:LatinAmerica,
    //   title: "Latin American Professionals",
    //   authorName: "A great opportunity for US companies",
    //   url: "/blog/Great-Oportunities-for-US-Companies"
    // },
    // {
    //   postImageSrc:QualitiesEmployees,
    //   title: "Qualities of Employees to Look for When Hiring",
    //   authorName: "Hiring process",
    //   url: "/blog/Qualities-to-Look-for-when-Hiring"
    // },
    // {
    //   postImageSrc: VirtualAssistance,
    //   title: "Virtual Assistance Can Benefit you Business",
    //   authorName: "Rise of Virtual Assistance",
    //   url: "/blog/Rise-of-Virtual-Assistance"
    // },
    // {
    //   postImageSrc:RemoteWork,
    //   title: "Importance of Remote Work Around the Globe",
    //   description:
    //   "The global remote work is Breaking boundaries, fostering inclusivity, unlocking talent, and promoting work-life balance for a thriving global workforce.",
    //   url: "/blog/Importance-of-Remote-Work",
    //   featured: true
    // },
    // {
    //   postImageSrc:SocialMedia,
    //   title: "Smart Companies Invest in Effective Management",
    //   description:
    //   "Effective management is the cornerstone of organizational success. Investing in strong management practices not only benefits the company but also empowers employees, drives productivity, and fosters a positive work environment",
    //   url: "/blog/The-Power-of-Social-Media",
    //   featured: true
    // },
    // {
    //   postImageSrc: ChoosingCompany,
    //   title: "What do Employees Value Most when Choosing a Company?",
    //   authorName: "Employees management",
    //   url: "/blog/Choosing-a-Company"
    // },
    // {
    //   postImageSrc: Communication,
    //   title: "How Good Communication can Impact your Work",
    //   authorName: "Communication",
    //   url: "/blog/Good-Communication"
    // },
    {
      postImageSrc: EmpoyeeRetainment,
      title: "Which options can generate employee retainment?",
      authorName: "Employee retainment",
      url: "/blog/remote-employee-retainment"
    },
    {
      postImageSrc: WhyInternationalRemoteHiring ,
      title: "Why international remote hiring can change business for the better",
      authorName: "International Remote Hiring",
      url: "/blog/international-remote-hiring"
    },
  
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Posts>
          {posts.map((post, index) => (
            <PostContainer featured={post.featured} key={index}>
              <Post className="group" href={post.url}>
                <PostImage imageSrc={post.postImageSrc} />
                <PostText>
                  <PostTitle>{post.title}</PostTitle>
                  {post.featured && <PostDescription>{post.description}</PostDescription>}
                  <AuthorInfo>
                    <AuthorTextInfo>
                      <AuthorName>{post.authorName}</AuthorName>
                      {post.featured && <AuthorProfile>{post.authorProfile}</AuthorProfile>}
                    </AuthorTextInfo>
                  </AuthorInfo>
                </PostText>
              </Post>
            </PostContainer>
          ))}
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  );
};
