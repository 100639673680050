import React from "react";

const YouTubeVideo = ({ videoId }) => {
  return (
    <div className="youtube-video">
      <iframe
        title="YouTube video player"
        className="youtube-video-iframe"
        src={`https://www.youtube.com/embed/${videoId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default YouTubeVideo;
