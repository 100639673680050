import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import NewHero from "components/Home/Hero";
import NewLogoSlider from "../components/Home/LogoSlider";
import NewWhyUs from "components/Home/WhyUs";
import FeaturedAplicants from "components/Home/FeaturedAplicants";
import VideoTestimonials from "components/Home/VideoTestimonials";
import GridTestimonials from "components/trutbox/GridTestimonials.js";

import PopUp from "helpers/PopUp";
import { PopupModal } from "react-calendly";


const TrustBoxHeroDiv = tw.div`hidden md:block` 

export default function Home() {

    const [openCalendly, setOpenCalendly] = useState(false);
    const [url, setUrl] = useState("");

    useEffect(() => {
        console.log(url);
      }, [url]);

    return (
        <>
        <PopUp setOpenCalendly={setOpenCalendly} setUrl={setUrl}/>
        <NewHero setOpenCalendly={setOpenCalendly} setUrl={setUrl}/>
        <NewLogoSlider />
        <NewWhyUs setOpenCalendly={setOpenCalendly} setUrl={setUrl} />
        <FeaturedAplicants />
        <VideoTestimonials setOpenCalendly={setOpenCalendly} setUrl={setUrl} />
        {/* <TrustBoxHeroDiv>
        <GridTestimonials />
        </TrustBoxHeroDiv> */}
        <PopupModal
            open={openCalendly}
            onModalClose={() => setOpenCalendly(false)}
            rootElement={document.getElementById("root")}
            url={url}
        ></PopupModal>
        </>
    )
}