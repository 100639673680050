import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo512 from "../../assets/images/icons/logo512.png";
import VirtustantHireRemote from "../../images/newimages/VirtustantLogoO.png";


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const TrustBox = tw.div`max-w-screen-xl mx-auto mt-10`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={VirtustantHireRemote} />
            <LogoText>Virtustant</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link href="/pricing">Pricing</Link>
            {/* <Link href="/jobs">Jobs</Link> */}
            {/*<Link href="https://recruitcrm.io/jobs/virtustant-jobs">Jobs</Link>*/}
            <Link href="/blog">Blog</Link>
            <Link href="/referrals">Referrals</Link>
            <Link href="/contactUs">Contact Us</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/virtustant">
              <i class="fa-brands fa-facebook"></i>
            </SocialLink>
            <SocialLink href="https://www.instagram.com/virtustant_">
              <i class="fa-brands fa-instagram"></i>
            </SocialLink>
            <SocialLink href="https://www.youtube.com/@virtustant">
              <i class="fa-brands fa-youtube"></i>
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/virtustant/">
              <i class="fa-brands fa-linkedin"></i>
            </SocialLink>
          </SocialLinksContainer>
          <TrustBox>
            <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="63fe142a6295ff0e0a72750e" data-style-height="24px" data-style-width="100%" data-theme="dark">
            <a href="https://www.trustpilot.com/review/virtustant.com" target="_blank" rel="noreferrer noopener">Trustpilot</a>
            </div>
          </TrustBox>
          <CopyrightText>
            &copy; Copyright 2024, Virtustant Inc. All Rights Reserved.
          </CopyrightText>
          
        </Row>
      </Content>
    </Container>
  );
};
