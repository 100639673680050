import React from "react";
import { useRef, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "../../assets/images/newContactUs.png";
import emailjs from "@emailjs/browser";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import validator from "validator";
import "./Forms.css"


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center  max-w-screen-xl mx-auto pt-5 py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 lg:w-6/12 flex-shrink-0 h-80 md:h-auto`;
const ImageColumnDiv = tw.div``;
const TextColumn = styled(Column)(props => [
  tw`md:w-9/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

//Trust Pilot
const TrustBox = tw.div`flex justify-center md:justify-start text-blue-900 md:mb-3 md:mt-20`;


const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-contain bg-no-repeat bg-center h-10/12 w-10/12 xl:h-9/12 xl:w-9/12 absolute right-0 bottom-0 -z-10 hidden lg:block`,
]);
const MobileImage = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-5/6 block md:hidden`,
]);
const TextContent = tw.div`text-center md:text-left `;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-0 font-black text-left text-5xl sm:text-5xl md:text-6xl text-center md:text-left leading-tight md:ml-4`;
const Description = tw.p`mt-4 mb-8 text-center md:text-left text-lg md:text-base lg:text-xl font-medium leading-relaxed text-secondary-900 md:ml-4`

const Form = tw.form`mt-20 md:mt-10 text-xl flex flex-col max-w-sm md:max-w-xl mx-auto border-2 border-transparent rounded-xl p-6 bg-gray-100 shadow-2xl z-50`
const Input = tw.input`first:mt-0 border-4 p-2 focus:outline-none font-medium rounded-md`
const Label = tw.label`text-left tracking-wide font-semibold text-lg text-secondary-900 mt-4 mb-2`;
const Span = tw.span`text-left text-sm text-red-500`
const Text = tw.p`text-secondary-800 text-left opacity-75 text-sm mb-3`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24 w-full`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({

  subheading = "",
  heading = <>Before the Call</>,
  description = "Tell Us About Your Needs",
  submitButtonText = "Let's Talk",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,

  //Calendly props
  setOpenCalendly,
  setUrl,
}) => {

    //form
    const form = useRef();
    const [fullname, setFullName] = useState("");
    const [lastname, setLastName] = useState("");

    const [companyemail, setCompanyEmail] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [companyname, setCompanyName] = useState("");
    const [position, setPosition] = useState("");
    const [hiringNumber, setHiringNumber] = useState("");
    const [hiringDate, setHiringDate] = useState("");
    const [additionalDetails, setAdditionalDetails] = useState("")


    //validation state
    const [errorfullname, setErrorFullName] = useState("");
    const [errorlastname, setErrorLastName] = useState("");

    const [errorcompanyemail, setErrorCompanyEmail] = useState("");
    const [errorphonenumber, setErrorPhoneNumber] = useState("");
    const [errorcompanyname, setErrorCompanyName] = useState("");
    const [errorposition, setErrorPosition] = useState("");
    const [errorhiringnumber, setErrorHiringNumber] = useState("");
    const [errorhiringdate, setErrorHiringDate] = useState("");



  //snackbar
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

    //blur validations
    function validateFullName() {
      if (fullname === "") {
        setErrorFullName("First name missing")
      } else {
        setErrorFullName("")
      }
    }
    function validateLastName() {
      if (lastname === "") {
        setErrorLastName("Last name missing")
      } else {
        setErrorLastName("")
      }
    }
    function validateCompanyEmail() {
    if (companyemail === "") {
        setErrorCompanyEmail("Company Email missing")
      } else if (!validator.isEmail(companyemail)) {
       setErrorCompanyEmail("Enter a valid email address")
      } else {
       setErrorCompanyEmail("")
    }
    }
    function validatePhoneNumber() {
      if (phonenumber === "") {
        setErrorPhoneNumber("Phone number missing")
      } else {
        setErrorPhoneNumber("")
      }
    }
    function validateCompanyName() {
      if (companyname === "") {
        setErrorCompanyName("Company name missing")
      } else {
        setErrorCompanyName("")
      }
    }
    function validatePosition() {
      if (position === "") {
        setErrorPosition("Position missing")
      } else {
        setErrorPosition("")
      }
    }
    function validateHiringNumber() {
      if (hiringNumber === "") {
        setErrorHiringNumber("Please Complete this Information")
      } else {
        setErrorHiringNumber("")
      }
    }
    function validateHiringDate() {
        if (hiringDate === "") {
          setErrorHiringDate("Please Complete this Information")
        } else {
          setErrorHiringDate("")
        }
      }

    //validations
    const sendEmail = async (e) => {
      e.preventDefault();

      if (fullname === "") {
        setErrorFullName("First name missing")
        return;
      }
      if (lastname === "") {
        setErrorLastName("Last name missing")
        return;
      }
      if (companyemail === "") {
        setErrorCompanyEmail("Company Email missing")
      } else if (!validator.isEmail(companyemail)) {
       setErrorCompanyEmail("Enter a valid email address")
      } 
      if (phonenumber === "") {
        setErrorPhoneNumber("Phone number missing")
        return;
      }
      if (companyname === "") {
        setErrorCompanyName("Company name missing")
        return;
      }
      if (position === "") {
        setErrorPosition("Position missing")
        return;
      }
      if (hiringNumber === "") {
        setErrorHiringNumber("Please Complete this Information")
        return;
      }
      if (hiringDate === "") {
        setErrorHiringDate("Please Complete this Information")
        return;
      }

      await emailjs
      .sendForm(
        "service_axl5e8d",
        "template_e73985h",
        form.current,
        "OxrtZIwLoGR3a8EaK"
      )
      .then(
        (result) => {
          setSnackbarMessage("Message sent.");
          setOpen(true);
          setSnackbarSeverity("success");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
          setSnackbarMessage("Error sending the message.");
          setOpen(true);
          setSnackbarSeverity("error");
        }
      );

      setOpenCalendly(true);
      setUrl(
        `https://calendly.com/d/347-pzt-32c/discovery-call-virtustant-webpage?`
      );

      setFullName("");
      setLastName("");
      setCompanyEmail("");
      setPhoneNumber("");
      setCompanyName("");
      setPosition("");
      setHiringNumber("");
      setHiringDate("");
      setAdditionalDetails("");
    };
  

  return (
    <Container>
         <Image imageSrc={EmailIllustrationSrc}/>
      <TwoColumn>
        <ImageColumn>
        <TrustBox>
                <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="63fe142a6295ff0e0a72750e" data-style-height="24px" data-style-width="100%" data-theme="light">
                <a href="https://www.trustpilot.com/review/virtustant.com" target="_blank" rel="noreferrer noopener">Trustpilot</a>
                </div>
        </TrustBox>
        <ImageColumnDiv>
                {subheading && <Subheading>{subheading}</Subheading>}
                    <Heading>{heading}</Heading>
                {description && <Description>{description}</Description>}
         </ImageColumnDiv>
         <MobileImage imageSrc={EmailIllustrationSrc}/>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Form ref={form} method="post" name="google-sheet">
            <Label htmlFor="name-input">First Name*</Label>
              <Input onBlur={validateFullName} onChange={(e) => setFullName(e.target.value)} 
              type="text" 
              name="fullname"
              value={fullname}
              className={errorfullname? "Invalid": ""}
              />
              <Span>{errorfullname}</Span>
              <Label htmlFor="name-input">Last Name*</Label>
              <Input onBlur={validateLastName} onChange={(e) => setLastName(e.target.value)} 
              type="text" 
              name="lastname"
              value={lastname}
              className={errorlastname? "Invalid": ""}
              />
              <Span>{errorlastname}</Span>
              <Label>Your Company Email*</Label>
              <Input onBlur={validateCompanyEmail} onChange={(e) => setCompanyEmail(e.target.value)} 
              type="email"
              name="companyemail"
              value={companyemail}
              className={errorcompanyemail? "Invalid": ""}
              />
              <Span>{errorcompanyemail}</Span>
              <Label htmlFor="name-input">Phone Number*</Label>
              <Input onBlur={validatePhoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} 
              type="tel" 
              name="phonenumber"  
              value={phonenumber}
              className={errorphonenumber? "Invalid": ""}
              />
              <Span>{errorphonenumber}</Span>
              <Label htmlFor="name-input">Company Name*</Label>
              <Input onBlur={validateCompanyName} onChange={(e) => setCompanyName(e.target.value)} 
              type="text" 
              name="companyname" 
              value={companyname}
              className={errorcompanyname? "Invalid": ""}
              />
              <Span>{errorcompanyname}</Span>
              <Label htmlFor="name-input">Your Position*</Label>
              <Input onBlur={validatePosition} onChange={(e) => setPosition(e.target.value)} 
              type="text" 
              name="position" 
              value={position}
              className={errorposition? "Invalid": ""}
              />
              <Span>{errorposition}</Span>
              <Label htmlFor="name-input">Hoy Many People Do You Plan To Hire?</Label>
              <Text>Knowing your hiring goals will help us prepare for our consultation.</Text>
              <Input onBlur={validateHiringNumber} onChange={(e) => setHiringNumber(e.target.value)} 
              type="number" 
              min={1}
              name="hiringNumber"
              value={hiringNumber}
              className={errorhiringnumber? "Invalid": ""}
              />
              <Span>{errorhiringnumber}</Span>
              <Label htmlFor="name-input">When Do You Plan To Hire By?</Label>
              <Input onBlur={validateHiringDate} onChange={(e) => setHiringDate(e.target.value)} 
              type="date"
              name="hiringDate"
              value={hiringDate}
              className={errorhiringdate? "Invalid": ""}
              />
              <Span>{errorhiringdate}</Span>
              <Label>Additional details</Label>
                  <Textarea onChange={(e) => setAdditionalDetails(e.target.value)} 
                    value={additionalDetails}
                    type="text"
                    name="additionalDetails"
                  />
              <SubmitButton type="submit"onClick={sendEmail}>{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};