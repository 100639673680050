import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.div`relative `;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto `;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-8 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h1`text-2xl md:text-3xl leading-snug max-w-3xl text-left`;
const Paragraph = tw.p`mt-5 text-xl font-medium text-gray-600 max-w-lg lg:mx-0 text-left`;

const VerticalSpacer = tw.div`mt-10 w-full`



export default function CommonDescription(props) {
  const { setDialogOpen } = props;
  const title = "Keep your capital liquid, reducing salary budgeting while keeping quality high. ";
  const description = "We serve 100+ companies with our unique pool of vetted talent that can diversify and help grow your systems. Reduce expenses while gaining profits, with the help of tried and tested remote employees.";

  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
        <Heading>{title}</Heading>
        </LeftColumn>
        <RightColumn>
        <Paragraph>{description}</Paragraph>
        </RightColumn>
      </TwoColumn>
      <VerticalSpacer/>
    </Container>
  );
}
