import React, { useState, useEffect } from "react";
import Header from "../components/headers/Header.js";
import ContactForm from "components/contact-us/NewContactForm.js";
import FAQ from "components/Home/FAQ";
import { PopupModal } from "react-calendly";
import { useContext } from 'react';
import { CurrentPage } from '../components/context/CurentPage.js';

export default function ContactUs() {

  const [openCalendly, setOpenCalendly] = useState(false);
  const [url, setUrl] = useState("");

  const {currentPage, setCurrentPage} = useContext(CurrentPage);

  useEffect(() => {
    setCurrentPage("Contact-us");
    console.log(currentPage);  
  });

  return (
    <>
      <Header roundedHeaderButton={true} />
      <ContactForm setOpenCalendly={setOpenCalendly} setUrl={setUrl}/>
      <FAQ />
      <PopupModal
            open={openCalendly}
            onModalClose={() => setOpenCalendly(false)}
            rootElement={document.getElementById("root")}
            url={url}
      ></PopupModal>
    </>
  );
};