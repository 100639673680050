import React from "react";
import styled from "styled-components";
import { css } from "styled-components/macro";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import calendar from "../../assets/images/steps/Calendar.png";
import talkwithus from "../../assets/images/steps/Talkwithus.png";
import onboarding from "../../assets/images/steps/Onboarding.png";
import selection from "../../assets/images/steps/Selection.png";
import { PrimaryButton as ImportedButton} from "components/misc/Buttons.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 flex flex-wrap md:flex-wrap justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.img(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-contain bg-no-repeat bg-center h-56 w-64 md:h-64 md:w-80 rounded rounded-b-none rounded `
]);

const Details = tw.div`text-center md:text-left mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-lg leading-loose`;

const Button = tw(ImportedButton)`mt-2 text-white`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default function Steps(props) {
  const { setDialogOpen } = props;
  const cards = [
    {
      imageSrc: calendar,
      subtitle: "Step 1",
      title: "Book a discovery call",
      description:
        "Book a discovery call with us. Choose the day and time you prefer.",
      button: true,
    },

    {
      imageSrc: talkwithus,
      subtitle: "Step 2",
      title: "Have a call with us",
      description:
        "Have a call with us, know how we work and give us the more information you can about the people you are looking for.",
    },

    {
      imageSrc: selection,
      subtitle: "Step 3",
      title: "Selection",
      description:
        "We make the work for you! We look for the applicants and send you the best that we find.",
    },
    {
      imageSrc: onboarding,
      subtitle: "Step 4",
      title: "Onboarding",
      description:
        "We make sure all the documentation has been sent and everything is ready to start.",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>How it works</HeadingTitle>
          <HeadingDescription>
            These are the steps of how the process of hiring is done
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
                <Image src={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {card.button ? (
                  <Button
                    style={{ marginTop: "1rem" }}
                    color="warning"
                    variant="contained"
                    onClick={() => {
                      setDialogOpen(true);
                    }}
                  >
                    Book now
                  </Button>
                ) : (
                  ""
                )}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
}
