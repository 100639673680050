import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.div`relative `;

const ThreeColmn = styled.div`
${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24 `}
`;

const Heading = tw.h4`text-2xl font-bold text-gray-900 text-center flex-wrap`;

const VerticalSpacer = tw.div`mt-10 w-full`


const Column = styled.div`
  ${tw` md:w-full lg:w-1/3 px-6 flex flex-wrap`}
`;
const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8 w-full`}
  .imageContainer {
    ${tw`text-center rounded-full flex-shrink-0 sm:h-full flex items-center `}
    img {
      ${tw`w-32 h-32`}
    }
  }
  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }
  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }
  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose text-lg`}
  }
 `;





export default function CommonDescription(props) {
    const { setDialogOpen } = props;
    const cards = [
        {
            key: 5,
            imageLogo: "",
            title: "Discovery call",
            description:
                "In 30 minutes we ask the right questions and actively listen to what you require, and find the best range of talent to offer.",
        }, {
            key: 6,
            imageLogo: "",
            title: "Recruitment tracker",
            description:
                "Our skilled recruitment consultants gather resumes, recorded interviews and the results of the skills assessments that they believe will work best for you. You can choose who you’d like to interview by having that information at your fingertips.",
        }, {
            key: 7,
            imageLogo: "",
            title: "Your chosen candidate interviews",
            description:
                "We schedule time to meet face to face with the people you feel will fit with your team and requirements. Once you’re satisfied, the candidate signs with us, we set up payroll for you, and onboarding begins.",
        },

    ];




    return (
<Container>
            <ThreeColmn>
            <Heading> How do we do it?</Heading>
            <VerticalSpacer />

            {cards.map((card,i) =>(
                <Column key={i}>
                    <Card className="group">
                    <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
                    </Card>
                </Column>

            ))}

            </ThreeColmn>
</Container>
    );
}
