import React from "react";
import { useEffect } from "react";
import { ThankYouPageEvent } from "utils/FacebookPixelsEvents"
import ThankYou from "components/thank-you/ThankYou";


export default () => {

  useEffect(() => {
    ThankYouPageEvent(window.fbq);
  },[])

  return (
    <ThankYou />
  );
};
