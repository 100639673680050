import React from "react";
import { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as Headingtitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
//Modal Imports
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
//current positions
import CurrentPositions from "./CurrentPositions"


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/2 xl:w-1/3 flex justify-center`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingTitle = tw(Headingtitle)``
const HeadingDescription = tw.p`mt-4 font-medium text-xl text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 w-80 sm:w-96 sm:max-w-sm flex flex-col h-full border-4 rounded border-primary-100 shadow-xl bg-gray-100`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-contain bg-no-repeat bg-center h-80 lg:h-64 rounded rounded-b-none rounded `
]);

const Details = tw.div`relative p-6 rounded  flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg mb-16`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold absolute bottom-0 mb-6`}
`

// Modal Structure
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  height: '95%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Form = tw.iframe`h-full w-full border-0 shadow-black`
const Actions = tw.div`flex sm:flex-row justify-center mt-56 flex-wrap`;
const ButtonDiv = tw.div`sm:w-4/5 md:w-3/5 bg-gray-200 drop-shadow-2xl flex justify-evenly p-10 border-2 border-secondary-900 rounded flex-wrap`
const ButtonTitle = tw(Headingtitle)`text-2xl sm:text-3xl w-full`
const ButtonDescription = tw.p`mt-2 font-medium text-lg sm:text-xl text-gray-700 text-center w-full`
const PrimaryButton = tw.button`mt-8 font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-900 focus:shadow-outline focus:outline-none transition duration-300`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default function Jobs(){

// alfabetic order
CurrentPositions.sort((x, y) => x.JobTitle.localeCompare(y.JobTitle));
//filter by status
const CurrentPositionsFiltered = CurrentPositions.filter((position) => 
  position.status === "open"
)
  const subheading = ""
  const heading = <>Embrace Your True Potential</>
  const description = "Find your Dream Career"

  //Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {CurrentPositionsFiltered.map((CurrentPosition, index) => (
            <Column key={index}>
              <Card>
                {<Image imageSrc={CurrentPosition.imgSrc} />}
                <Details>
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{CurrentPosition.JobType}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{CurrentPosition.hourSalary} per hour</div>
                    </Meta>
                  </MetaContainer>
                  <Title>{CurrentPosition.JobTitle}</Title>
                  {/*<Description>{post.description}</Description>*/}
                  <Link href={CurrentPosition.url}>Job Description</Link>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
        <Actions>
          <ButtonDiv>
            <ButtonTitle>Didin't Find a Match?</ButtonTitle>
            <ButtonDescription>Leave Your Data For Future Opportunities</ButtonDescription>
            <PrimaryButton onClick={handleOpen} >Click Here</PrimaryButton>
          </ButtonDiv>
        </Actions>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
              <Form
                title="Form"
                src="https://forms.monday.com/forms/embed/77f82e34a9e9e34aca38d28fe3c44d67?r=use1"
              />
              </Box>
            </Fade>
        </Modal>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
