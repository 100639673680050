import React, { useEffect }  from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import "./Header.css"
import { css } from "styled-components/macro"; //eslint-disable-line
import { useState } from 'react';
import { useContext } from 'react';
import { CurrentPage } from '../context/CurentPage';
import { Dropdown } from 'antd';

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import VirtustantHireRemote from "../../images/newimages/VirtustantLogoB.png"
import VirtustantLogo from "../../images/newimages/VirtustantLogoWword.png"

import logo512 from "../../assets/images/icons/logo512.png";

import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";

//LOGO IMAGE
const Image = tw.img`max-w-full bg-gray-lightest w-auto rounded-t sm:rounded relative z-20 `;



//dropdown structure 
const ImportedDropdown = tw(Dropdown)`hidden lg:inline`
const AbutUsNavLink = tw.a`inline lg:hidden text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
font-semibold tracking-wide transition duration-300
pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`

//Hire the best Remote
const HireBestNavLink = tw.a`inline lg:hidden text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
font-semibold tracking-wide transition duration-300
pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`
const HireBestDropdown = tw(Dropdown)`hidden lg:inline`

const HiddenNavLinks = tw.div`flex-wrap bg-gray-200 w-full border-2 rounded-xl border-secondary-900`;
const HiddenLink = tw.a`
text-sm my-2
font-semibold tracking-wide
pb-1 border-b-2 border-transparent w-full text-secondary-900
`;

const items = [
  {
    key: '1',
    label: (
      <a href="/ourTeam">
        Meet Our Team
      </a>
    ),
  },
  {
    key: '3',
    label: (
      <a href="/services">
        Services
      </a>
    ),
  },
  {
    key: '4',
    label: (
      <a href="/comparisonChart">
        Comparison Chart
      </a>
    ),
  },
  {
    key: '5',
    label: (
      <a href="/howitworks">
        How It Works
      </a>
    ),
  },
];
const hireRemote =[
  {
    a: '6',
    label: (
      <a href="/hire-the-best-remote/Sales-Representative">
        Sales Representative
      </a>
    ),
  },
  {
    a: '7',
    label: (
      <a href="/hire-the-best-remote/Account-Manager">
        Account Manager
      </a>
    ),
  },
  {
    a: '8',
    label: (
      <a href="/hire-the-best-remote/Account-Executive">
        Account Executive
      </a>
    ),
  },
  {
    a: '9',
    label: (
      <a href="/hire-the-best-remote/Proyect-Manager">
        Proyect Manager
      </a>
    ),
  },
  {
    a: '10',
    label: (
      <a href="/hire-the-best-remote/Virtual-Assistant">
       Virtual Assistant
      </a>
    ),
  },

];

export const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto relative z-50
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-900 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-32 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;

export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const { currentPage } = useContext(CurrentPage);
  let page = currentPage
  console.log(currentPage);

  const [hiddenlinks, setHiddenLinks] = useState("true")
  const handleToggle = () => {
    setHiddenLinks((current) => !current);
  };

  const defaultLinks = [
    <NavLinks key={1}>
      
   

      {/* <NavLink href="/Hire-the-Best-Remote">Hire the Best Remote</NavLink> */}
      <NavLink href="/">Home</NavLink>
      <NavLink className={page === "Pricing" ? "selected" : ""} href="/pricing">Pricing</NavLink>
      {/* <NavLink className={page === "Jobs" ? "selected" : ""} href="/jobs">Jobs</NavLink> */}
      <ImportedDropdown
        menu={{
          items,
        }}
        placement="bottom"
      >
        <NavLink className={page === "About-us" ? "selected" : ""} href="#">About Us</NavLink>
      </ImportedDropdown>
     
      <AbutUsNavLink onClick={handleToggle} href="#">About Us</AbutUsNavLink>
      <HiddenNavLinks className={hiddenlinks ? "invisible" : "visible"}>
        <HiddenLink href="/ourTeam">Meet Our Team</HiddenLink>
        <HiddenLink href="/services">Services</HiddenLink>
        <HiddenLink href="/comparisonChart">Comparison Chart</HiddenLink>
        <HiddenLink href="/howitworks">How It Works</HiddenLink>
      </HiddenNavLinks>
      {/*<NavLink href="https://recruitcrm.io/jobs/virtustant-jobs">Jobs</NavLink>*/}
      <NavLink className={page === "Blog" ? "selected" : ""} href="/blog">Blog</NavLink>
      <NavLink className={page === "Referrals" ? "selected" : ""} href="/referrals">Referrals</NavLink>
      {/*<NavLink className="lets-talk" href="/contact-us">Contact Us</NavLink>*/}

      {/* <NavLink href="/#" tw="lg:ml-12!">
        Login
      </NavLink>*/}
      <PrimaryLink css={tw`rounded-l`} href="/contactUs">
        Lets talk!
      </PrimaryLink>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <Image
        style={{ maxHeight: "350px", }}
        src={VirtustantLogo} alt="Virtustant-Hire-Remote" />
      
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://r2.leadsy.ai/tag.js";
    script.async = true;
    script.id = "vtag-ai-js";
    script.setAttribute('data-pid', 'YHvgO7ml7GF9knKo');
    script.setAttribute('data-version', '062024');
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  
  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          <DialogTitle sx={{ m: 0, p: 0.2 }}>
            <IconButton
              onClick={toggleNavbar}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <NavToggle>
                <CloseIcon />
              </NavToggle>
            </IconButton>
          </DialogTitle>
          {links}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            ""
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
