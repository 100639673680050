import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container } from "components/misc/Layouts.js";
import { SectionHeading as ImportedHeading} from "components/misc/Headings.js";
import VirtuLogo from "../../assets/images/icons/logo512.png";
import VirtustantHireRemote from "../../images/newimages/VirtustantLogoB.png";

import comparissonChart from "../../assets/images/icons/comparissonChart.png";

const Heading = tw(ImportedHeading)`hidden sm:block`

const FeaturesContainer = tw.div`mt-10 hidden sm:block`;
const MobileFeaturesContainer = tw.div`mt-10 flex-col mb-6 justify-center block sm:hidden`;

const CompaniesRow = tw.div`flex flex-nowrap mb-6 justify-center`;
const BlankDiv = tw.div`ml-6 sm:w-48 md:w-96 flex justify-center items-center`;
const MobileBlankDiv = tw.div`ml-2 w-5/12 flex-none`;
const IconDiv = styled.div`
${(props) =>
  css`
    background-image: url("${props.imageSrc}");
  `}
${tw`sm:w-32 sm:h-24 md:w-56 md:h-40 lg:w-64 bg-contain bg-no-repeat justify-center shrink`}
`;
const ImgDiv = tw.div`p-2 bg-gray-200 border-l-2 border-l-white flex justify-center items-center w-24 sm:w-32 md:w-48 2xl:w-64 text-xs sm:text-xs md:text-sm xl:text-lg 2xl:text-xl text-center font-bold`;
const CompaniesLogo = styled.div`
${(props) =>
  css`
    background-image: url("${props.imageSrc}");
  `}
${tw`w-16 h-16 sm:w-16 sm:h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 bg-contain bg-no-repeat justify-center mb-6`}
`;
const FeaturesRow = tw.div`flex flex-nowrap mb-6 justify-center items-center`;
const Features = tw.div`ml-6 from-primary-400 h-16 w-56 sm:w-48 md:w-96 p-4 flex justify-center items-center text-center font-bold text-xs sm:text-xs md:text-sm  xl:text-lg 2xl:text-xl text-secondary-900  bg-gray-200 shadow-md`;
const FeatureData = tw.div`border-l-2 border-l-white flex justify-center items-center w-40 sm:w-32 md:w-48 2xl:w-64 h-16 p-4 text-xs sm:text-xs md:text-sm xl:text-lg 2xl:text-xl text-center  bg-gray-200 shadow-md`;

const MobileCard = tw.div`mt-10`;
const MobileFeatures = tw.div`p-6 justify-center items-center text-center font-bold text-secondary-900 text-3xl font-extrabold`;
const MobileCompanyFeature = tw.div`flex flex-nowrap justify-center`;
const MobileCompanyData = tw.div`bg-gray-200 w-48 flex justify-center items-center h-16 text-xs sm:text-xs md:text-sm xl:text-lg 2xl:text-xl text-center`;
const MobileImgDiv = tw.div`bg-gray-200 w-64 flex justify-center items-center text-xs sm:text-xs md:text-sm xl:text-lg 2xl:text-xl text-center font-bold`;
const MobileCompaniesLogo = styled.div`
${(props) =>
  css`
    background-image: url("${props.imageSrc}");
  `}
${tw`w-16 h-16 bg-contain bg-contain justify-center`}
`;

export default function HighlightComparison()
{
    const heading = "Virtustant's VA Comparison Chart"
    const highlightFeature = css`background: rgb(234, 212, 161); font-weight: 600;`
    const companies = [
        {
        company:"Virtustant",
        imageSrc:VirtustantHireRemote,
        featuresData:[
            "Less than 2 Weeks",
            "$0",
            "YES",
            "$5 per hour",
            "YES",
            "YES",
            "Very Low",
            "YES",
        ],
        featured:true
        },
        {
        company:"In-House Staff/Assistant US-based",
        featuresData:[
            "1-4 months",
            "$500-5k",
            "NO",
            "$25 per hour",
            "NO",
            "NO",
            "Low",
            "YES",
        ],
        },
        {
        company:"Virtual Assistant US-based",
        featuresData:[
            "2-3 weeks",
            "$0",
            "May Vary",
            "$20 per hour",
            "YES",
            "May Vary",
            "Varies",
            "YES",
            ],
        },
        {
        company:"Virtual Assistant Philippines",
        featuresData:[
            "1-2 months",
            "$0",
            "May Vary",
            "$4 per hour",
            "NO",
            "YES",
            "Med-high",
            "NO",
        ],
        },
    ]
    const features = ["Hiring Time","Recruiting Fee","Pre Screened Talent","Average Lowest Price Per Hour","Free Replacement","Flexible Schedule","Turnover","Same Time Zone",]

    const MobileCompanies = [
        {
        company:"Virtustant",
        imageSrc:VirtuLogo,
        featuresData:[
            "Less than 2 Weeks",
            "$0",
            "YES",
            "$5 per hour",
            "YES",
            "YES",
            "Very Low",
            "YES",
        ],
        featured:true
        },
        {
        company:"In-House Staff/Assistant US-based",
        featuresData:[
            "1-4 months",
            "$500-5k",
            "NO",
            "$25 per hour",
            "NO",
            "NO",
            "Low",
            "YES",
        ],
        },
      ]
    
    return (
        <Container>
            <Heading className="Applicant-Heading">{heading}</Heading>
            <FeaturesContainer>
                <CompaniesRow>
                    <BlankDiv>
                        <IconDiv imageSrc={comparissonChart}/>
                    </BlankDiv>
                    {companies.map((data, index) => (
                    <ImgDiv css={data.featured && highlightFeature}>
                        {data.imageSrc? <CompaniesLogo imageSrc={data.imageSrc}/>:
                        data.company}
                    </ImgDiv>
                    ))}
                </CompaniesRow>
                {features.map((feature, index) => (
                <FeaturesRow>
                    <Features>{feature}</Features>
                    {companies.map((data, i) => (
                        <FeatureData css={data.featured && highlightFeature}>
                            {data.featuresData[index]}
                        </FeatureData>
                    ))}
                </FeaturesRow>
                ))}
            </FeaturesContainer>

            <MobileFeaturesContainer>
                <CompaniesRow>
                    <MobileBlankDiv />
                    {MobileCompanies.map((data, index) => (
                    <ImgDiv css={data.featured && highlightFeature}>
                        {data.imageSrc? <CompaniesLogo imageSrc={data.imageSrc}/>:
                        data.company}
                    </ImgDiv>
                    ))}
                </CompaniesRow>
                {features.map((feature, index) => (
                <FeaturesRow>
                    <Features>{feature}</Features>
                    {MobileCompanies.map((data, i) => (
                        <FeatureData css={data.featured && highlightFeature}>
                            {data.featuresData[index]}
                        </FeatureData>
                    ))}
                </FeaturesRow>
                ))}
            </MobileFeaturesContainer>

        </Container>
    );
};