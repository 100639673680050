import React from "react";
import tw from "twin.macro";
import Slider from "react-slick";
import './logoSlider.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SectionHeading as ImportedHeading} from "components/misc/Headings.js";
import contractor from "../../assets/images/brands/contractor.png";
import gladiator from "../../assets/images/brands/gladiator.png";
import intelemark from "../../assets/images/brands/intelemark.png";
import jb from "../../assets/images/brands/jb.png";
import lazaro from "../../assets/images/brands/lazaro.png";
import nutrition from "../../assets/images/brands/nutrition.png";
import olivia from "../../assets/images/brands/olivia.png";
import prospect from "../../assets/images/brands/prospect.png";
import selfup from "../../assets/images/brands/selfup.png";
import wealth from "../../assets/images/brands/wealth.png";
import medschool from "../../assets/images/brands/medschoolinsiders.png";
import growth from "../../assets/images/brands/growth456.png";
import pipeline from "../../assets/images/brands/pipeline.png";
import samba from "../../assets/images/brands/samba.png";
import giftpack from "../../assets/images/brands/giftpack.png";
import { red } from "@mui/material/colors";

const Heading = tw(ImportedHeading)`text-primary-500`
const ImageDiv = tw.div`flex flex-wrap justify-center`

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 2,
  autoplaySpeed: 5000,
  autoplay: true,
  speed: 2000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export default ({
  heading = "Our Clients",
}) => {

  return (
    <>
      <Heading className="Applicant-Heading">{heading}</Heading>
      <div
      className="container"
      style={{
        background: "white",
        marginTop: "2rem",
        marginBottom: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <Slider className="Logo-Slider" {...settings}>
        <div>
        <ImageDiv>
          <img alt="contractor" src={contractor} />
          <img alt="gladiator" src={gladiator} />
        </ImageDiv>
        </div>
        <div>
        <ImageDiv>
          <img alt="intelemark" src={intelemark} />
          <img alt="jb" src={jb} />
        </ImageDiv>
        </div>
        <div>
        <ImageDiv>
          <img alt="nutrition" src={nutrition} />
          <img alt="olivia" src={olivia} />
        </ImageDiv>
        </div>
        <div>
        <ImageDiv>
          <img alt="prospect" src={prospect} />
          <img alt="wealth" src={wealth} />
        </ImageDiv>
        </div>
        <div>
        <ImageDiv>
          <img alt="growth" src={growth} />
          <img alt="medschool" src={medschool} />
        </ImageDiv>
        </div>
        <div>
        <ImageDiv>
          <img alt="pipeline" src={pipeline} />
          <img alt="samba" src={samba} />
        </ImageDiv>
        </div>
        <div>
        <ImageDiv>
          <img alt="giftpack" src={giftpack} />
          <img alt="intelemark" src={intelemark} />
        </ImageDiv>
        </div>
      </Slider>
    </div>
  </>
  );
};
