import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "assets/images/Landing.png";
import { PopupModal } from "react-calendly";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-8 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-900 focus:shadow-outline focus:outline-none transition duration-300`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl text-left`;
const Paragraph = tw.p`mt-5 text-xl font-medium text-gray-600 max-w-lg lg:mx-0 text-left`;


//Google Tag Manager
const GoogleTag = tw.iframe`hidden h-0 w-0`

//trustpilot
const TrustBox = tw.div`hidden sm:flex mt-3 sm:justify-center sm:items-center ml-8`;

const Actions = tw.div`flex sm:flex-row lg:justify-start mt-8 flex-wrap sm:flex-nowrap`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

export default function PresentationCard(props) {
  const imageSrc = DesignIllustration
  const imageCss = null
  const imageDecoratorBlob = false
  const Probando = tw.div`  text-orange-500  `


  const primaryButtonText="Hire Talent"


  // const { setOpenCalendly } = props;
  // const { setUrl } = props;
  const [openCalendly, setOpenCalendly] = useState(false);
  const [url, setUrl] = useState("");

  const onNext = () => {
    setOpenCalendly(true);
    setUrl(
      `https://calendly.com/d/cpqt-ffk-q2s`
    );
  }

  const JobPost = [

    {
      JobTittle: "Sales Representative",
      LinkUrl: "/hire-the-best-remote/Sales-Representative"
    },
    {
      JobTittle: "Account Manager",
      LinkUrl: "/hire-the-best-remote/Account-Manager"
    },
    {
      JobTittle: "Account Executive",
      LinkUrl: "/hire-the-best-remote/Account-Executive"
    },
    {
      JobTittle: "Proyect Manager",
      LinkUrl: "/hire-the-best-remote/Proyect-manager"
    },
    {
      JobTittle: "Virtual Assistant",
      LinkUrl: "/hire-the-best-remote/Virtual-Assistant"
    },

  ]

  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
          <Heading>Employ Remote 
            <em><Probando > Sales Representative </Probando></em>
            from LATAM and reduce your budget by up to 70%</Heading>
            
            <Paragraph>Avoid mis-hires and retain the best human capital. Hiring is easy with experts that provide tested, vetted and experienced employees.</Paragraph>
           
            <Actions>
              <TrustBox>
                <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="63fe142a6295ff0e0a72750e" data-style-height="24px" data-style-width="100%" data-theme="light">
                  <a href="https://www.trustpilot.com/review/virtustant.com" target="_blank" rel="noreferrer noopener">Trustpilot</a>
                </div>
              </TrustBox>
            </Actions>
            <Actions>
              <PrimaryButton onClick={onNext}>{primaryButtonText}</PrimaryButton>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                css={imageCss}
                src={imageSrc}
                alt="Hero"
              />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
      <GoogleTag src="https://www.googletagmanager.com/ns.html?id=GTM-TM59XZLC" />
      <PopupModal
              open={openCalendly}
              onModalClose={() => setOpenCalendly(false)}
              rootElement={document.getElementById("root")}
              url={url}
              ></PopupModal>
    </>
  );
};