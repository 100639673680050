import React from "react";
import YouTubeVideoCarousel from "./YouTubeVideoCarousel";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import tw from "twin.macro";

const Container = tw.div`mx-auto py-20`;
// button
const Actions = tw.div`flex flex-col sm:flex-row justify-center items-center pb-10`;
const PrimaryButton = tw.button`text-lg font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-900 focus:shadow-outline focus:outline-none transition duration-300 max-w-screen-md`;

export default function VideoTestimonials(props) {
  const videoIds = ["hDNPf3yvSLk", "L7ZN9Wd8YPQ"];

  const {setOpenCalendly} = props;
  const {setUrl} = props;
  
  const onNext = () => {
    setOpenCalendly(true);
    setUrl(
      `https://calendly.com/d/cpqt-ffk-q2s`
    );
  }

  return (
    <Container>
      <HeadingTitle>Video Testimonials</HeadingTitle>
      <YouTubeVideoCarousel videoIds={videoIds} />
      <Actions>
        <PrimaryButton onClick={onNext} >Learn more</PrimaryButton>
      </Actions>
    </Container>
  );
}
