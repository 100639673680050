import React from "react";
import tw from "twin.macro";
import { useState } from "react";
import { useEffect } from "react";
import { AdsPageEvent } from "utils/FacebookPixelsEvents"
import AdsFormHeader from "../components/headers/ads-form-header";
import AdsForm from "components/contact-us/AdsForm";
import FeaturedAplicants from "components/Home/FeaturedAplicants";
import { PopupModal } from "react-calendly";
import WhyUs from "components/Home/WhyUs";
import SliderTestimonials from "components/trutbox/SliderTestimonials";

//Google Calendar Modal
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

//Google Calendar Modal
const Form = tw.iframe`h-full w-full border-0 shadow-black`
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '15px',
  boxShadow: 24,
  p: 0,
};


export default function AdsPage() {

  const [url, setUrl] = useState("");
  
  // Google Calendar Modal
  const [openGC, setOpenGC] = useState(false);
  const handleOpenGC = () => setOpenGC(true);
  const handleCloseGC = () => setOpenGC(false);

  useEffect(() => {
    AdsPageEvent(window.fbq);
  },[])

    return (
        <>
          <AdsFormHeader roundedHeaderButton={true} />
          <AdsForm setOpenGC={setOpenGC}/>
          <WhyUs />
          <SliderTestimonials />
          <FeaturedAplicants />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openGC}
            onClose={handleCloseGC}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={openGC}>
              <Box sx={style}>
              <Form
                title="Form"
                src="https://calendar.app.google/7fqSnNJxfugWBnWe9"
              />
              </Box>
            </Fade>
        </Modal>
        </>
      );
}