import React from "react";
import { useState, useEffect } from "react";
import { useContext } from 'react';
import { CurrentPage } from '../components/context/CurentPage';
import Header from "../components/headers/Header";
import NewBlog from "components/Blog/BlogStructure";

import { PopupModal } from "react-calendly";
import GetStarted from "components/Home/GetStarted";

export default function BlogContent() {

  const [openCalendly, setOpenCalendly] = useState(false);
  const [url, setUrl] = useState("");

  //highlight navbar state
  const {currentPage, setCurrentPage} = useContext(CurrentPage);

  useEffect(() => {
    setCurrentPage("Blog");
    console.log(currentPage);  
  });

  return (
    <>
      <Header roundedHeaderButton={true} />
      <NewBlog />
      <GetStarted setOpenCalendly={setOpenCalendly} setUrl={setUrl}/>
      <PopupModal
        open={openCalendly}
        onModalClose={() => setOpenCalendly(false)}
        rootElement={document.getElementById("root")}
        url={url}
      ></PopupModal>
    </>
  );
}