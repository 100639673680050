import React from "react";
import tw from "twin.macro";
import { useState } from "react";
import styled from "styled-components";
import { SectionHeading as ImportedHeading } from "components/misc/Headings.js";
import { Subheading as ImportedSubHeading } from "components/misc/Headings.js";
import { PrimaryButton as ImportedButton } from "components/misc/Buttons.js";
import ReferralImg from "../../assets/images/referral-program/NewReferrals.png"
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';


const Container = tw.div`relative mt-20 p-5`;
const Content = tw.div`static max-w-screen-xl mx-auto py-10 mb-20`;
const TwoColumns = tw.div`flex flex-wrap md:flex-nowrap justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const Image = styled.img(props => [
  `background-image: url("${ReferralImg}");`,
  tw`h-full bg-contain bg-no-repeat`,
]);
const TextHeading = tw(ImportedHeading)`text-xl mx-10 lg:text-2xl md:mx-5`;
const Heading = tw(ImportedHeading)`bg-white`;
const Subheading = tw(ImportedSubHeading)`text-2xl lg:text-4xl text-secondary-900 mr-2`;
const QuestionsDiv = tw.div`items-center justify-center text-center`;
const Button = tw(ImportedButton)`mt-2 text-white`;
const Span = tw.text`text-primary-500`
const Text = tw.text``
const Form = tw.iframe`h-full w-full border-0 shadow-black`

const ExtraContent = tw.div`flex flex-wrap items-center justify-center text-center `;


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};




export default (props) => {

  const { setReferralOpenCalendly } = props;
  const { setUrl } = props;

  const onNext = () => {
    setReferralOpenCalendly(true);
    setUrl(
      `https://calendly.com/d/3nt-jkr-8df/discovery-call-virtustant-referral-link`
    );
  }

  //Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container>
      <ExtraContent>
        <span>Make money with our</span>
      </ExtraContent>
      <Heading>NEW Referral Program</Heading>
      <Content>
        <TextHeading>
          <Span>  Full-Time Agent:</Span> Refer us a company, and if they hire a full-time agent who stays with us for at least one month, you will earn $500 per agent. Payment is made once per agent.
          <br/>          <br/>

          <Span> Part-Time Agent:</Span> Similarly, for every part-time agent hired and retained for one month, you will earn $250 per agent. Payment is made once per agent.
         
          <br />
        </TextHeading>
        <TwoColumns>
          <Column>
            <Image src={ReferralImg}>
            </Image>
          </Column>
          <Column>
            <QuestionsDiv>
              <Subheading>What are the steps?</Subheading>
              <Text>Send this page to your friend so he can:</Text>
              <br />
              <Button onClick={onNext}>Book a call</Button>
              <br />
              <br />
              <Subheading>And then</Subheading>
              <div>
                <Text>Tell us about it here</Text>
              </div>
              <Button onClick={handleOpen}>Who you are Referring</Button>
              <br /><br />

              <Text>Two easy steps for you <Span> to win money!</Span> </Text>
            </QuestionsDiv>
          </Column>
        </TwoColumns>
        <ExtraContent>
        </ExtraContent>
      </Content>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Form
              title="Form"
              src="https://forms.monday.com/forms/embed/db24d04b30b37f82e904658f64b911fc?r=use1"
            />
          </Box>
        </Fade>
      </Modal>


    </Container>
  );
};
