import JobImage2 from "assets/images/jobs/job2.png"
import JobImage3 from "assets/images/jobs/job3.png"
import JobImage4 from "assets/images/jobs/job4.png"
import JobImage5 from "assets/images/jobs/job5.png"
import JobIMage6 from "assets/images/jobs/job6.png"
import JobIMage7 from "assets/images/jobs/job7.png"
import JobIMage8 from "assets/images/jobs/job8.png"
import JobIMage9 from "assets/images/jobs/job9.png"
import JobIMage11 from "assets/images/jobs/job11.png"
import JobIMage12 from "assets/images/jobs/job12.png"
import JobIMage13 from "assets/images/jobs/job13.png"
import JobIMage15 from "assets/images/jobs/job15.png"
import JobIMage16 from "assets/images/jobs/job16.png"
import JobIMage17 from "assets/images/jobs/job17.png"
import JobIMage18 from "assets/images/jobs/job18.png"
import JobIMage19 from "assets/images/jobs/job19.png"


const CurrentPositions = [
  {
    JobTitle: "Creator Outreach Specialist",
    id: 1,
    status: "Hired",
    hourSalary: "$8 ~ $12",
    JobType: "Part time",
    imgSrc: JobImage2,
    url: `/jobs/jobDescription/1`,
    form: "https://forms.monday.com/forms/embed/7ac04aaf58407e628ee3777ba59d742b?r=use1",
    JobDescription: "We are seeking a  motivated, part-time Creator Outreach Specialist to join our team headquartered in New York City but with a fully remote operation currently. Reporting directly to the founders of 456 Growth Media as well as our Business Development Manager, the ideal candidate will have the opportunity to be a part of a dynamic team while enhancing procedures for the effective onboarding of 456 Growth Media creators.",
    CompanyValues: [
      "People First",
      "Transparency breeds trust ",
      "Embrace the Journey",
      "Exceed expectations",
      "Innovation is rewarded",
      "Drive towards a sustainable future"
    ],
    Skills: [
      "Team player with exceptional interpersonal and follow-up skills",
      "Ambitious self-starter with a positive attitude, and a great work ethic",
      "Takes initiative to solve problems and complete tasks as they arise",
      "Adaptive to change who thrives in an environment where change is the norm",
      "Tech savvy with the motivation to meet and beat deadlines",
      "Prior experience working directly with Influencers, celebrity talent, and their representations"
    ],
    ExperienceAndQualifications: [
      "Bachelors Degree or equivalent business experience",
      "2+ years in a similar role preferred (Business Development Role Acceptable)",
      "2+ years in a similar role preferred (Business Development Role Acceptable)",
      "Experience with creator platforms tools like CreatorIQ, GRIN, Click Analytics for creator discovery a plus.",
      "Extensive experience in social media platforms (Instagram, Facebook, TikTok, YouTube, and Pinterest) with an eye towards the future of influencer marketing and platforms.",
      "Experience with additional tech-based solutions such as Google Sheets, Drive, Salesforce, and email subscription software.",
      "Solid organizational, and verbal / written communication skills",
      "Ability to manage high volume email",
      "Experience with Salesforce with a focus on Leads, and Accounts Objects.",
      "Minimum 1-2 years of managing client outreach programs.",
      "Strong organization skills",
      "Open to repetitive processes on a structured timeline."
    ],
    PreferredSkillsAndTools: [
      "Demonstrated passion for social media and audience acquisition.",
      "Proven ability to grow a loyal following on social media.",
      "Ability to learn or grow with platform analytic evaluation",
      "Demonstrated taste and judgment",
      "An eye for aesthetics that can strengthen the creator's visual brand."
    ],
    Tasks: [
      "Utilize proprietary and third-party tools to aggregate and analyze social and digital platform/creator data.",
      "Source and compile target list for creator outreach",
      "Convert and format outreach document",
      "Setup and process email cadence flow and manage and track responses.",
      "Manage Salesforce database responsibilities inclusive of lead generation, account creation and data synchronization.",
      "Flag new trends happening on platforms like TikTok and Instagram for our team to capitalize on as well as implement new strategies and trends for creators.",
      "Alert the team to potential ''drama'' in influencer communities that might negatively affect creator campaigns",
      "Manage light customer service support for direct creator communication and/or representation inquiries."
    ],
    WorkSchedule: [],
    LanguageRequirements: [],
    AdditionalDetails: [],
  },
  {
    JobTitle: "Contract Administrator",
    id: 2,
    status: "Hired",
    hourSalary: "$8 ~ $12",
    JobType: "Part time",
    imgSrc: JobImage2,
    url: `/jobs/jobDescription/2`,
    form: "https://forms.monday.com/forms/embed/006d108778e655471bcf4128cafeda89?r=use1",
    JobDescription: "We are seeking a motivated, part-time Contract Administrator to join our team headquartered in New York City but with a fully remote operation currently. Reporting directly to the Senior Contract Support Specialist of 456 Growth Media, the ideal candidate will have the opportunity to be a part of a dynamic team while enhancing procedures. The candidate will be responsible for the data entry, preparation, and processing of 456 Growth media contracts.",
    CompanyValues: [],
    Skills: [
      "Team player with exceptional interpersonal and follow-up skills",
      "Ambitious self-starter with a positive attitude, and a great work ethic",
      "Takes initiative to solve problems and complete tasks as they arise",
      "Adaptive to change who thrives in an environment where change is the norm",
      "Tech savvy with the motivation to meet and beat deadlines",
    ],
    ExperienceAndQualifications: [
      "Bachelors Degree or equivalent business experience (2+ Years)",
      "2+ years in a similar role preferred (Media Buying, Talent Agency (Legal), Brand (Legal))",
      "Experience in Salesforce, and in media, a plus",
      "Technology-savvy with experience in contract management systems, electronic signature platforms, Google Suite, Microsoft Office Suite (including Word, PowerPoint, Excel, Sharepoint, PandaDoc)",
      "Solid organizational, and verbal / written communication skills",
      "Past legal clerical experience or Legal externs welcome",
      "Fine eye for attention to detail",
    ],
    PreferredSkillsAndTools: [
      "Salesforce",
      "Contract Exposure",
      "PandaDoc or Similar E-sign tool experience preferred",
      "Math centric candidate preferred",
      "Social Media experience preferred",
      "Ideal candidate has paralegal experience, or contract management experience"
    ],
    Tasks: [
      "Data Entry",
      "Responsible for overseeing all aspects of contract data entry by the sales department and cross-departmental collaboration; includes making corrections and adjustments in the contract database, updating contract details with the legal department, thoroughly reviewing all aspects of a contract to transfer appropriately into the internal contracting system",
      "Implement contracting best practices",
      "Reporting",
      "Assist contracting department heads with weekly contract reporting, year-end audit requests, month-end close sales reports",
      "Contract Creation and Issuance",
      "Working with external/internal legal counsel to track, manage, and execute agreements",
      "Enter company agreements into 456 Growth Media contract management system for tracking and ongoing management in an organized manner; including managing and maintaining",
      "Draft basic legal agreements",
      "Assist with electronic signature processes",
      "Administrative and direct support for various Legal Operations projects",
    ],
    WorkSchedule: [],
    LanguageRequirements: [],
    AdditionalDetails: ["25hrs Week"],
  },
  {
    JobTitle: "Sales Representative",
    id: 3,
    status: "Hired",
    hourSalary: "$8 ~ $15",
    JobType: "FullTime",
    imgSrc: JobImage2,
    url: "/jobs/jobDescription/3",
    form: "https://forms.monday.com/forms/embed/58b88b29d2fc74f0c2797df0b33fa421?r=use1",
    JobDescription: "As a Sales Representative, you will be responsible for strategically identifying and contacting prospects who best fit our ideal customer profiles. You will work directly with our Co-Founders to support our sales efforts and help us achieve our growth goals. You will need a strong work ethic, exceptional communication and interpersonal skills, and the ability to quickly develop rapport with senior executives.",
    CompanyValues: [],
    Skills: [
      "Work directly with our Co-Founders to support our sales efforts",
      "Make sales and marketing calls including cold calling, follow-up calls, setting appointments, and meeting logistics",
      "Initiate research of prospects and qualify them",
      "Establish and build relationships with contacts to develop leads",
      "Engage in active listening with callers, confirm or clarify information, and address any questions they may have as needed",
      "Utilize software, databases, and sales tools appropriately",
      "Draft and negotiate proposals",
      "Research, understand, and verbally articulate a variety of topics and materials by phone, in person, and in email communication",
    ],
    ExperienceAndQualifications: [
      "Must be a self-starter",
      "No experience required, but previous sales experience welcome",
      "Passion for the sales process",
      "Knowledge of B2B sales and marketing tactics is a plus",
      "Extremely curious; you love to read and learn new things",
      "Highly organized people person, detail-oriented, and sees value in client success",
      "Ability to thrive in a fast-paced and high-energy environment",
      "Extremely efficient using technology",
      "Excellent communicator over the phone and through email",
      "Can work independently and be a team player when called upon",
      "Positive attitude and constantly searching for areas of improvement",
    ],
    PreferredSkillsAndTools: [],
    Tasks: [],
    WorkSchedule: [],
    LanguageRequirements: [],
    AdditionalDetails: [
      "Ongoing professional development and mentorship",
    ],
  },
  {
    JobTitle: "Conversion Rate Optimization Specialist",
    id: 4,
    status: "Declined",
    hourSalary: "$9 ~ $23",
    JobType: "Part Time",
    imgSrc: JobImage3,
    url: "/jobs/jobDescription/4",
    form: "https://forms.monday.com/forms/embed/8b8e34ba7811160ebda1966b6cac811b?r=use1",
    JobDescription: "We are looking for an experienced Conversion Rate Optimization (CRO) Specialist to join our team. The primary responsibility of the CRO Specialist is to analyze client websites and identify areas for improvement to increase conversions. The ideal candidate will have at least 5 years of experience in CRO and possess excellent analytical and problem-solving skills. Excellent English fluency is required, and experience working with law firm clients is preferred.",
    CompanyValues: [],
    Skills: [],
    ExperienceAndQualifications: [
      "5+ years of experience as a CRO Specialist",
      "Excellent English fluency",
      "Strong analytical and problem-solving skills",
      "Experience working with law firm clients is preferred",
    ],
    PreferredSkillsAndTools: [
      "Proficiency in specific software or tools related to Conversion Rate Optimization",
      "Knowledge of commission or bonus structure details",
      "Understanding of time allocation for each responsibility in this role",
    ],
    Tasks: [
      "Analyze client websites to identify areas for improvement to increase conversions",
      "Develop and execute strategies to improve conversion rates",
      "Conduct A/B testing and other experiments to determine the effectiveness of different approaches",
      "Collaborate with design and development teams to implement changes and improvements",
      "Monitor website performance and make recommendations for ongoing improvements",
    ],
    WorkSchedule: [],
    LanguageRequirements: [],
    AdditionalDetails: ["starting with 10-20hs/week"],
  },
  {
    JobTitle: "Digital Marketing Manager",
    id: 5,
    status: "Declined",
    hourSalary: "$5 ~ $10",
    JobType: "FullTime",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/5",
    form: "https://forms.monday.com/forms/embed/4c2dae27370c37b640a06277a6aae926?r=use1",
    JobDescription: "We are seeking a Digital Marketing Manager to help us increase brand awareness and drive business growth through various digital channels. The ideal candidate should be a smart, ambitious person who can take leadership initiative and experiment with different marketing strategies. The candidate must have excellent copywriting skills and be able to create and push out engaging content. The work schedule is flexible, but PST hours work best.",
    CompanyValues: [],
    Skills: [
      "Excellent copywriting skills",
      "Familiarity with content management systems and content creation",
      "B2 level English proficiency",
      "Leadership initiative and ability to experiment with new ideas and strategies"
    ],
    Tasks: [
      "Experimentation: Constantly test and evaluate new marketing strategies and tactics to find the most effective channels for our brand.",
      "Content Creation: Develop and execute a content marketing strategy to create engaging and informative content that resonates with our target audience.",
      "LinkedIn Outreach: Leverage LinkedIn to build relationships with potential clients, partners, and influencers.",
      "New Channel Engagement: Identify and engage new channels to expand our reach and brand awareness.",
      "SEO: Develop and execute a comprehensive SEO strategy to improve our website's visibility and organic traffic."
    ],
    PreferredSkillsAndTools: [
      "Experience with LinkedIn outreach",
      "Knowledge of SEO best practices",
      "Tools: Content management systems"
    ],
    WorkSchedule: ["Copywriting: 50%", "New Channel Engagement: 20-30%", "SEO: 20%"],
    ExperienceAndQualifications: [],
    LanguageRequirements: [],
    AdditionalDetails: [],
  },
  {
    JobTitle: "Talent Acquisition Specialist",
    id: 6,
    status: "Hired",
    hourSalary: "",
    JobType: "FullTime",
    imgSrc: JobImage2,
    url: "/jobs/jobDescription/6",
    form: "https://forms.monday.com/forms/embed/e9e28a2638937ef57b7d04fb902a75bd?r=use1",
    JobDescription: "We are seeking a highly motivated and experienced Talent Acquisition Specialist to join our team. As a Talent Acquisition Specialist, you will play a crucial role in sourcing and attracting top talent to meet our clients' hiring needs. You will be responsible for talent sourcing, conducting interviews, and performing CRM data entry. This is a remote position with a work schedule of 9-6pm EST.",
    CompanyValues: [],
    Skills: [
      "Competitive sourcing strategies to attract passive candidates.",
      "Assessment and screening techniques to identify the most qualified candidates.",
      "Collaborating with hiring managers to understand job requirements and candidate profiles.",
      "Providing feedback and recommendations to hiring managers regarding candidate selection.",
      "Managing multiple job requisitions simultaneously.",
      "Maintaining confidentiality and professionalism throughout the recruitment process."
    ],
    Tasks: [
      "Talent sourcing: Utilize various sourcing methods and platforms, with a primary focus on LinkedIn, to identify and engage potential candidates.",
      "Interviewing: Conduct interviews to assess candidates' qualifications, skills, and cultural fit for specific job roles.",
      "CRM Data Entry: Maintain accurate and up-to-date candidate information in our customer relationship management (CRM) system.",
    ],
    WorkSchedule: [
      "Talent sourcing: 55%",
      "Interviewing: 35%",
      "CRM Data Entry: 10%"
    ],
    ExperienceAndQualifications: [
      "Previous recruitment experience is required.",
      "Proficiency in English at a C1 (Advanced) level.",
      "Strong interpersonal and communication skills.",
      "Excellent organizational and time management abilities.",
      "Attention to detail and accuracy."
    ],
    PreferredSkillsAndTools: [
      "Familiarity with LinkedIn as a sourcing tool.",
      "Experience using CRM systems."
    ],
    LanguageRequirements: [],
    AdditionalDetails: [],
  },
  {
    JobTitle: "Sales Development Representative (SDR)",
    id: 7,
    status: "hired",
    hourSalary: "$7 ~ $13",
    JobType: "FullTime",
    imgSrc: JobImage3,
    url: "/jobs/jobDescription/7",
    form: "https://forms.monday.com/forms/embed/86f1972572ede34ebfe8cd3b4ca531f6?r=use1",
    JobDescription: "We are seeking a highly motivated and driven Sales Development Representative (SDR) to join our team. As an SDR, you will play a crucial role in generating qualified leads and initiating the sales process. This is a full-time position with a work schedule of Monday to Friday, 8 am to 5 pm CST.",
    CompanyValues: [],
    Skills: [],
    Tasks: [
      "Manage CRM: Ensure accurate and up-to-date data entry and organization within the CRM system.",
      "Make outbound calls: Conduct 80-100 outbound calls per day to potential customers, engaging them in meaningful conversations.",
      "Lead Qualification: Identify and qualify leads by understanding their needs, pain points, and decision-making process.",
      "Handoff qualified leads: Collaborate with the account executive team to handoff qualified leads for further sales process.",
      "Data enrichment: Gather additional information and enrich existing lead data to enhance targeting and personalization efforts.",
      "Knowledge improvement/training: Dedicate 10% of your time to continuous learning, improving your sales skills, and staying updated on industry trends.",
      "Administrative work: Allocate 10% of your time to administrative tasks supporting the sales development process."
    ],
    WorkSchedule: [],
    ExperienceAndQualifications: [
      "Passion for learning: Demonstrate a strong desire for personal and professional growth, along with a coachable attitude.",
      "Growth mindset: Embrace challenges, seek feedback, and consistently strive for improvement.",
      "Thick skin: Display resilience and the ability to handle rejection in a high-volume outbound calling environment."
    ],
    PreferredSkillsAndTools: [
      "Microsoft Dynamics experience is a bonus, while any CRM experience is preferred.",
      "Proficiency in using various sales and communication tools to facilitate lead generation and engagement."
    ],
    LanguageRequirements: [
      "Native-level English proficiency."
    ],
    AdditionalDetails: [
      "Industry Variety: The role involves calling into multiple industries to identify decision-makers who are potential customers for security cameras and maintenance labor for buildings.",
      "Work-Life Balance: We value work-life balance and strive to create a supportive and flexible work environment."
    ]
  },
  {
    JobTitle: "Appointment Setter / Business Development Representative (BDR)",
    id: 8,
    status: "Declined",
    hourSalary: "$6.5 ~ $14",
    JobType: "FullTime",
    imgSrc: JobImage3,
    url: "/jobs/jobDescription/8",
    form: "https://forms.monday.com/forms/embed/ddc61598eeab00c9b2a3431b07a3fe8e?r=use1",
    JobDescription: "We are currently seeking a dedicated and experienced Appointment Setter / BDR to join our team. This role is pivotal to our company's success and requires an individual who is strategy-focused, goal-oriented, and possesses superior communication skills. The ideal candidate will be responsible for qualifying leads, conducting outbound calls, updating the CRM system, and sending emails to prospects. The ability to work during US Business Hours (9-5 CST - Flex) is a must.",
    CompanyValues: [],
    WorkSchedule: [],
    Tasks: [
      "Qualify & Identify Leads (25%): Understand the campaign strategy, analyze, and discern who to call based on provided leads.",
      "Outbound Dials (60%): Conduct 50-100 outbound calls per day to potential clients.",
      "Update CRM (7.5%): Maintain a systematic approach to updating CRM post setting up meetings.",
      "Send Emails (7.5%): Communicate with prospects via emails."
    ],
    ExperienceAndQualifications: [
      "Previous experience in a sales role or as a BDR is required."
    ],
    PreferredSkillsAndTools: [
      "Proficiency in Zoom.info or Odoo CRM is highly desirable."
    ],
    LanguageRequirements: [
      "A native level of English proficiency is required."
    ],
    Skills: [
      "Strong interpersonal and customer service skills.",
      "Ability to handle rejection and continue with a positive attitude.",
      "Track record of over-achieving quota.",
      "Ability to multitask, prioritize, and manage time effectively.",
      "Familiarity with CRM systems and practices."
    ],
    AdditionalDetails: ["If you are a motivated individual with a passion for sales and a desire to work in a dynamic environment, we would love to hear from you. Apply now to join our team and start making a difference!"]
  },
  {
    JobTitle: "Intake Specialist",
    id: 9,
    status: "Hired",
    hourSalary: "$5 ~ $12",
    JobType: "FullTime",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/9",
    form: "https://forms.monday.com/forms/embed/92d6a8f09f1cfe41113367b9e7b2fb3b?r=use1",
    JobDescription: "We are seeking a highly motivated and empathetic individual to join our team as an Intake Specialist. As the first point of contact for our clients, you will play a critical role in providing exceptional customer service and ensuring a positive experience for those seeking assistance with addiction issues. The ideal candidate should possess excellent communication skills, strong attention to detail, and the ability to handle multiple tasks simultaneously.",
    CompanyValues: [],
    WorkSchedule: [
      "Receptionist - 50%",
      "Intake - 40%",
      "Miscellaneous (including responsibilities 3-7) - 10%"
    ],
    Tasks: [
      "Act as the first impression for our organization, warmly greeting clients and directing them to the appropriate staff members. If the client is an existing one, you will direct them accordingly. For new clients, you will take charge of the intake process.",
      "Facilitate the intake process for new clients, including gathering necessary information and conducting initial assessments. Ensure that all required documentation is completed accurately and efficiently.",
      "Open and maintain client files, ensuring that they are organized and up-to-date.",
      "Maintain regular communication with active clients to provide updates, answer queries, and offer support as needed.",
      "Make phone calls to courts on behalf of clients to confirm court dates and times, and gather relevant information.",
      "Assist Spanish-speaking clients by providing translation services as required.",
      "Manage appointment scheduling and coordination for clients, ensuring timely and accurate arrangements.",
      "Handle incoming emails, respond to inquiries, and forward messages to the appropriate individuals."
    ],
    ExperienceAndQualifications: [
      "Customer service experience, preferably in an intake or inbound call role",
      "Proficiency in English and Spanish (C2 level or equivalent)",
      "Strong verbal and written communication skills",
      "Excellent organizational and time management abilities",
      "Proficiency in Microsoft Office suite",
      "Familiarity with case management software, specifically Clio, is preferred",
      "Ability to work collaboratively in a team environment",
      "Critical thinking and problem-solving skills"
    ],
    PreferredSkillsAndTools: [],
    LanguageRequirements: [],
    Skills: [],
    AdditionalDetails: [
      "In addition to the outlined responsibilities, the ideal candidate should possess a friendly demeanor, a pleasant speaking voice, and a genuine desire to help individuals with addiction issues. This role offers the opportunity for career growth, with raises based on performance and end-of-year bonuses."
    ]
  },
  {
    JobTitle: "Remote Office Manager",
    id: 10,
    status: "closed",
    hourSalary: "$7 ~ $15",
    JobType: "FullTime",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/10",
    form: "https://forms.monday.com/forms/embed/644ad8810a404bdfe1644f61bc6152ef?r=use1",
    JobDescription: "We are seeking a highly organized and motivated Remote Office Manager to oversee our Front and Back Office Department remotely. The Remote Office Manager will be responsible for supervising a team of 60+ employees, ensuring efficient workflow, and maintaining a productive and organized work environment. This is a remote position, allowing the candidate to work from the comfort of their own home.",
    CompanyValues: [],
    WorkSchedule: [],
    Tasks: [
      "Coordinate and monitor office operations to ensure company requirements are sufficiently met on a daily basis.",
      "Plan goals/objectives for office operations and identify the resources (staff, tools, funds) required to obtain the company's desired results.",
      "Oversee departments, including Front Office, Back Office, Claims/Billing, and support positions.",
      "Handle payroll, calculate weekly time sheets for all staff and outsourced employees, and submit bi-weekly payroll.",
      "Confirm the number of patients seen daily.",
      "Select job applicants, conduct prescreening, and initial interviews for all open positions.",
      "Ensure staff is performing to support and contribute to the needs of the organization.",
      "Produce and update company policies and procedures, instructions, and staff checklists.",
      "Monitor office procedures, collaborate with staff to resolve problems, and suggest/implement changes as needed.",
      "Resolve operational problems related to customer service, accounting procedures, and systems.",
      "Schedule weekly staff meetings.",
      "Operate standard office equipment and software effectively, including Soft-phone system, facsimile features, computer, MS Excel, PowerPoint & Word, and Adobe Acrobat Reader.",
      "Understand all company software and tools entirely, and train staff on use based on job descriptions.",
      "Discuss with management the needs for staff and tools, and create user IDs, logins, and passwords when necessary. Revoke access to platforms, including company group health insurance plans, when employees leave.",
      "Provide continuous feedback, constructive criticism, encouragement, and instruction to staff as needed.",
      "Provide guidance and direction to staff for their professional development, including facilitating cross-training.",
      "Follow and complete all tasks on assigned company checklists.",
      "Perform quality assurance for all departments.",
      "Monitor completion of all employees' daily checklists.",
      "Coordinate and oversee the completion of special projects as needed."
    ],
    ExperienceAndQualifications: [
      "Associate degree (A. A. / A. S.) or equivalent from a two-year college or technical school; or two to four years related experience and/or"
    ],
    PreferredSkillsAndTools: [
      "Knowledge of Microsoft Office Suite."
    ],
    LanguageRequirements: [
      "Ability to read and interpret documents such as safety rules, operating and maintenance instructions, and procedure manuals.",
      "Ability to write routine reports and correspondence.",
      "Ability to speak effectively before groups of customers or employees of the organization."
    ],
    "Skills": [],
    AdditionalDetails: [
      "Supervisory Responsibilities:",
      "Directly supervise 60+ employees in the Front and Back Office Department.",
      "Carry out supervisory responsibilities in accordance with the organization's policies and applicable laws.",
      "Responsibilities include training employees; planning, assigning, and directing work; appraising performance; rewarding and disciplining employees; addressing complaints and resolving problems; performing quality assurance; generating reports; and handling payroll."
    ]
  },
  {
    JobTitle: "Campaign Manager",
    id: 11,
    status: "declined",
    hourSalary: "$20 - $24",
    JobType: "FullTime",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/11",
    form: "https://forms.monday.com/forms/embed/87e1f1f945acb9674c393a146e928309?r=use1",
    JobDescription: "The Campaign Manager will play a crucial role in driving successful outcomes for clients in the contractor industry who utilize our digital marketing services. As a Campaign Manager, you will be responsible for managing a portfolio of clients, ensuring their retention, expansion, and overall satisfaction. This position requires strong account management skills, experience in digital marketing agencies, and the ability to build and maintain relationships with clients. The Campaign Manager will report to the Head of Retention & Expansion and work closely with internal teams to deliver exceptional customer service and achieve business goals.",
    CompanyValues: ["Freedom", "Empowerment", "Organic Growth"],
    "WorkSchedule": [],
    Tasks: [
      "Provide world-class client support and serve as the face of Contractor Independence to our clients, demonstrating enthusiasm, positivity, and inspiring excitement in all interactions.",
      "Conduct launch meetings to onboard new clients, reviewing hand-off material from the Sales team, preparing presentation decks, and coordinating with Campaign Specialists for a successful campaign launch.",
      "Manage the implementation of our digital marketing products, collaborating with internal teams and clients to gather necessary administrative information and ensuring prompt delivery of qualified leads.",
      "Monitor and improve campaign performance, reviewing monthly performance with clients, identifying challenges and opportunities, and collaborating with internal teams to address technical issues.",
      "Develop customer relationships to maximize retention and expansion opportunities, understand client business goals, address concerns, and act as a trusted advisor.",
      "Identify opportunities for expansion and upsell, asking probing questions, referencing success criteria, and closing opportunities to generate additional revenue.",
      "Advocate for customer success across departments, sharing customer feedback, analyzing friction areas, and presenting ideas for improving the customer journey."
    ],
    ExperienceAndQualifications: [
      "Minimum 2 years of experience in an Account Management role.",
      "Experience in a digital marketing agency is preferred.",
      "Familiarity with the professional contractor industry is desired.",
      "Proven ability to manage 40-50+ clients, delivering high-touch customer service.",
      "Strong presentation and communication skills, with the ability to relay complex concepts to non-technical individuals and collaborate with highly-technical team members.",
      "Track record of exceeding portfolio goals for retention and expansion.",
      "Demonstrated knowledge of marketing/sales pipelines and customer journey.",
      "Availability to work US business hours (9am-6pm Eastern)."
    ],
    PreferredSkillsAndTools: [],
    LanguageRequirements: [],
    Skills: [],
    AdditionalDetails: [
      "We are a digital marketing agency based in Richmond, Virginia. Our mission is to free contractors from leads companies forever and empower them to take control of their business and grow organically. With a focus on the professional contractor industry, we have achieved significant growth and helped over 350 contractors generate high-quality job requests. As a Campaign Manager, you will have the opportunity to be part of our expanding team and contribute to our continued success."
    ]
  },
  {
    JobTitle: "Administrative Assistant",
    id: 12,
    status: "hired",
    hourSalary: "$5",
    JobType: "Part-time",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/12",
    form: "https://forms.monday.com/forms/embed/09d67423f335e2f99627ce8a010c9456?r=use1",
    JobDescription: "We are currently seeking a dedicated and organized Administrative Assistant to join our team. As an Administrative Assistant, you will play a crucial role in supporting our daily operations and ensuring the smooth functioning of our office. You will be responsible for a wide range of administrative tasks and will work closely with executives and team members to facilitate efficient workflow.",
    "CompanyValues": [],
    "WorkSchedule": [],
    Tasks: [
      "Manage phone calls, emails, and correspondence, providing timely and professional responses.",
      "Coordinate and maintain executive calendars, scheduling appointments, meetings, and conferences.",
      "Prepare and distribute important documents, presentations, and reports with accuracy and confidentiality.",
      "Assist in organizing and coordinating internal and external meetings, including travel arrangements.",
      "Maintain electronic and physical files, ensuring proper organization, retrieval, and storage of documents.",
      "Assist with the preparation and processing of expense reports, invoices, and financial documentation.",
      "Conduct research and compile data to support various projects and initiatives.",
      "Assist in the preparation and editing of legal documents, contracts, and agreements.",
      "Liaise with clients, partners, and vendors, providing professional and courteous assistance as needed.",
      "Coordinate and facilitate the onboarding process for new employees, including paperwork and equipment arrangements.",
      "Support the team by coordinating office supplies, equipment maintenance, and other administrative needs.",
      "Proactively identify areas for process improvement and implement effective solutions.",
      "Maintain a professional and welcoming office environment, ensuring cleanliness and organization."
    ],
    ExperienceAndQualifications: [
      "Previous experience as an administrative assistant is preferred.",
      "Strong organizational and time management skills.",
      "Excellent communication and interpersonal abilities.",
      "Proficiency in Microsoft applications, GMail, and willingness to learn new software.",
      "Minimum C1 English Level."
    ],
    "PreferredSkillsAndTools": [],
    LanguageRequirements: [
      "Minimum C1 English Level."
    ],
    Skills: [
      "Strong organizational and time management skills.",
      "Excellent communication and interpersonal abilities."
    ],
    AdditionalDetails: [
      "The role requires attention to detail, confidentiality, and the ability to work in a fast-paced environment.",
      "Flexibility and adaptability are key, as the candidate may need to handle unexpected tasks or changes in priorities.",
      "Part-time, 5-6 hours daily between 7 AM to 6 PM Mountain Time."
    ]
  },
  {
    JobTitle: "Sales Development Representative",
    id: 13,
    status: "hired",
    hourSalary: "$6 - 13",
    JobType: "Full-time",
    imgSrc: JobImage5,
    url: "/jobs/jobDescription/13",
    form: "https://forms.monday.com/forms/embed/b1d75b99fb90802fd4e30a9bd0e051c3?r=use1",
    JobDescription: "We are seeking a motivated and dynamic Sales Development Representative to join our team. As a Sales Development Representative, you will play a crucial role in prospecting and qualifying leads for both new and existing accounts. This remote position offers a fun work environment and an opportunity to contribute to the growth and success of our company.",
    CompanyValues: ["Personal growth", "Faith", "Fun", "Greatness", "Ethical conduct"],
    "WorkSchedule": [],
    Tasks: ["Lead Prospecting and Qualification: Utilize various channels to identify and reach out to potential sales leads. Initiate initial phone or email contact to engage prospects and qualify their interest in our product/services. Act as the main point of contact for the sales lead qualification process within your team.",
      "Account Management: Maintain regular communication with existing accounts and leads to build relationships, increase brand awareness, provide educational opportunities, and request referrals. Facilitate in-person meetings and calls between new clients and Account Executives.",
      "Customer Support: Address incoming calls and emails from clients, identify their needs or concerns, and provide appropriate solutions or suggestions for future products or services."],
    ExperienceAndQualifications: ["Sales Experience: Hands-on experience with various sales techniques, including cold calls, to engage and convert leads into customers.",
      "CRM and Data Management: Proficiency in working with CRM software to manage leads, track customer interactions, and ensure efficient communication and collaboration between sales and marketing teams.",
      "Analytical Skills: Familiarity with MS Excel for analyzing spreadsheets and charts, enabling you to interpret and leverage sales performance metrics to optimize strategies.",
      "Strong Communication:  Excellent verbal and written communication skills to effectively engage leads, build relationships, and provide exceptional customer support."],
    "PreferredSkillsAndTools": [],
    "LanguageRequirements": [],
    Skills: ["Hands-on experience with various sales techniques", "Proficiency in working with CRM software", "Analytical skills using MS Excel", "Excellent verbal and written communication skills"],
    AdditionalDetails: ["We are a leading Demand Generation organization focused on developing sales leaders and growing sales pipelines.", "Join us and be part of our journey to drive sales growth and success."]
  },
  {
    JobTitle: "Virtual Assistant",
    id: 14,
    status: "closed",
    hourSalary: "$7-9",
    JobType: "Part-Time",
    imgSrc: JobImage5,
    url: "/jobs/jobDescription/14",
    form: "https://forms.monday.com/forms/embed/e75c62b4c5f8b6010dce6f4b9cfcc20e?r=use1",
    JobDescription: "We are seeking a bilingual Spanish-speaking professional virtual assistant to support a Registered Dietitian Supervisor in their daily tasks and responsibilities. The virtual assistant will primarily be responsible for facilitating video and phone consultations, processing patient lists, monitoring chats, providing language translation and interpretation, performing quality assurance tasks, and assisting with administrative duties such as creating Excel spreadsheets and PowerPoint presentations. Strong English skills, organization, efficiency, and excellent communication are essential for success in this role.",
    CompanyValues: [],
    WorkSchedule: ["Video and phone consults: As required",
      "Processing daily RD patient lists: Daily",
      "Monitoring chats: Ongoing",
      "Translating and interpreting Spanish language candidates/material/video: As needed",
      "Performing QA on Patient Recognition Sheets: Ongoing",
      "Creating Excel spreadsheets: As assigned",
      "Creating PowerPoint presentations: As assigned"],
    Tasks: ["Facilitate video and phone consultations",
      "Process daily RD patient lists",
      "Monitor chats",
      "Translate and interpret Spanish language candidates/material/video",
      "Perform QA on Patient Recognition Sheets",
      "Create Excel spreadsheets",
      "Create PowerPoint presentations"],
    ExperienceAndQualifications: ["Bilingual in Spanish: Fluent in both spoken and written Spanish to effectively communicate and translate materials.",
      "Strong English skills: Proficient in reading, writing, and speaking English to ensure clear communication with the supervisor and other team members. English fluency is mandatory.",
      "Plant-based nutrition background (optional): Having a background or knowledge in plant-based nutrition is highly beneficial for understanding and assisting with relevant dietary information.",
      "Technology-savvy: Comfortable using various digital platforms, tools, and software for scheduling, communication, document processing, and data management.",
      "Organized: Ability to efficiently manage tasks, prioritize responsibilities, and maintain a well-structured work routine.",
      "Dependable: Reliable and trustworthy in meeting deadlines and completing assigned tasks accurately.",
      "Efficient: Able to work diligently and optimize productivity while maintaining high-quality work.",
      "Excellent communication: Strong interpersonal and communication skills to interact effectively with the supervisor, patients, and other team members."],
    PreferredSkillsAndTools: [],
    LanguageRequirements: ["Bilingual in Spanish",
      "Strong English skills"],
    Skills: ["Fluent in both spoken and written Spanish",
      "Proficient in reading, writing, and speaking English",
      "Technology-savvy",
      "Organizational skills",
      "Dependable",
      "Efficient",
      "Excellent communication"],
    AdditionalDetails: ["The position requires a flexible schedule, with availability to work between 24 to 30 hours per week from Monday to Friday. There may be occasions when additional hours are needed, but the minimum weekly commitment will not be less than 24 hours."]
  },
  {
    JobTitle: "Sales Development Representative",
    id: 15,
    status: "hired",
    hourSalary: "$7-13",
    JobType: "Full-Time",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/15",
    form: "https://forms.monday.com/forms/embed/9daaa34f64ac8143b95885189b65dc91?r=use1",
    JobDescription: "We are currently seeking a motivated and results-driven Sales Development Representative to join our team. As a Sales Development Representative, you will play a crucial role in driving business growth by generating new leads and setting appointments for our account team. This position requires strong communication skills, a proactive mindset, and the ability to thrive in a fast-paced, target-oriented environment.",
    CompanyValues: [],
    WorkSchedule: [],
    Tasks: ["Conduct 80-100 outbound business to business (B2B) calls daily to prospective clients.",
      "Identify and prospect potential accounts to generate appointments for the account team.",
      "Maintain accurate and up-to-date records using Microsoft Dynamics 365 CRM.",
      "Utilize Salesforce and other prospecting and marketing automation tools effectively.",
      "Leverage additional communication channels such as LinkedIn and email to secure meetings with key decision makers within target accounts.",
      "Provide excellent service and support to business partners, clients, and team members."],
    ExperienceAndQualifications: ["Minimum of two years of experience in an inside sales/tele environment.",
      "Demonstrated track record of meeting or exceeding sales quotas.",
      "Excellent written and verbal communication skills.",
      "Strong time management and organizational skills.",
      "Background in telecommunications is a plus."],
    PreferredSkillsAndTools: ["Proficiency in Microsoft Dynamics CRM and Salesforce is preferred but not required."],
    LanguageRequirements: ["B1 (Intermediate) level of English proficiency."],
    Skills: [],
    AdditionalDetails: ["Competitive salary based on experience and qualifications.",
      "Comprehensive training and ongoing professional development opportunities.",
      "Collaborative and supportive work environment.",
      "Opportunity for career growth and advancement within the company.",
    ]
  },
  {
    JobTitle: "Senior Sales Representative",
    id: 16,
    status: "declined",
    hourSalary: "$15 - $35",
    JobType: "Full-Time",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/16",
    form: "https://forms.monday.com/forms/embed/773cd54740c573f86dc7ecb8e87e9e07?r=use1",
    JobDescription: "We are seeking a highly motivated and experienced Senior Sales Representative to join our team. As a Senior Sales Representative, you will be responsible for driving revenue growth by reaching out to potential customers, setting up meetings, and closing deals. The ideal candidate will have a background in consulting or professional service selling and possess excellent communication and negotiation skills. This is a full-time position with a flexible salary range.",
    CompanyValues: [],
    WorkSchedule: ["Maintain online records and documents - 60%",
    "Reconcile spending and books - 10%",
    "Process payment for vendors and manage accounts receivable and payables - 10%",
    "Document reports for shareholders and the board - 10%",
    "Assist with year-end financial audit - 10%"
  ],
    Tasks: ["Conduct outreach activities through email and phone to engage potential customers from your own pipeline and leads provided",
      "Set up meetings between potential customers and the sales team to discuss their needs and present solutions",
      "Maintain and update the Customer Relationship Management (CRM) system with accurate and up-to-date information",
      "Guide customers through the sales process, addressing any questions or concerns they may have",
      "Meet or exceed sales targets and quotas",
      "Collaborate with internal teams to ensure customer satisfaction and timely delivery of products/services"],
    ExperienceAndQualifications: ["Proven experience in consulting or professional service selling",
      "Strong sales and negotiation skills with a track record of achieving or exceeding sales targets",
      "Excellent verbal and written communication skills in English (native-level proficiency)",
      "Self-motivated and results-oriented with the ability to work independently and as part of a team",
      "Willingness to travel for client meetings and to collaborate with the sales team"],
    PreferredSkillsAndTools: ["Proficiency in CRM software",
      "Experience with lead generation and management",
      "Familiarity with the industry and market trends",
      "Strong organizational and time management skills"],
    LanguageRequirements: ["English (native-level proficiency)"],
    Skills: ["Excellent communication and negotiation skills",
      "Self-motivation and results-oriented mindset",
      "Ability to work independently and collaborate with internal teams",
      "Strong organizational and time management skills"],
    AdditionalDetails: ["The candidate should bring their own pipeline of potential customers to engage with.",
      "The candidate should be willing to travel for client meetings or team collaboration."]
  },
  {
    JobTitle: "Market Operations Manager(Junior position)",
    id: 17,
    status: "declined",
    hourSalary: "$6 - $19",
    JobType: "Full-Time",
    imgSrc: JobImage3,
    url: "/jobs/jobDescription/17",
    form: "https://forms.monday.com/forms/embed/3b3690e7a2401ac922d6adda3633d6f7?r=use1",
    JobDescription: "Our client is a leading AI gifting solution for business ROI. We are currently seeking a highly motivated and talented Market Operations Manager to join our team. In this role, you will be responsible for driving the growth of our gifting platform by managing operational and strategic initiatives in areas such as ABM, Sales, Customer Success, and Employee Rewards. Your ability to streamline processes, plan partnerships, and effectively manage resources will be crucial to the success of our business.",
    CompanyValues: [],
    WorkSchedule: [],
    Tasks: ["Oversee and manage day-to-day operations related to gifting services, focusing on O2O and B2B services.",
      "Host events and presentations to promote our platform and build strategic partnerships.",
      "Utilize your expertise in Excel and numbers to analyze data and make informed business decisions.",
      "Develop and deliver compelling pitches to potential clients and partners.",
      "Assist in recruiting and building a high-performing team.",
      "Demonstrate a strong sense of dealing with data and numbers, ensuring accuracy and efficiency in operations.",
      "Collaborate with cross-functional teams, including developers, to improve software functionality and user experience.",
      "Contribute to the continuous improvement of our operations, sharing knowledge and learning from others.",
      "Ensure exceptional integrity in delivering the best possible customer experiences aligned with Client's vision.",
      "Drive the development and implementation of web-based and data-driven applications."],
    ExperienceAndQualifications: ["Proven experience in operations and management, with a track record of successfully executing projects.",
      "Familiarity with O2O and B2B services, demonstrating an understanding of market dynamics and customer needs.",
      "Strong organizational and event hosting skills.",
      "Proficiency in Excel and data analysis.",
      "Excellent presentation and pitching abilities.",
      "Experience in recruiting and building effective teams.",
      "Ability to work with large volumes of data and draw actionable insights."],
    PreferredSkillsAndTools: ["Proficiency in English and Chinese.",
      "Bachelor's degree in a related field or equivalent work experience.",
      "Prior success working in high-performance teams.",
      "Experience delivering solutions in large-scale enterprise environments.",
      "Prior consulting or professional services experience.",
      "Motivated to continually improve skills and contribute to a learning culture.",
      "Experience with web-based and data-driven applications."],
    LanguageRequirements: [],
    Skills: [],
    AdditionalDetails: ["We are seeking candidates who are passionate about our vision and eager to contribute to our fast-growing startup environment. We value professionalism, but also prioritize alignment with our team's vision and culture. As part of our hiring process, applicants will be required to take a short test to assess their suitability for the role.",
      "Our client believes in hard work, camaraderie, and continuous improvement. Their team works diligently to achieve our goals, and we offer a flexible work schedule and great benefits to reward their efforts. If you thrive in a challenging, dynamic environment and enjoy working with a talented team, we encourage you to apply.",
      "A day with our client will involve attending a standup meeting, updating on previous accomplishments, setting goals for the day, and collaborating with colleagues on business cooperation, partner acquisition, and achieving growth/sales targets."]
  },
  {
    JobTitle: "Business Development Representative",
    id: 18,
    status: "hired",
    hourSalary: "$7- $16",
    JobType: "Full-Time",
    imgSrc: JobImage2,
    url: "/jobs/jobDescription/18",
    form: "https://forms.monday.com/forms/embed/cd2dbcd05f44c22a009069c544cb678f?r=use1",
    JobDescription: "We are currently seeking a motivated and results-driven Business Development Representative (BDR) to join our team. As a BDR, your primary responsibility will be to qualify leads, identify decision-makers, and conduct cold calls to generate new business opportunities. You will play a crucial role in our sales process by identifying potential customers and connecting them with our Sales Manager, Steve. This is an excellent opportunity for someone with business development experience and a passion for driving growth.",
    CompanyValues: [],
    WorkSchedule: [],
    Tasks: ["Qualify leads and identify decision-makers within target organizations",
      "Conduct cold calls to engage prospects and introduce our products/services",
      "Gather and document relevant information during the qualification process",
      "Maintain accurate and up-to-date records of all interactions and sales activities",
      "Collaborate with the Sales Manager to hand off qualified leads",
      "Meet or exceed assigned targets and quotas"],
    ExperienceAndQualifications: ["Previous experience in business development or a related field",
      "Strong communication and interpersonal skills",
      "Excellent cold calling and prospecting abilities",
      "Ability to identify decision-makers and understand their needs",
      "Proven track record of meeting or exceeding sales targets"],
    PreferredSkillsAndTools: ["Experience in the construction industry",
      "Familiarity with customer relationship management (CRM) tools, preferably HubSpot",
      "Proficiency in using Seamless for lead management",
      "Bilingualism (Native Spanish) is a bonus"],
    LanguageRequirements: ["Fluency in English is essential, as the role involves communicating with English-speaking prospects. Native English proficiency is preferred."],
    Skills: ["Strong problem-solving and decision-making skills",
      "Organizational skills to maintain accurate records and prioritize tasks"],
    AdditionalDetails: ["Direct and assertive communication style",
      "Coachable and open to feedback",
      "Flexibility to adapt and work outside the job description",
      "Comfortable working independently and as part of a team",
      "Self-motivated and results-oriented"]
  },
  {
    JobTitle: "Marketing Agent",
    id: 19,
    status: "hired",
    hourSalary: "$5 - $ 9",
    JobType: "Full-Time",
    imgSrc: JobImage5,
    url: "/jobs/jobDescription/19",
    form: "https://forms.monday.com/forms/embed/d9277576d6441374851abedee95ed64e?r=use1",
    JobDescription: "We are seeking a dedicated and self-motivated Marketing Agent to join our team. As a Marketing Agent, you will be responsible for high volume outbound calling to generate leads and promote our products/services. This is a full-time position with a Monday to Friday schedule from 9 am to 6 pm PST.",
    CompanyValues: [],
    WorkSchedule: [],
    Tasks: ["Conduct high volume outbound calls to potential customers",
      "Engage in effective communication to generate leads and promote our products/services",
      "Follow provided scripts and guidelines to deliver persuasive sales pitches",
      "Maintain accurate and up-to-date records of all interactions and outcomes",
      "Demonstrate strong product knowledge and address customer inquiries",
      "Meet daily, weekly, and monthly call volume targets"],
    ExperienceAndQualifications: ["Previous experience in a call center or sales role is required",
      "Proficiency in outbound calling techniques and strategies",
      "Excellent verbal communication and interpersonal skills",
      "Ability to work independently and handle back-to-back calls with minimal supervision",
      "Strong problem-solving skills and the ability to adapt to various situations",
      "Proficient in using Ring Central (preferred but not mandatory), Excel, and Google Sheets",
      "Reliable backup plans for power outages, internet or equipment issues are mandatory",
      "Minimum English proficiency level of C1 (Advanced)"],
    PreferredSkillsAndTools: [],
    LanguageRequirements: ["Minimum English proficiency level of C1 (Advanced)"],
    Skills: ["Excellent verbal communication and interpersonal skills",
      "Strong problem-solving skills and the ability to adapt to various situations"],
    AdditionalDetails: ["This is a full-time position with a Monday to Friday schedule from 9 am to 6 pm PST."]
  },
  {
    JobTitle: "Inbound Sales Representative",
    id: 20,
    status: "declined",
    hourSalary: "$10-$20",
    JobType: "Full Time",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/20",
    form: "https://forms.monday.com/forms/embed/fed211e757d59086d724667098eba7dd?r=use1",
    JobDescription: "The Inbound Sales Representative position requires individuals with a minimum of 5 years of sales experience in hospitality, sales, call center, server, or marketing. As an Inbound Sales Representative, you will be responsible for handling incoming sales inquiries and converting leads into customers. The role requires excellent communication skills, both verbal and written, along with the ability to listen attentively to customer needs and overcome objections. Proficiency in Microsoft Office and CRM management is essential, and a strong client focus is necessary to provide exceptional customer service. The position offers the opportunity for remote work from home, with a full-time schedule from Sunday to Thursday (9am-5:30pm EST). Weekend availability is also required as needed",
    CompanyValues: [],
    WorkSchedule: [],
    Tasks: ["handling incoming sales inquiries and converting leads into customers"],
    ExperienceAndQualifications: ["Proven B2C sales experience",
     "Minimum of 5 years of sales experience in hospitality, sales, call center, server, or marketing.",
     "Excellent communication skills, both verbal and written",
     "Ability to listen attentively to customer needs and overcome objections.",
     "Proficiency in Microsoft Office and CRM management is essential",
     "Strong client focus is necessary to provide exceptional customer service"],
    PreferredSkillsAndTools: [],
    LanguageRequirements: [],
    Skills: ["Excellent communication skills (verbal and written)",
    "Ability to listen attentively to customer needs",
    "Strong client focus",
    "Proficiency in Microsoft Office and CRM management"],
    AdditionalDetails: [
    "Based on the East Coast",
    "You will need a dedicated and quiet office/workspace",
    "Application process includes an audio interview",
    "Role is subject to reference and criminal background checks",]
  },
  {
    JobTitle: "Financial Analyst",
    id: 21,
    status: "hired",
    hourSalary: "$4-10",
    JobType: "Full-Time",
    imgSrc: JobImage4,
    url: "/jobs/jobDescription/21",
    form: "https://forms.monday.com/forms/embed/261e5c63973cfeadd6299407b770f455?r=use1",
    JobDescription: "We are seeking a highly organized and detail-oriented Financial Analyst to join our team. As a Financial Analyst, you will play a crucial role in maintaining financial records, reconciling spending, processing payments, and providing accurate reports to shareholders and the board. The ideal candidate will have a strong background in QuickBooks and possess excellent analytical skills. Proficiency in Google Workspace is preferred. The position requires a high level of attention to detail and effective communication skills.",
    CompanyValues: [],
    WorkSchedule: [
      "Maintain online records and documents - 60%",
      "Reconcile spending and books - 10%",
      "Process payment for vendors and manage accounts receivable and payables - 10%",
      "Document reports for shareholders and the board - 10%",
      "Assist with year-end financial audit - 10%"
    ],
    Tasks: ["Maintain online records and documents",
    "Reconcile spending and books",
    "Process payment for vendors and manage accounts receivable and payables",
    "Document reports for shareholders and the board",
    "Assist with year-end financial audit"],
    ExperienceAndQualifications: ["Strong proficiency in QuickBooks, with experience in managing accounts receivable and payables"],
    PreferredSkillsAndTools: ["Familiarity with Google Workspace"],
    LanguageRequirements: ["C2 level of English proficiency (Fully Bilingual)"],
    Skills: ["Strong attention to detail",
    "Excellent organizational and time management skills",
    "Strong analytical and problem-solving abilities",
    "Effective written and verbal communication skills"],
    AdditionalDetails: ["The successful candidate should possess strong attention to detail, as accuracy is vital in this role.",
    "The position needs to be filled by June 15th, 3 pm EST, and interviews can be scheduled between 3-5 pm EST."]
  },
  {
    JobTitle: "Director of Growth",
    id: 27,
    status: "declined",
    hourSalary: "$16 - 24",
    JobType: "Full-Time",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/27",
    form: "https://forms.monday.com/forms/embed/96b2228b3d854f0bf845682063bb93f1?r=use1",
    JobDescription: "We are seeking a Director of Growth to join our team. The selected candidate will be responsible for developing and implementing marketing strategies to increase brand awareness and drive sales. This is a remote, full-time position with flexible work hours during PST business hours.",
    CompanyValues: [],
    WorkSchedule: ["SM - creating content or reaching out to influencers - 30% - 40%",
    "Reaching out to Institutions - 5%",
    "Getting testimonials - 10%",
    "General Marketing/Promotion - 10$",
    "Miscellaneous activities - Remaining time"],
    Tasks: ["Develop and execute social media marketing strategies to promote the brand and drive engagement",
    "Create and manage content for social media platforms including Instagram and TikTok",
    "Reach out to social media influencers to set up promotions and collaborations",
    "Work on creative ways to promote the business and increase brand awareness",
    "Identify and reach out to institutions to secure institutional subscriptions",
    "Conduct zoom calls with users to get testimonials and feedback"
    ],
    ExperienceAndQualifications: ["Experience in growing a TikTok or Instagram account to an audience of greater than 10k without having fake followers",
    "Ability to create cohesive aesthetics on social media posts and understanding of branding",
    "Proficiency in using Canva, Metricool or a similar social media planner app, Instagram & TikTok, Notion",
    "Excellent written and verbal communication skills in English at a C2 level (Native)",
    "Creative, self-motivated and able to work in a remote team environment"],
    PreferredSkillsAndTools: [],
    LanguageRequirements: ["Excellent written and verbal communication skills in English at a C2 level (Native)"],
    Skills: [],
    AdditionalDetails: ["The ideal candidate should be someone who understands the \"young audience\" and their social media habits. There is a possibility of this being split into two roles if necessary."]
  },
  {
    JobTitle: "Construction Estimator",
    id: 36,
    status: "closed",
    hourSalary: "$7- $9.5",
    JobType: "Part - Time",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/36",
    form: "https://forms.monday.com/forms/embed/54f4fb7fa34474d11a4212fb1fe926c3?r=use1",
    JobDescription: "Analyze blueprints, specifications, proposals, and other documentation to prepare time, cost, materials, and labor estimates. Prepare estimates used by management for purposes such as planning, organizing, and scheduling work. Prepare estimates for use in selecting vendors or subcontractors. Compile cost data from suppliers to determine a reasonable estimate of material costs.",
    CompanyValues: [],
    WorkSchedule: ["Analyzing documentation and preparing estimates - 95% ",
  "Preparing estimates for management - 1.65%",
  "Preparing estimates for subcontractors/vendors - 1.65%",
  "Compiling cost data from suppliers - 1.65%"
],
    Tasks: [],
    ExperienceAndQualifications: ["Planswift software proficiency",
    "Construction estimating experience of at least 2 years",
    "Fluent in English (C1/Advanced)"],
    PreferredSkillsAndTools: ["Finishing trade estimating experience"],
    LanguageRequirements: [],
    Skills: ["Ability to read and understand blueprints and construction documents",
    "Knowledge of construction materials and costs",
    "Strong analytical and math skills",
    "Strong communication skills",
    "Ability to work under tight deadlines",
    "Strong attention to detail"],
    AdditionalDetails: ["Personality: Methodical and task-oriented",
    "Availability for a semi-flexible work schedule"]
},
{
  JobTitle: "System Engineer",
  id: 31,
  status: "closed",
  hourSalary: "$25 - 29",
  JobType: "Full-Time",
  imgSrc: JobImage5,
  url: "/jobs/jobDescription/31",
  form: "https://forms.monday.com/forms/embed/e167552c65f43cb3ecc477510d7f4207?r=use1",
  JobDescription: "We are on the hunt for an experienced Systems Engineer! If you love problem-solving, automating workflows, data analysis, and designing business solutions, then we need you on our team! We believe in investing in our own brand, growing organically, and helping our clients do the same. In this role, you will be directly reporting to our CTO and playing a vital role in the stability and performance of our digital processes. With over 1000 automations running daily, your expertise will ensure seamless operations across the organization.",
  CompanyValues: ["Ownership", "Clear Communication", "Team Results Over Self"],
  WorkSchedule: [],
  Tasks: [],
  ExperienceAndQualifications: ["2+ years of experience building automated workflows.",
  "2+ years of experience building and using Google Sheets for data warehousing.",
  "1+ year of experience configuring/automating/managing CRMs.",
  "Analytical or business-focused degree."],
  PreferredSkillsAndTools: [],
  LanguageRequirements: [],
  Skills: ["Problem-solving",
  "Workflow automation",
  "Data analysis",
  "Business solution design"],
  AdditionalDetails: ["Our mission is to free contractors from lead generation companies.",
  "A 27-strong team of dedicated professionals.",
  "An opportunity to grow with a company that has 5x its size in the last 12 months.",
  "Remote work opportunities."]
},
{
  JobTitle: "Campaign Manager",
  id: 32,
  status: "closed",
  hourSalary: "$15 - 20",
  JobType: "Full Time",
  imgSrc: JobImage4,
  url: "/jobs/jobDescription/32",
  form: "https://forms.monday.com/forms/embed/87e1f1f945acb9674c393a146e928309?r=use1",
  JobDescription: "As a Campaign Manager, you will be responsible for driving successful outcomes for our clients as they work toward their business goals using our products and services. Your main focus will be on client retention and expansion, ensuring that clients have a positive experience and achieve their desired results. You will work closely with both internal teams and clients to launch and manage client services, provide support, and drive campaign performance. This role offers the opportunity for growth and advancement within our expanding team.",
  CompanyValues: [],
  WorkSchedule: [],
  Tasks: ["Meet or exceed client retention goals",
  "Reduce assigned client average Time-To-Value (TTV)",
  "Effectively onboard new clients, ensuring successful campaign launch in the first 30 days",
  "Achieve at least one review, testimonial, or referral for 25% of assigned clients",
  "Increase expansion/up-sell opportunities and conversions by identifying key opportunities to provide value"],
  ExperienceAndQualifications: ["2+ years in an Account Management role",
  "Digital Marketing Agency experience preferred",
  "Experience working with professional contractor industry preferred",
  "Experience managing 40-50+ low to high-touch clients",
  "Proven ability to build and maintain outcome-based relationships with a variety of customer cohorts",
  "Experience relaying complicated concepts to non-technical people, while maintaining ability to collaborate with highly-technical people",
  "Track record of exceeding portfolio goals for retention and expansion",
  "Strong presentation and communication skills",
  "Demonstrated knowledge of marketing/sales pipelines and/or customer journey",
  "Able to work US Business Hours (9am-6pm Eastern)"],
  PreferredSkillsAndTools: [],
  LanguageRequirements: [],
  Skills: ["Competitive Greatness",
  "Professionalism",
  "Engaging",
  "Empathy",
  "Thinks and Acts Strategically",
  "Communication Ownership",
  "Time Management",
  "Player-Coach Mindset",
  "Remote Tech Savvy"],
  AdditionalDetails: ["This role is compensated with a base salary, in addition to performance-based bonuses that are attainable and lucrative.",
  "The job description is not all-inclusive, and certain activities, duties, or responsibilities may be required as needed."]
},
{
  JobTitle: "Appointment Setter",
  id: 34,
  status: "closed",
  hourSalary: "$4 - $8",
  JobType: "Full time",
  imgSrc: JobImage2,
  url: "/jobs/jobDescription/34",
  form: "https://forms.monday.com/forms/embed/400198cbf7b5516df51093df86abc61e?r=use1",
  JobDescription: "We are seeking a highly motivated and results-driven Appointment Setter to join our team. As an Appointment Setter, you will be responsible for making outbound calls using our predictive dialer system to set appointments for our sales team. The ideal candidate should possess excellent communication skills, a persuasive demeanor, and a strong work ethic. This is a great opportunity to contribute to a growing company and potentially advance to higher-level positions with commissions.",
  CompanyValues: [],
  WorkSchedule: ["Making outbound calls"],
  Tasks: ["Engage potential customers in meaningful conversations to promote our products/services and generate interest",
  "Effectively communicate product/service features and benefits to potential customers",
  "Qualify leads by gathering necessary information and determining potential interest",
  "Schedule appointments for the sales team based on customers' availability and preferences",
  "Maintain accurate and up-to-date records of calls, appointments, and customer interactions",
  "Collaborate with the sales team to ensure a seamless transition of qualified leads"],
  ExperienceAndQualifications: ["Proven experience in appointment setting or cold calling (experience with a predictive dialer is preferred but not required)",
  "Strong verbal communication skills with the ability to engage and build rapport with potential customers",
  "Persistent and self-motivated with a strong desire to achieve targets and quotas",
  "Ability to adapt to different customer personalities and objections",
  "Excellent organizational skills and attention to detail",
  "Proficient in using basic computer applications and CRM systems",
  ],
  PreferredSkillsAndTools: [],
  LanguageRequirements: ["Minimum C1 (Advanced) level of English proficiency"],
  Skills: [],
  AdditionalDetails: ["Emphasize that the candidate should possess a driven, go-getter, self-starter mentality with an alpha personality",
  "Highlight the potential for growth within the company",
  "Specify that the position is available for immediate hiring"]
},
{
  JobTitle: "Sales Representative",
  id: 40,
  status: "closed",
  hourSalary: "$16 - 20",
  JobType: "Full time",
  imgSrc: JobIMage19,
  url: "/jobs/jobDescription/40",
  form: "https://forms.monday.com/forms/embed/3c7a30b6ef5e8ee24c2a109b3c5dda5e?r=use1",
  JobDescription: "We are seeking a motivated and adaptable Sales Representative to join our team. As a Sales Representative, you will play a crucial role in driving sales and generating leads for our organization. This is a dynamic position that requires flexibility, technical acumen, and excellent communication skills.",
  CompanyValues: ["Intelligence", "Flexibility", "Dedication", "Punctuality", "Accountability"],
  WorkSchedule: ["Inbound Calls 70%"],
  Tasks: ["Handle inbound calls from customers, addressing their inquiries and providing exceptional customer service.", "Utilize your technical background and ability to communicate using technical language to effectively engage with customers.", "Generate leads during slow periods to boost sales opportunities.", "Demonstrate strong analytical abilities to identify and solve customer problems.", "Show a high level of emotional intelligence (EQ) in understanding customer needs and building rapport."],
  ExperienceAndQualifications: ["No specific experience or qualifications required; however, relevant sales experience will be an advantage."],
  PreferredSkillsAndTools: ["Fluency in English at a C2 (Fully Bilingual) level to ensure effective communication with customers.", "Proficiency in Salesforce, Appolo.ai, LinkedIn Sales Nav, and Dialers to manage sales processes efficiently."],
  LanguageRequirements: ["Fluency in English at a C2 (Fully Bilingual) level"],
  Skills: ["Strong analytical abilities", "Excellent communication skills", "Emotional Intelligence (EQ)"],
  AdditionalDetails: ["The company culture is akin to that of a startup, offering a dynamic and fast-paced work environment.", "Flexibility and dedication are essential, as some periods may require longer work hours to meet business demands.", "The remote position has a strict Paid Time Off (PTO) policy, providing 5 days of PTO.", "Punctuality and accountability are emphasized, and the candidate must be comfortable with writing End of Day (EOD) reports.",
  "There is a commission structure in place for inside sales, where you can earn 5% commission on successful sales."]
},
{
  JobTitle: "Sales Representative",
  id: 41,
  status: "closed",
  hourSalary: "$16",
  JobType: "Full time",
  imgSrc: JobIMage19,
  url: "/jobs/jobDescription/41",
  form: "https://forms.monday.com/forms/embed/11829bd485931a2291ca587d2c280cdb?r=use1",
  JobDescription: "We are seeking a motivated and skilled Project Manager to join our team. As a Project Manager, you will play a crucial role in overseeing and coordinating robotics deliveries and ensuring successful project execution. Your technical expertise, strong organizational skills, and ability to communicate effectively will be key to excel in this role.",
  CompanyValues: ["Intelligence", "Flexibility", "Dedication", "Punctuality", "Accountability"],
  WorkSchedule: ["Estimating Pricing for Robotics Deliveries - 20%",
  "Collaborating with the Sales Team - 15%",
  "Scheduling and Deadlines - 15%",
  "Customer Updates - 10%",
  "Creating Project Quotes - 15%",
  "Logistics Coordination - 10%",
  "CRM Management - 10%" ],
  Tasks: ["Estimate pricing for robotics deliveries",
  "Collaborate with the sales team",
  "Efficiently schedule project tasks and enforce deadlines",
  "Provide customer updates on project status",
  "Develop detailed project quotes",
  "Oversee and coordinate logistics of project deliveries",
  "Utilize the CRM system to track and manage project details",
  "Effectively communicate project status to the internal team"],
  ExperienceAndQualifications: ["Technical Background: Previous experience or knowledge in technical fields, engineering, or programming is highly beneficial.",
  "Mandarin Language Skills: Proficiency in speaking or reading Mandarin is highly preferred, as it will facilitate communication with stakeholders."],
  PreferredSkillsAndTools: ["Salesforce Proficiency: The ideal candidate should be proficient in using Salesforce or similar CRM tools to manage project-related data and workflows effectively."],
  LanguageRequirements: ["English Level: C1 (Advanced)",
  "Mandarin: Preferred"],
  Skills: ["Technical expertise",
  "Strong organizational skills",
  "Effective communication",
  "Salesforce proficiency (desired)"],
  AdditionalDetails: ["Work Schedule: The selected candidate is expected to work from 9 AM to 5 PM.",
   "Long-term Hire: The company is looking for a candidate willing to commit to the role for at least 2 to 3 years.",]
},
{
  JobTitle: "Paralegal",
  //ponnocklaw
  id: 42,
  status: "declined",
  hourSalary:"$6,5 - $16",
  JobType: "Full-Time EST",
  imgSrc: JobIMage19,
  url: "/jobs/jobDescription/42",
  form: "https://forms.monday.com/forms/embed/c94513b6ad6b3885643358fd1d9bed70?r=use1",
  JobDescription: ["We are a well-established legal firm seeking a dedicated and detail-oriented Paralegal to join our team. With a strong emphasis on probate cases, particularly in the Florida jurisdiction, we offer an opportunity for growth and professional development within a dynamic legal environment.\n\nPosition Overview:\nThe selected candidate will play a crucial role in assisting attorneys with a wide range of tasks, from document review and analysis to data entry and case management. The Paralegal will contribute to the seamless progression of cases, ensuring that every aspect is handled efficiently and effectively."],
  Tasks: [
  "Thoroughly review incoming documents, analyzing their contents in accordance with legal protocols and standards.",
  "Accurately input relevant data and information into our systems, maintaining the integrity and organization of case-related details.",
  "Manage electronic filing systems, ensuring timely and accurate submission of documents to various e-portals as required by the legal process.",
  "Take ownership of cases from inception to conclusion, collaborating closely with attorneys to ensure smooth progression and resolution."
  ],
  ExperienceAndQualifications: [
  "Solid experience in probate cases, ideally with a focus on Florida jurisdiction.",
  "A strong understanding of legal processes and terminology.",
  "Attorney Background: A background as an attorney would be advantageous, though not mandatory."
  ],
  LanguageRequirements: ["Minimum English Level: C1 (Advanced)"],
  Skills: [
  "Proficient in Microsoft Office Suite.",
  "Familiarity with Cleo software.",
  "Exceptional attention to detail.",
  "Strong organizational and time-management skills.",
  "Excellent communication and teamwork abilities."
  ],
  AdditionalDetails: [
  "In the previous year, our firm successfully managed 650 cases, highlighting the active nature of the role and the need for efficiency.",
  "The role requires a high level of detail orientation to ensure accuracy in all tasks.",
  ]
  },
{
  JobTitle: "Medical Manager",
  //mokaram lawfirm
  id: 43,
  status: "hired",
  hourSalary: "$8 - 14",
  JobType: "Full Time CST",
  imgSrc: JobIMage18,
  url: "/jobs/jobDescription/43",
  form: "https://forms.monday.com/forms/embed/521e1b6405ce6bd97f37a9aed9a57a11?r=use1",
  JobDescription: ["We are seeking a dedicated and experienced Medical Manager to join our team. As a Medical Manager, you will play a crucial role in facilitating communication between clients, medical providers, and the law firm. Your organizational skills, empathetic communication, and strong attention to detail will contribute to the smooth operation of our processes."],
  Tasks: ["Ensure timely and accurate transmission of client information to medical providers.",
  "Facilitate the flow of information regarding appointments from medical providers back to the law firm.",
  "Confirm client attendance, occasionally communicating directly with clients.",
  "Maintain meticulous records to ensure accurate tracking and documentation."],
  ExperienceAndQualifications: ["Previous experience working in a medical office or a similar role within a law firm.",
  "Strong interpersonal and communication skills with an empathetic and friendly demeanor.",
  "Proficiency in intermediate Excel for data tracking and management.",
  "Fully bilingual with a minimum English level of C2.",
  "Excellent organizational skills to manage multiple tasks and responsibilities effectively."],
  Skills: ["Strong interpersonal and communication skills with an empathetic and friendly demeanor.",
  "Proficiency in intermediate Excel for data tracking and management.",
  "Excellent organizational skills to manage multiple tasks and responsibilities effectively.",
  "Familiarity with personal injury law firm operations.",
  "Experience with medical billing or records management."],
  PreferredSkillsAndTools: [ "Familiarity with personal injury law firm operations.",
  "Experience with medical billing or records management."],
  LanguageRequirements: ["Fully bilingual with a minimum English level of C2."],
  AdditionalDetails: ["This role requires a dynamic individual with strong organizational abilities, empathetic communication skills, and the ability to maintain accurate records.",
  "The candidate should exhibit a personality characterized by strong communication, an upbeat attitude, and empathy.",
  "The successful candidate will be a key link between clients, medical providers, and the law firm, contributing to the overall success of our operations.",
  "Familiarity with personal injury law firm operations as a preferred qualification.",
  "Deadline-driven nature of the role and the need for accuracy in data tracking and communication."]
},
{
  JobTitle: "Sales Development Representative",
  //madisonwells
  id: 44,
  status: "closed",
  hourSalary: "$13-17",
  JobType: "Part Time/Full time CST",
  imgSrc: JobIMage17,
  url: "/jobs/jobDescription/44",
  form: "https://forms.monday.com/forms/embed/ef09df5553d08bf79bc8b13fdc1a8db3?r=use1",
  JobDescription: ["We understand that the key to growth is a high-performing sales team. Thats why we are seeking a qualified Sales Development Representative (SDR) to find and screen potential clients who could benefit from our bespoke executive search services in research insights and analytics. As the first line of communication with a prospect, the ideal SDR has a strong understanding of the sales process and excels at researching leads, initiating new relationships, and setting up sales closers for success. The SDR should be a quick learner who possesses strong communication skills and the ability to present our offerings in a compelling manner. Every potential client lead presents an opportunity for boosting top-line revenue growth, customer acquisition levels, and profitability."],
  Tasks: [ "Utilize ZoomInfo, Loom, cold calls, and email to create new sales opportunities.",
  "Recognize the needs of prospects and recommend appropriate executive recruitment services.",
  "Cultivate enduring, trusting relationships with prospects to qualify leads as potential sales opportunities.",
  "Actively seek new business prospects in the market.",
  "Arrange meetings or calls between (prospective) customers and the Managing Director.",
  "Provide weekly, monthly, and quarterly results to the manager."],
  ExperienceAndQualifications: [
  "Two or more years of experience in sales, demonstrating a track record of surpassing lead targets.",
  "Proficient communication skills via phone and email.",
  "Demonstrated, innovative problem-solving approach and robust analytical skills."],
  PreferredSkillsAndTools: [ "Bachelor's degree (or equivalent) or a minimum of five years of pertinent work experience.",
  "Experience as a Sales Development Representative, boasting a history of meeting sales quotas.",
  "Proficiency with Excel, Sharepoint, LinkedIn, Loom, and CRMs."],
  LanguageRequirements: ["Native English speaker"],
  Skills: ["Proficient communication skills via phone and email.",
  "Demonstrated, innovative problem-solving approach and robust analytical skills.",
  "Experience as a Sales Development Representative, boasting a history of meeting sales quotas."],
},
{
  JobTitle: "Sales Representative",
  //RichtechRobotics
  id: 45,
  status: "closed",
  hourSalary: "$12 - $20",
  JobType: "Full Time",
  imgSrc: JobIMage16,
  url: "/jobs/jobDescription/45",
  form: "https://forms.monday.com/forms/embed/3c7a30b6ef5e8ee24c2a109b3c5dda5e?r=use1",
  JobDescription: "We are seeking a motivated and adaptable Sales Representative to join our team. As a Sales Representative, you will play a crucial role in driving sales and generating leads for our organization. This is a dynamic position that requires flexibility, technical acumen, and excellent communication skills.",
  Tasks: ["Handle inbound calls from customers, addressing their inquiries and providing exceptional customer service.",
  "Utilize your technical background and ability to communicate using technical language to effectively engage with customers.",
  "Generate leads during slow periods to boost sales opportunities.",
  "Demonstrate strong analytical abilities to identify and solve customer problems.",
  "Show a high level of emotional intelligence (EQ) in understanding customer needs and building rapport."],
  ExperienceAndQualifications: ["No specific experience or qualifications required; however, relevant sales experience will be an advantage."],
  LanguageRequirements: ["English (C2 Level)"],
  Skills: [ "Strong communication skills",
  "Technical acumen",
  "Emotional intelligence",
  "Sales experience (advantage)"],
  AdditionalDetails: ["Intelligence is highly valued for this role, as you will be dealing with technical matters and diverse customer inquiries.",
  "The company culture is akin to that of a startup, offering a dynamic and fast-paced work environment.",
  "Flexibility and dedication are essential, as some periods may require longer work hours to meet business demands.",
  "The remote position has a strict Paid Time Off (PTO) policy, providing 5 days of PTO.",
  "Punctuality and accountability are emphasized, and the candidate must be comfortable with writing End of Day (EOD) reports.",
  ]
},
{
  JobTitle: "Sales Development Representative",
  //quolum
  id: 46,
  status: "closed",
  hourSalary: "$8-12",
  JobType: "Full Time",
  imgSrc: JobIMage15,
  url: "/jobs/jobDescription/46",
  form: "https://forms.monday.com/forms/embed/ca7100bc6ebfea0a8bc0b970771e39aa?r=use1",
  JobDescription: ["We are seeking a highly motivated and results-driven Sales Development Representative to join our team. The selected candidate will play a crucial role in generating leads and driving new business opportunities. As a Sales Development Representative, you will be responsible for identifying potential clients, initiating contact, and setting up meetings for our sales team. This is a challenging and rewarding role that requires excellent communication skills, a strong work ethic, and a passion for sales in the US tech industry."],
  Tasks: ["Conduct outbound calls and emails to prospective clients to generate leads and book meetings.",
  "Research and identify potential clients using tools like LinkedIn Sales Navigator, Apollo.IO, and other resources.",
  "Articulate our value proposition to potential clients and create interest in our services.",
  "Collaborate closely with the sales team to ensure a seamless handover of qualified leads.",
  "Meet and exceed monthly targets for the number of meetings booked.",
  "Keep accurate and detailed records of interactions and outcomes in our CRM system (HubSpot).",
  "Provide valuable feedback to the sales and marketing teams to improve lead generation strategies."],
  ExperienceAndQualifications: ["2 years of experience in US tech sales, with SaaS experience considered a plus.",
  "Proven track record of meeting or exceeding sales targets.",
  "Proficiency in using sales tools such as HubSpot, Apollo.IO, and LinkedIn Sales Navigator.",
  "Excellent communication skills and a persuasive demeanor.",
  "Native English speaker with exceptional verbal and written skills."],
  PreferredSkillsAndTools: ["Emphasis on diversity: We are actively seeking to diversify our team and encourage applications from female candidates.",
  "High-energy and self-motivated individual who thrives in a fast-paced sales environment."],
  LanguageRequirements: ["Native English speaker"],
  Skills: ["Sales", "Lead Generation", "Communication", "CRM", "Sales Tools"],
  AdditionalDetails: ["Successfully book a minimum of 18 meetings per month for Additional commission: $125 per booked meeting, Additional commission: 2.12% of the contract value for closed deals",
  "Work Schedule: 8-hour workdays",
  "Application Process: To apply, please submit your resume and a cover letter detailing your relevant experience and why you are interested in this position. In your cover letter, please also address how your skills align with the responsibilities and requirements outlined in the job description."]
},
{
  JobTitle: "Bilingual Paralegal (Spanish-English)",
  //Rosenberg
  id: 47,
  status: "closed",
  hourSalary: "$9 - 16",
  JobType: "Full Time EST",
  imgSrc: JobIMage19,
  url: "/jobs/jobDescription/47",
  form: "https://forms.monday.com/forms/embed/be5b51744c4dc6004f8f3194f7c86014?r=use1",
  JobDescription: "We are seeking a dedicated, positive-minded Bilingual Paralegal to become an integral part of our dynamic legal team. In this role, you will leverage your proficiency in both Spanish and English to provide comprehensive support to our legal operations. Your proactive approach, ability to adapt, and strong communication skills will be key assets in ensuring the seamless flow of our legal processes. Join us in making a real impact on our clients and the community.",
  Tasks: [
  "Skillfully transcribe legal documents and correspondence through accurate dictation in both English and Spanish.",
  "Manage court notifications and liaise with prosecutors to facilitate adjournment requests.",
  "Engage with clients, promptly addressing inquiries, and furnishing updates on case developments.",
  "Interface with judges to procure adjournments as required.",
  "Contribute to the filing and systematic organization of legal documentation.",
  "Collaborate seamlessly with attorneys and fellow team members to uphold efficient case management."
  ],
  ExperienceAndQualifications: [
  "Fluency in both Spanish and English (bonus points for proficiency in Portuguese).",
  "Prior experience in dictation and transcription.",
  "Familiarity with organizing and filing legal documents.",
  "Proficiency in Microsoft Suite (Word, Excel, PowerPoint, Outlook)."
  ],
  LanguageRequirements: [
  "Minimum required English proficiency level: C1 (Advanced)."
  ],
  Skills: [
  "Exhibit proactive initiative and thrive while working independently.",
  "Approach tasks and problem-solving with confidence and proactiveness.",
  "Embrace challenges as opportunities for growth, unafraid to learn from mistakes.",
  "Foster a positive, enthusiastic attitude that contributes to a collaborative work environment."
  ],
  AdditionalDetails: [
  "This role necessitates adaptability and proactivity, requiring seamless integration into the team while independently shouldering responsibilities.",
  "Strong organizational skills and meticulous attention to detail are essential for effective management of legal documents and processes."
  ]
},
{
  JobTitle: "Intake Specialist",
  //Island View Law
  id: 48,
  status: "closed",
  hourSalary: "$9 - 11",
  JobType: "Full Time",
  imgSrc: JobIMage13,
  url: "/jobs/jobDescription/48",
  form: "https://forms.monday.com/forms/embed/413526c663dfe9bf69951d880a7f403c?r=use1",
  JobDescription: "Position Overview:\n\nWe are seeking a dedicated and detail-oriented Intake Specialist to join our team. As an Intake Specialist, you will play a crucial role in providing excellent client service through inbound and outbound calls. Your exceptional communication skills and sales experience will contribute to enhancing client relationships and driving our business forward.",
  Tasks: [
  "Handle inbound and outbound calls with clients, addressing their inquiries, providing information, and assisting with their needs.",
  "Utilize your sales experience to effectively communicate and engage with clients.",
  "Maintain client contact to ensure their needs are met and satisfaction is achieved.",
  "Write detailed notes and maintain accurate records of client interactions.",
  "Operate proficiently with the ZoomPhone system and Microsoft Office suite."
  ],
  ExperienceAndQualifications: [
  "Strong communication skills, both written and verbal, at a native English level.",
  "Demonstrated ability to remain calm under pressure and maintain a high level of professionalism.",
  "Detail-oriented approach to tasks and interactions.",
  "Previous experience in sales is preferred.",
  "Proficiency in using ZoomPhone and Microsoft Office tools."
  ],
  Skills: [
  "Excellent problem-solving skills and the ability to think on their feet.",
  "Passion for providing exceptional client service and fostering positive relationships.",
  "Ability to work independently while also being a collaborative team player.",
  "Flexibility to adapt to changing priorities and a dynamic work environment."
  ]
},
{
  JobTitle: "Administrative Assistant",
  //Gem Harvest
  id: 49,
  status: "closed",
  hourSalary: "$5 - 8",
  JobType: "Part Time EST",
  imgSrc: JobIMage12,
  url: "/jobs/jobDescription/49",
  form: "https://forms.monday.com/forms/embed/d9576f22db57fdd6a4547ea4080c5c6d?r=use1",
  JobDescription: "We are seeking a detail-oriented and motivated Administrative Assistant to join our team. As an Administrative Assistant, you will play a crucial role in coordinating and facilitating various tasks to ensure smooth operations. Your exceptional organizational skills, effective communication abilities, and proficiency in various software tools will be key assets in this role.",
  Tasks: [
  "Scheduling: Efficiently manage and organize the calendar by scheduling appointments, coordinating meetings, and setting up Zoom sessions. Prioritize time zones, availability, and preferences to create an optimized schedule.",
  "Candidate Coordination: Communicate with candidates via email, ensuring professional and effective correspondence. Arrange and confirm meetings, prep and debrief calls, and manage all administrative aspects related to candidate interactions.",
  "Data Entry and Reviewing Lists: Accurately enter data into spreadsheets, databases, and other systems. Review lists for accuracy and completeness, maintaining a high level of attention to detail."
  ],
  ExperienceAndQualifications: [
  "Proven experience in creating professionally formatted emails.",
  "Proficiency in scheduling tools and techniques, including Calendly and Google Calendar.",
  "Strong written and verbal communication skills with a professional demeanor.",
  "Effective time management and organizational skills.",
  "Project management experience is a plus.",
  "Ability to handle inward communication with sensitivity and professionalism.",
  "Proficiency in Google Workspace, Gmail, Excel, and Zoom."
  ],
  PreferredSkillsAndTools: [
  "Exceptional attention to detail and accuracy.",
  "Self-starter with an intuitive approach to tasks.",
  "Open and transparent communication style.",
  "Long-term commitment to the role.",
  ],
  LanguageRequirements: [
  "Bilingual proficiency (C2) in English."
  ],
},
{
  JobTitle: "Appointment Setter",
  //Gem Harvest
  id: 50,
  status: "closed",
  hourSalary: "$5 - 8",
  JobType: "Full Time EST",
  imgSrc: JobIMage11,
  url: "/jobs/jobDescription/50",
  form: "https://forms.monday.com/forms/embed/afec008f3913d620a2f4ee369fa3f086?r=use1",
  JobDescription: "We are seeking a dedicated and skilled Appointment Setter to join our team. If you have excellent communication skills, a knack for setting appointments, and a passion for connecting with people, we want you to be a part of our team.",
  WorkSchedule: [
  "The role will involve approximately 67% of time spent on making calls and 33% on administrative tasks."
  ],
  Tasks: [
  "Conduct outbound calls to potential clients, introducing our services and scheduling appointments.",
  "Take detailed notes during each call, recording essential information for future reference.",
  "Maintain accurate and up-to-date records of leads, appointments, and outcomes in our CRM system (Manatal).",
  "Follow up with potential clients via email, providing additional information and reinforcing the value of our services.",
  "Demonstrate a personable and friendly demeanor to establish rapport with clients over the phone.",
  "Showcase exceptional performance to unlock opportunities for expanding into additional administrative tasks."
  ],
  ExperienceAndQualifications: [
  "Proven experience in appointment setting, telemarketing, or sales-related roles.",
  "Proficiency in making phone calls, engaging in sales conversations, and active listening.",
  "Strong written and verbal communication skills, with a warm and engaging phone presence.",
  "Ability to connect with potential clients on a human level, building trust and understanding.",
  "Familiarity with Google Voice or similar voice systems, Gmail, and Google Sheets.",
  "Exceptional organizational skills and an intuitive approach to managing tasks.",
  "Creativity in problem-solving and adapting to various client interactions.",
  ],
  PreferredSkillsAndTools: [
  "Prior experience in sales, customer service, or client engagement.",
  ],
  LanguageRequirements: [
  "Native English speaker with a natural grasp of the language."
  ],
},
{
  JobTitle: "Virtual Assistant (Content and Operations/SEO Support)",
  //Gladiator Law Marketing
  id: 51,
  status: "hired",
  hourSalary: "$5 - 7",
  JobType: "Full Time",
  imgSrc: JobIMage19,
  url: "/jobs/jobDescription/51",
  form: "https://forms.monday.com/forms/embed/4a9f217746c755f125ea703783f7373f?r=use1",
  JobDescription: "Position Overview:\nWe are seeking a motivated and organized Virtual Assistant to join our team in a full-time role, Monday to Friday, from 9am to 5pm EST. The successful candidate will play a pivotal role in supporting our Content Department and Operations/SEO team. The role involves a diverse range of responsibilities, encompassing both ad-hoc tasks and planned daily/weekly/monthly tasks. The Virtual Assistant will contribute to maintaining seamless workflows, ensuring tasks are completed promptly and effectively.",
  WorkSchedule: ["Monday to Friday, from 9am to 5pm EST"],
  Tasks: [
  "Content Department:\n Upload approximately 200 monthly content pieces from writers to our project management system for editor review.\n- Generate monthly task lists for individual writers using our established in-house template.\n- Collaborate and adapt within the Content Department, potentially expanding responsibilities based on strengths and rapport.",
  "Operations/SEO Department:\n Input relevant keywords into our tracking software for each new page or blog post published.\n- Incorporate new page/blog URLs into our architecture documentation for streamlined reference.\n- Implement schema markup code into the backend of web pages and blogs.\n- Potential involvement in broader SEO tasks based on expertise and interest."
  ],
  ExperienceAndQualifications: [
  "Prior experience in a similar role, with provided references for verification.",
  ],
  PreferredSkillsAndTools: [
    "Previous experience in a marketing/SEO agency.\n- Proficiency in content editing within the WordPress backend.\n- Familiarity with keyword tracking methodologies"
  ],
  LanguageRequirements: [
  "Native or fluent English proficiency."
  ],
  Skills: [
  "Excellent organizational skills for effective task management across departments.",
  "Strong time management abilities to handle simultaneous tasks and consistent workflow.",
  "Proactive approach to preventing backlogs and addressing challenges promptly."
  ]
},
{
  JobTitle: "Sales Development Representative (SDR)",
  //Elay.io
  id: 52,
  status: "open",
  hourSalary: "$7 - 11",
  JobType: "Full Time",
  imgSrc: JobIMage9,
  url: "/jobs/jobDescription/52",
  form: "https://forms.monday.com/forms/embed/1d382882f5d3a76b73f034632fc5c547?r=use1",
  JobDescription: "We are seeking a driven and enthusiastic Sales Development Representative (SDR) to join our team. As an SDR, you will play a crucial role in identifying and engaging potential clients, contributing to the growth of our business. The ideal candidate will be a highly motivated individual with a knack for B2B sales, strong communication skills, and the ability to thrive in a fast-paced environment.",
  WorkSchedule: ["Monday to Friday, 9:00 AM to 5:00 PM EST."],
  Tasks: [
  "Make a minimum of 150 cold calls per day to prospective clients.",
  "Utilize HubSpot to manage and organize leads, ensuring a seamless pipeline.",
  "Engage in conversations with potential clients, understanding their needs and effectively communicating the value of our services."
  ],
  ExperienceAndQualifications: [
  "Strong critical thinking skills to engage in meaningful conversations with potential clients.",
  "Previous experience in B2B sales preferred, with 2 years of experience desired.",
  "Recruitment experience.",
  "High school diploma required; Bachelor's degree preferred.",
  "Familiarity with HubSpot is a plus, but training will be provided."
  ],
  LanguageRequirements: [
    "Native English speaker with exceptional verbal and written communication skills."
  ],
  Skills: [
  "A dynamic personality that embraces challenges and demonstrates a strong work ethic.",
  "Self-motivated individuals with a hunger for success are encouraged to apply.",
  "Quick learner who can adapt to new processes and absorb product knowledge.",
  "Ability to think on your feet and communicate effectively off-script.",
  "Resilient and capable of handling rejection gracefully."
  ],
},
{
  JobTitle: "Media Buyer",
  //RainMaker
  id: 53,
  status: "interview",
  hourSalary: "$10 - 14",
  JobType: "Full Time",
  imgSrc: JobIMage9,
  url: "/jobs/jobDescription/53",
  form: "https://forms.monday.com/forms/embed/a3e2bde7d4e8cea5092e5ea139aac78b?r=use1",
  JobDescription: "We are currently seeking a dynamic and experienced Paid Ads Manager (Media Buyer) to join our high-performing team. If you're someone who is looking for a challenging environment where your skills can truly shine and make a significant impact, then this opportunity is for you. As a Paid Ads Manager, you will be instrumental in expanding brand exposure and generating leads for our clients. This role requires an entrepreneurial spirit, strategic thinking, and a passion for driving results.",
  Tasks: [
    "Develop and execute strategic paid media campaigns to drive leads and traffic, aligning with business objectives and client goals.",
    "Collaborate with internal teams to ensure that paid advertising strategies complement broader marketing initiatives.",
    "Research, measure, and evaluate campaign performance, providing actionable insights and data-driven recommendations.",
    "Define and communicate key metrics for campaign success, including ROI, conversion rates, cost-performance, impressions, and website traffic.",
    "Maintain a strong understanding of clients' industries and business objectives to influence paid search creative development.",
    "Regularly meet with clients to present audit findings, discuss results, and provide strategic recommendations.",
    "Utilize Google Ads, Analytics, and other relevant tools for campaign management and optimization.",
    "Keep abreast of industry trends and new digital marketing strategies to enhance campaign effectiveness."
  ],
  ExperienceAndQualifications: [
    "Google Ads and Analytics Certification (Microsoft Ads/Bing certification desirable).",
    "Proficiency in Google Tag Manager, Marketing Automation Systems, and Microsoft Products.",
    "Demonstrated ability to generate results using paid social strategies.",
    "Strong analytical skills and ability to distill insights from data.",
    "General knowledge of SEO principles.",
    "Excellent verbal, written, and interpersonal communication skills.",
    "Highly organized with exceptional attention to detail and the ability to manage multiple projects and deadlines.",
    "Passion for digital marketing and a keen interest in staying current with industry trends.",
    "Bachelor's degree in Marketing, Digital Media, Web Development, or a related field.",
    "Minimum of 5 years of experience in SEM and paid media execution.",
    "Bonus consideration for prior experience within the legal industry."
  ],
  Skills: [
    "We value individuals who are not only skilled and talented but are also dedicated to continuous learning and improvement. Our team members are creative problem solvers who take ownership of their work, much like running a small business. Joining our team means becoming part of a motivated group that embraces our core mission and is committed to working hard, playing hard, and delivering exceptional results."
  ],
},
{
  JobTitle: "Real Estate Cold Calling - Appointment Setter",
  //Option 1 Residential
  id: 54,
  status: "closed",
  hourSalary: "$5 - 8.5",
  JobType: "Full Time",
  imgSrc: JobIMage8,
  url: "/jobs/jobDescription/54",
  form: "https://forms.monday.com/forms/embed/c09f49aa5db7158e6bc9b1a16f0f0422?r=use1",
  JobDescription: "We are seeking a motivated and experienced individual to join our team as a Real Estate Cold Calling Appointment Setter. In this role, you will be responsible for identifying and contacting potential clients to set up appointments for our real estate professionals. If you have a passion for sales, excellent communication skills, and a strong work ethic, we want to hear from you.",
  WorkSchedule: ["10 AM - 6 PM or 11 AM - 7 PM EST"],
  Tasks: [
  "Cold Calling, Utilize a predictive dialer to make outbound calls to potential real estate clients."
  ],
  ExperienceAndQualifications: [
  "2+ years of Cold Calling or Sales experience in the Real Estate industry",
  ],
  PreferredSkillsAndTools: [
  "Knowledge of real estate market trends\nPrevious experience with appointment setting",
  "Proficiency in relevant software and tools for cold calling"
  ],
  LanguageRequirements: [
  "C2 level of English proficiency (Fully Bilingual)"
  ],
  Skills: [
  "We are looking for candidates who are enthusiastic, possess strong sales personalities, and have excellent communication skills."
  ]
},
{
  JobTitle: "Client Satisfaction Specialist",
  //Mokaram Law Firm
  id: 55,
  status: "hired",
  hourSalary: "$5 - 7",
  JobType: "Full Time",
  imgSrc: JobIMage7,
  url: "/jobs/jobDescription/55",
  form: "https://forms.monday.com/forms/embed/c4d58609d54bdd9e75acb743a53e2e7d?r=use1",
  JobDescription: "We are seeking a dedicated Client Satisfaction Specialist to join our team. This role is crucial in ensuring our clients feel cared for and informed throughout their case process. As a Client Satisfaction Specialist, you will spend your day on the phone, checking in with clients, providing updates, and addressing their concerns. Your friendly and empathetic voice will be the cornerstone of our client communication.",
  WorkSchedule: ["8:30 AM - 5:30 PM CST"],
  Tasks: [
  "Conduct proactive client outreach via phone calls to provide updates on their case's progress.",
  "Listen to client concerns, answer their questions, and address any issues promptly and professionally.",
  "Maintain a friendly and empathetic demeanor to reassure clients, especially during extended periods of no updates or responses from the opposing side.",
  "Assist in handling call overflows during times of heavy call volume throughout our office.",
  "Utilize our legal software for case management.",
  "Proficiency in Microsoft Excel and Word for documenting client interactions and maintaining records can be helpful."
  ],
  ExperienceAndQualifications: [
  "Previous call center experience.",
  "Exceptional verbal communication skills with a kind and friendly tone.",
  "Excellent organizational skills and attention to detail.",
  "Ability to adapt to changing priorities and workload.",
  "Reliable and punctual work ethic."
  ],
  PreferredSkillsAndTools: [
  "Legal software, Microsoft Excel, and Word for documentation."
  ],
  LanguageRequirements: [
  "Fully bilingual with a minimum English level of C2."
  ],
  Skills: [
  "Maintaining a friendly and empathetic demeanor."
  ],
  AdditionalDetails: [
  "This position is essential for maintaining client satisfaction and ensuring they feel supported during the legal process.",
  "The role may require flexibility during peak call times to meet client needs effectively."
  ]
},
{
  JobTitle: "Business Development Representative / Sales Development Representative (BDR/SDR)",
  //Use Work Hero
  id: 56,
  status: "closed",
  hourSalary: "$6 - 8.5",
  JobType: "Part Time",
  imgSrc: JobIMage6,
  url: "/jobs/jobDescription/56",
  form: "https://forms.monday.com/forms/embed/139c43019c21ed6a89076dd754396de1?r=use1",
  JobDescription: "Responsibilities:\n\nFielding Inbound Booked Calls: Effectively handle incoming calls from potential clients, engaging in meaningful conversations, and guiding them through the sales process.\nGenerating Lists: Research and compile potential client lists, identifying prospects that align with the company's target market.\nSending Out Emails: Craft and send compelling emails to prospects, nurturing leads and initiating the sales cycle.\nSystem Improvement: Actively contribute to the enhancement and development of the sales system by providing insights, feedback, and tech-savvy solutions.",
  Tasks: [
  "Fielding Inbound Booked Calls: Effectively handle incoming calls from potential clients, engaging in meaningful conversations, and guiding them through the sales process.",
  "Generating Lists: Research and compile potential client lists, identifying prospects that align with the company's target market.",
  "Sending Out Emails: Craft and send compelling emails to prospects, nurturing leads and initiating the sales cycle.",
  "System Improvement: Actively contribute to the enhancement and development of the sales system by providing insights, feedback, and tech-savvy solutions."
  ],
  ExperienceAndQualifications: [
  "Strong desire to learn and gain experience.",
  "Some sales experience, demonstrating a fundamental understanding of sales processes.",
  "Tech-savvy mindset and willingness to adapt to new software and tools.",
  "Excellent critical thinking skills."
  ],
  PreferredSkillsAndTools: [
  "Knowledge of WordPress is advantageous."
  ],
  LanguageRequirements: [
    "Language Proficiency: C2 (Fully Bilingual) in English."
  ],
  Skills: [
  "Strong intellect and the ability to contribute to system improvements actively.",
  "A critical thinking mindset and an easy-going personality are highly valued."
  ]
},
{
  JobTitle: "Intake Specialist",
  //Lander Holm Law
  id: 57,
  status: "declined",
  hourSalary: "$6.5- 11",
  JobType: "Full Time",
  imgSrc: JobIMage8,
  url: "/jobs/jobDescription/57",
  form: "https://forms.monday.com/forms/embed/7d67e3139a94c145e494fc3fd60dfe13?r=use1",
  JobDescription: "We are looking for an Intake Specialist to join our team and assist with essential administrative tasks, a personable and organized individual with a knack for handling incoming inquiries and scheduling appointments. If you're looking for a dynamic role that values your customer service skills, this may be the perfect opportunity for you.",
  WorkSchedule: ["8:30 AM - 5:30 PM (Pacific Standard Time), Monday to Friday"],
  Tasks: [
  "Answering Phones, Respond promptly and professionally to incoming calls, providing excellent customer service and addressing inquiries effectively.",
  "Scheduling Appointments, Manage appointment booking efficiently, ensuring accurate scheduling and coordination.",
  "Additional Administrative Tasks, Assist with various administrative duties as needed to support the smooth operation of the office."
  ],
  ExperienceAndQualifications: [
  "Proficiency in English (C2 level, fully bilingual).",
  ],
  PreferredSkillsAndTools: [
  "No specific software or tools proficiency required for this position",
  "Personable and friendly demeanor.",
  "Sales background is a plus but not required.",
  "Mature candidates are preferred but not mandatory."]
},
{
  JobTitle: "Remote Receptionist for Law Firm",
    //Pacific Cascade Legal
    id: 58,
    status: "open",
    hourSalary: "$6 - 10",
    JobType: "Full Time",
    imgSrc: JobIMage11,
    url: "/jobs/jobDescription/58",
    form: "https://forms.monday.com/forms/embed/2ae024c35d3ccc557dd85f376762a162?r=use1",
  JobDescription: "We are looking for a friendly and customer service-oriented Receptionist to join our team at Pacific Cascade Legal, a dynamic and growing law firm with locations throughout the Pacific Northwest. Your role is crucial as the first impression of our firm, aligning with our Law Firm brand and Core Values.",
  CompanyValues: [
  "Client-Centric Approach",
  "Team Collaboration", 
  "Professionalism"],
  WorkSchedule: ["Mon-Fri, 8:30 am – 5:30 pm PST."],
  Tasks: [
    "Answer busy multi-line phones and handle calls with a positive attitude, friendly demeanor, and professionalism.",
    "Update and maintain contacts in the firm’s contact management system.",
    "Professionally and clearly communicate with the firm's legal team, colleagues, opposing Attorneys, courts, clients, and other parties via phone, Microsoft Teams, and e-mail.",
    "Forward calls to appropriate departments and staff members, taking and relaying messages when needed.",
    "Support the firm's administrative team as needed with other tasks and projects as necessary.",
    "Protect Senior Management’s time from unsolicited and unscheduled phone calls."
  ],
  ExperienceAndQualifications: [
    "Multi-line phone experience.",
    "Previous customer service experience.",
    "Proficient in MS Word, Excel, and Outlook.",
    "High level of professionalism along with proper phone etiquette.",
    "Clear voice and great communication skills.",
    "A desire to help people.",
    "Proactive with excellent problem-solving skills.",
    "A can-do attitude and authentic optimism.",
    "Dependable and trustworthy."
  ],
  LanguageRequirements: [
    "Bilingual is preferable."
  ],
  Skills: [
    "Customer Service",
    "Multi-line Phone Operation",
    "MS Word, Excel, and Outlook Proficiency",
    "Professionalism",
    "Clear Communication",
    "Problem Solving",
    "Proactivity",
    "Dependability"
  ],
  AdditionalDetails: [
    "This job involves being the first point of contact for our law firm and requires a friendly and professional demeanor.",
    "Adaptability to a fast-paced environment is crucial.",
    "Our firm values teamwork and collaboration.",
  ]
},
{
  JobTitle: "Career Success Advisor",
  //Yes Writing
  id: 59,
  status: "interview",
  hourSalary: "$6 - 8",
  JobType: "Full Time",
  imgSrc: JobIMage15,
  url: "/jobs/jobDescription/59",
  form: "https://forms.monday.com/forms/embed/ee4c2b19ce85cf592a2b84f6db9bfcff?r=use1",
  JobDescription: "We are seeking a dynamic and polished Career Success Advisor to join our team. As a Career Success Advisor, you will play a pivotal role in helping our clients achieve their career goals and aspirations.",
  WorkSchedule: ["(Semi-Flexible) Mon-Fri, Somewhere between 8:00 AM EST to 7:00 PM EST"],
  Tasks: [
    "Conducting mini consultations with potential clients.",
    "Expertly guiding and advising clients on their career choices.",
    "Closing deals and assisting clients in signing up for our services.",
    "Proactively following up with clients to ensure their satisfaction.",
    "Addressing any concerns or questions clients may have.",
    "Building and maintaining strong relationships with clients.",
    "Efficiently managing our Customer Relationship Management (CRM) system.",
    "Crafting persuasive and customized proposals for clients.",
    "Collaborating with the team on various tasks to enhance our service delivery."
  ],
  PreferredSkillsAndTools: ["Previous work experience in inbound sales."],
  LanguageRequirements: ["Native English proficiency is essential for this role."],
  Skills: [
    "Exceptional Polishing",
    "Client Rapport Building",
    "Inbound Sales",
    "CRM Management",
    "Proposal Writing",
    "Adaptability"
  ],
  AdditionalDetails: [
    "We are looking for someone with exceptional polish and the ability to build strong rapport with clients.",
    "While specific software proficiency is not required, a strong ability to adapt and learn new tools and systems is important.",
  ]
},
{
  JobTitle: "Sales Development Representative (SDR) - German Speaking",
  //Si Group
  id: 60,
  status: "interview",
  hourSalary: "$11 - 13",
  JobType: "Full Time",
  imgSrc: JobIMage16,
  url: "/jobs/jobDescription/60",
  form: "https://forms.monday.com/forms/embed/7a8bf2586278da6b367fa68b006250d4?r=use1",
  JobDescription: "We are seeking a motivated and results-driven Sales Development Representative (SDR) fluent in German to join our team. As an SDR, you will play a crucial role in identifying and engaging potential customers. Your primary responsibilities will include making cold calls, sending personalized prospecting emails, and utilizing LinkedIn and CRM tools to generate leads. If you're passionate about sales and are eager to make an impact, we want to hear from you.",
  WorkSchedule: ["Local German Hours (9:00 AM - 5:00 PM, Monday to Friday)"],
  TimeAllocated: [
    "Outbound Cold Calls: 40%",
    "Personalized Emails: 30%",
    "LinkedIn and Social Networking: 20%",
    "CRM Management: 10%"
  ],
  Tasks: [
    "Conduct 80-100 outbound cold calls per day to potential clients.",
    "Craft and send personalized prospecting emails to engage with prospects effectively.",
    "Utilize LinkedIn and other social media platforms for networking and lead generation.",
    "Maintain accurate and organized records of leads and interactions in the CRM system."
  ],
  ExperienceAndQualifications: [
    "Previous sales background with a proven track record of making 80-100 outbound calls daily.",
    "Proficiency in using LinkedIn for prospecting and networking.",
    "Fluent in German."
  ],
  PreferredSkillsAndTools: [
    "Experience with CRM software.",
    "Strong communication and interpersonal skills."
  ],
  LanguageRequirements: [
    "Fluent in German.",
    "English proficiency at the B2 (Upper Intermediate) level."
  ],
},
{
  JobTitle: "Sales Development Representative (SDR)",
  //Si Group
  id: 61,
  status: "interview",
  hourSalary: "$7 - 10",
  JobType: "Full Time",
  imgSrc: JobIMage17,
  url: "/jobs/jobDescription/61",
  form: "https://forms.monday.com/forms/embed/2e21888d1a49154e0125666a889cc848?r=use1",
  JobDescription: "As an SDR, you will play a crucial role in identifying and engaging potential customers. Your primary responsibilities will include making cold calls, sending personalized prospecting emails, and utilizing CRM tools to generate leads. If you're passionate about sales and are eager to make an impact, we want to hear from you.",
  WorkSchedule: ["Between 6 am - 3 pm PST, with some weeks being full-time and others part-time"],
  TimeAllocated: [
    "Pre-registration for Event: 50%" ,
    "Post-Event Follow-up: 50%" 
  ],
  Tasks: [
    "Make a minimum of 150 outbound calls per day for pre-registration of events.",
    "Engage with potential event attendees to generate interest and secure pre-registrations.",
    "Make a minimum of 150 outbound calls per day for post-event follow-up.",
    "Follow up with event participants to gather feedback, answer queries, and potentially secure further engagement."
  ],
  ExperienceAndQualifications: [
    "Previous experience in Lead Generation, Customer Service, or Outbound Sales.",
    "Proficiency in making 150 manual dials per day.",
    "Strong communication and persuasion skills."
  ],
  PreferredSkillsAndTools: [
    "Familiarity with Microsoft Dynamic CRM is a plus."
  ],
  LanguageRequirements: [
    "C2 level English proficiency (Fully Bilingual)."
  ]
},
{
  JobTitle: "Administrative Assistant",
  //Contractor independence
  id: 62,
  status: "waiting for the HIRED",
  hourSalary: "$6 - 10.40",
  JobType: "Full Time",
  imgSrc: JobIMage18,
  url: "/jobs/jobDescription/62",
  form: "https://forms.monday.com/forms/embed/e9b5c161e11ced54769057e3d503c7e9?r=use1",
  JobDescription: "We are seeking a highly motivated Administrative Assistant to work alongside our leadership team at our 32-person Digital Marketing Agency based in Richmond, Virginia. Since our founding in 2018 by two US Army vets, we have built our reputation on building organic lead generation assets that bring in high-quality job requests for contractors. Our mission is to free contractors from lead companies forever. We help empower our clients to take control of their business, invest in their own brand, and grow organically. We have worked with over 700 contractors, earned them strong results, and gathered over 100 five-star reviews. Our business has 5x’d in size and in monthly revenue in the last 2 years, and we plan to double again over the next year.",
  WorkSchedule: ["9:00-6:00 pm US Eastern"],
  CompanyValues: [
    "Ownership, Every Team Member acts like an Owner of their Role",
    "Clear Communication, We utilize BLUF communication (Bottom Line Up Front)",
    "Team Results Over Self, We are radically Results Focused.",
  ],
  Tasks: [
    "Changing recurring billing schedules for clients when their campaign status changes",
    "Daily uploading of a CSV to a Google Sheet to track client payments",
    "Ordering company shirts for new team members, etc.",
    "Act as the 'Swiss Army Knife' of the company, handling various tasks across the organization as needed.",
    "Sitting in on Leadership Meetings and having visibility on large portions of the company and access to company payment methods.",
    "Seeking out opportunities to take tasks and responsibilities off of Leader's plates and getting to know their workflows to save them time.",
    "Being open to dynamic (outside of the box) thinking to find proposed solutions.",
    "Building and documenting processes for streamlined work in the future.",
    "Accomplishing a wide range of administrative tasks, from well-defined and repetitive to open-ended and creative.",
    "Improving the morale and well-being of team members by acting as a 'community coordinator'.",
    "Operating with flexible agility and being extremely responsive in communication."
  ],
  ExperienceAndQualifications: [
    "Prior experience as an Executive Assistant or Admin Assistant (Preferred).",
    "Experience building processes.",
    "Experience working in Google Sheets.",
    "Experience in a remote work environment.",
    "Experience finding creative solutions to problems."
  ],
  LanguageRequirements: [
    "C2 English level"
  ],
  Skills: [
    "Loyal / Trustworthy",
    "Eager / Attentive",
    "Detail-Oriented",
    "Hard-Working",
    "Takes Pride in Work",
    "Intelligent, Innovative and Open-Minded",
    "Caring"
  ]
},
{
  JobTitle: "Researcher - Recruiter / SDR",
  //Sanford Rose Associates International, Inc.
  id: 63,
  status: "interview",
  hourSalary: "$6 - 9.5",
  JobType: "Full Time",
  imgSrc: JobIMage19,
  url: "/jobs/jobDescription/63",
  form: "https://forms.monday.com/forms/embed/1cec6ba658aa8a7c71f35cb2fdfe0159?r=use1",
  JobDescription: "We are seeking a motivated and adaptable Researcher - Recruiter / SDR to join our team. In this role, you will play a crucial part in identifying and engaging with potential candidates for our clients. The ideal candidate should have a solid understanding of technology, the ability to quickly grasp industry specifics, and possess the potential to grow into an SDR (Sales Development Representative) or Recruiter role in the future.",
  WorkSchedule: ["9:00 AM - 5:00 PM EST, Monday to Friday"],
  Tasks: [
    "Utilize LinkedIn and other resources for researching potential candidates.",
    "Create and maintain lists of potential candidates for follow-up by the practice lead.",
    "Conduct initial outreach via email blasts to engage with potential candidates.",
    "Compile lists of company contacts for recruitment purposes.",
    "Collaborate with the team to identify suitable candidates.",
    "Eventually, perform phone screenings and cold calling as needed."
  ],
  ExperienceAndQualifications: [
    "Minimum of 12-18 months of professional experience.",
    "Strong understanding of technology and the ability to learn about the industry quickly.",
    "Potential to advance into an SDR or Recruiter role in the future."
  ],
  LanguageRequirements: [
    "C2 level of English proficiency (Fully Bilingual). As long as it can be understood, accent is not a problem."
  ],
  Skills: [
    "Proficiency in LinkedIn for candidate research.",
    "Phone experience is a plus but not required.",
    "Excellent communication skills."
  ],
},
{
  JobTitle: "VA Appointment Setter",
  //Viewpoint Finantial Home Loans
  id: 64,
  status: "hired",
  hourSalary: "$5 - 7",
  JobType: "Part Time",
  imgSrc: JobIMage6,
  url: "/jobs/jobDescription/64",
  form: "https://forms.monday.com/forms/embed/f4ed22b3d77e1ae1bd4d9935baf8cc80?r=use1",
  JobDescription: "We are seeking a VA Appointment Setter to join our team. This part-time position involves contacting real estate agents to schedule appointments",
  WorkSchedule: ["10:00 AM - 14:00 PM, Monday - Friday / PST"],
  Tasks: [
    "Outbound Calls, Make outbound calls to real estate agents using a provided script.",
    "Appointment Setting, Schedule appointments with real estate agents based on their availability.",
    "Call Result Documentation, Maintain accurate records of call results and interactions.",
    "Calendar Management, Input scheduled appointments into a calendar system, if applicable."
  ],
  ExperienceAndQualifications: [
    "Some previous outbound call experience.",
  ],
  LanguageRequirements: [
    "C2 level of English proficiency (Fully Bilingual)."
  ]
  },
  {
    JobTitle: "Systems Engineer",
    //Contractor Independence
    id: 65,
    status: "open",
    hourSalary: "No Min - Max 28",
    JobType: "Full Time",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/65",
    form: "https://forms.monday.com/forms/embed/603cfc998551984c7b83166c427902a7?r=use1",
    JobDescription: "We are on the hunt for an experienced Systems Engineer! If you love problem-solving, automating workflows, data analysis, and designing business solutions, then we need you on our team! 🚀 We are a results-driven, innovative Digital Marketing Agency dedicated to freeing contractors from lead generation companies. We believe in investing in our own brand, growing organically, and helping our clients do the same. In this role, you will be directly reporting to our CTO and playing a vital role in the stability and performance of our digital processes. With over 1000 automations running daily, your expertise will ensure seamless operations across the organization.",
    CompanyValues: [
      "Ownership",
      "Clear Communication",
      "Team Results Over Self"
    ],
    ExperienceAndQualifications: [
      "2+ years of experience building automated workflows.",
      "2+ years of experience building and using Google Sheets for data warehousing.",
      "1+ year of experience configuring/automating/managing CRMs.",
      "Analytical or business-focused degree."
    ],
    AdditionalDetails: [
      "What you will love about us:",
      "🎯 Our mission to free contractors from lead generation companies.",
      "👥 A 27-strong team of dedicated professionals.",
      "🚀 An opportunity to grow with a company that has 5x’d its size in the last 12 months.",
      "🌍 Remote work opportunities.",
    ]
  },
  {
    JobTitle: "Customer Success / Account Executive",
    //Creators Training
    id: 66,
    status: "declined",
    hourSalary: "9 - 16",
    JobType: "Full Time",
    imgSrc: JobIMage11,
    url: "/jobs/jobDescription/66",
    form: "https://forms.monday.com/forms/embed/abb4ff27f07705787b3e3773e459846c?r=use1",
    JobDescription: "We are seeking a motivated Customer Success / Account Executive to join our team. The ideal candidate will have a flexible work schedule, a tech-savvy mindset, and exceptional communication skills. Experience is not the primary focus; we value personality traits such as critical thinking, autonomy, positivity, and the ability to challenge the status quo.",
    Tasks: [
      "Inbound Calls (5-6 Zoom calls a day), Engage with clients in 45-minute Zoom calls, providing exceptional customer service and addressing their needs and inquiries.",
      "Onboarding & Account Management, Assist in onboarding new clients, ensuring a smooth transition into our services, and manage their accounts for the first week to guarantee satisfaction.",
      "Coaching Calls, Participate in coaching calls as needed to provide guidance and support to clients."
    ],
    TimeAllocated: [
      "Inbound Calls: 60%",
      "Onboarding & Account Management: 30%",
      "Coaching Calls: 10% ",
    ],
    ExperienceAndQualifications: [
      "Experience, Experience is not a primary requirement, but a background in customer service or account management is a plus.",
      "Personality Traits, Critical thinking, ability to work autonomously, positive attitude, and a willingness to challenge conventions.",
      "Language Skills, Fully bilingual with a C2 level of English proficiency.",
      "Software/Tools Proficiency, Tech-savvy, with the ability to quickly adapt to and navigate various software and tools."
    ]
  },
  {
    JobTitle: "Marketing Director",
    //Creators Training
    id: 67,
    status: "declined",
    hourSalary: "10 - 19",
    JobType: "Full Time",
    imgSrc: JobIMage17,
    url: "/jobs/jobDescription/67",
    form: "https://forms.monday.com/forms/embed/0991d3b4e0d1a6e3ae8b08842e1b16ee?r=use1",
    JobDescription: "As the Marketing Director, you will play a pivotal role in managing and optimizing digital marketing campaigns. You will be responsible for overseeing Google and Facebook Ads, as well as executing email marketing campaigns. This position requires a candidate who is tech-savvy, proficient in marketing tools, and has a knack for breaking down numbers.",
    WorkSchedule: ["Bali Timezone (semi-flexible)"],
    Tasks: [
      "Google & FB Ads: Strategize, create, and optimize Google and Facebook advertising campaigns to maximize ROI.",
      "Email Marketing Campaigns: Develop and execute email marketing strategies to engage and nurture leads."
    ],
    ExperienceAndQualifications: [
      "Proficiency in Google and Facebook Ads, as well as email marketing.",
      "Strong technical skills and the ability to analyze and interpret data.",
      "Tech-savvy individual with experience using various marketing tools.",
      "Ability to work autonomously and teach others how to manage Google and Facebook Ads.",
      "Quick learner with critical thinking skills.",
      "Clear and direct communication style.",
    ],
    PreferredSkillsAndTools: [
      "Experience with YouTube Ads.",
      "Additional language skills."
    ],
    LanguageRequirements: [
      "C1 (Advanced) English proficiency."
    ],
    AdditionalDetails: [
      "This role offers the opportunity to share knowledge and teach team members how to effectively manage Google and Facebook Ads.",
      "The Marketing Director will have the autonomy to make strategic decisions and execute campaigns.",
      "Our ideal candidate possesses strong critical thinking skills and is comfortable with direct communication."
    ]
  },
  {
    JobTitle: "Sales Development Representative (SDR)",
    //11-11 Partners
    id: 68,
    status: "open",
    hourSalary: "7 - 10.5",
    JobType: "Full Time",
    imgSrc: JobIMage8,
    url: "/jobs/jobDescription/68",
    form: "https://forms.monday.com/forms/embed/3d7a0448c1196d47d97c06ad43f6c441?r=use1",
    JobDescription: "We're committed to making a positive impact on the world by promoting sustainable food and climate solutions. We're seeking a passionate and dedicated Sales Development Representative (SDR) who shares our values and wants to be an integral part of our mission.",
    CompanyValues: [
      "Integrity", 
      "Genius", 
      "Teamwork", 
      "Innovation"],
    Tasks: [
      "Create Loom Videos for Clients, Develop engaging video content to showcase our products and services to potential clients.",
      "Discovery Calls, Initiate and conduct discovery calls with prospective clients to understand their needs and align our solutions accordingly.",
      "Cold Calls, Proactively reach out to potential leads through cold calling, introducing our offerings, and generating interest.",
      "Client Relations, Build and maintain strong relationships with clients, providing exceptional service and support throughout their journey with us."
    ],
    ExperienceAndQualifications: [
      "Experience in outbound calls.",
    ],
    PreferredSkillsAndTools: [
      "Proficiency in relevant software/tools is a plus.",
      "Experience in the Food and Climate industry.",
      "Experience in Cold Calling."
    ],
    LanguageRequirements: [
      "Native English"
    ],
  },
  {
    JobTitle: "Dispatch Agent",
    //Officer Reports
    id: 69,
    status: "interview",
    hourSalary: "6 - 6.5",
    JobType: "Full Time",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/69",
    form: "https://forms.monday.com/forms/embed/67eda24ef2bc41e362c0f548ef73c6f1?r=use1",
    JobDescription: "We are seeking a Dispatch Agent to join our team for overnight shifts. As a Dispatch Agent, you will play a crucial role in ensuring the safety and security of our clients by monitoring security guards and responding to alerts. This position requires a patient, honest, and somewhat tech-savvy individual with a background in security guard services preferred.",
    WorkSchedule: ["11:00 PM - 7:00 AM CST"],
    TimeAllocated: [
      "Inbound Calls and Communications: 70%",
      "Monitoring Security Guards: 20%",
      "Administrative Tasks: 10%"
    ],
    Tasks: [
      "Handle inbound calls and occasionally make outbound calls and send text messages as necessary.",
      "Monitor the location and status of security guards to ensure their well-being and readiness to respond to security incidents.",
      "Respond to alerts promptly, taking appropriate actions based on the situation, such as contacting authorities or dispatching backup.",
      "Maintain clear and accurate records of all communications and incidents.",
      "Work collaboratively with security personnel to provide support and assistance when needed.",
      "Demonstrate exceptional patience and professionalism when dealing with clients and security personnel.",
      "Utilize basic technology tools effectively to perform job responsibilities."
    ],
    ExperienceAndQualifications: [
      "Preferred background in security guard companies or experience as a security guard.",
      "Strong communication skills.",
      "C1 (Advanced) level of English proficiency.",
      "Tech-savvy with the ability to adapt to various software and tools.",
      "Must be patient, have a thick skin, and demonstrate honesty."
    ],
    LanguageRequirements: [
      "C1 (Advanced) level of English proficiency."
    ]
  },
  {
    JobTitle: "Digital Email Communication Specialist",
    //The Rainmaker Institute
    id: 70,
    status: "declined",
    hourSalary: "7 - 9",
    JobType: "Full Time",
    imgSrc: JobIMage16,
    url: "/jobs/jobDescription/70",
    form: "https://forms.monday.com/forms/embed/9e8915a63a54faec1924cf221597092a?r=use1",
    JobDescription: "We are looking for a confident, energetic Digital Email Communications Specialist who is passionate about all things email and texting. This role is part of a team responsible for building software systems for our clients - law firms - and creating nurturing campaigns that convert leads into clients. The ideal ROCK STAR will have at least 2 years of email marketing experience in setting up drip campaigns in CRM software.",
    TimeAllocated: [
      "Review and enter emails into software system: 70%",
      "Pull data from graphs: 20%",
      "Interact with clients: 10%"
    ],
    ExperienceAndQualifications: [
      "Excellent organization and communication skills",
      "Experience with email campaign creation",
      "Creatio experience is a big plus"
    ],
    PreferredSkillsAndTools: [
      "Solid knowledge of Microsoft Office and various email platforms (HubSpot, Constant Contact, Infusionsoft/Keap, etc…)",
      "Creatio (preferred)",
      "CRM"
    ],
    LanguageRequirements: ["C2 (Native)"],
  },
  {
    JobTitle: "Recruiting Development Representative (RDR)",
    //Sanford Rose Associates
    id: 71,
    status: "interview",
    hourSalary: "8 - 11.50",
    JobType: "Full Time",
    imgSrc: JobIMage15,
    url: "/jobs/jobDescription/71",
    form: "https://forms.monday.com/forms/embed/f9d26b77fde9bffa61c3db96dd62da16?r=use1",
    JobDescription: "The Recruiting Development Representative (RDR) position is in the Architecture, Engineering & Construction (AEC) industry.",
    WorkSchedule: ["Monday to Friday, 8:00 AM – 5:00 PM EST"],
    TimeAllocated: [
      "Candidate Research and List Development: 20%",
      "Candidate Outreach: 30%",
      "Appointment Setting: 20%",
      "Training and Development: 10%",
      "ATS Management: 20%"
    ],
    ExperienceAndQualifications: [
      "Strong verbal and written communication skills with a confident phone presence.",
      "Familiarity with an Applicant Tracking System (ATS), preferably PCRrecruiter.",
      "Results-driven and motivated by financial incentives.",
      "Strong research skills, showing a proactive approach to identifying the right candidates.",
      "Native English speaker"
    ],
    PreferredSkillsAndTools: [
      "Previous experience or knowledge in the Architecture, Engineering & Construction (AEC) industry."
    ],
    LanguageRequirements: [
      "Native English"
    ],
    AdditionalDetails: [
      "Career Growth, The goal is to provide training and mentorship to transition into a candidate-facing role within a few months. Encouragement for long-term career growth and development within the company."
    ]
  },
  {
    JobTitle: "Executive Assistant",
    //AG Group
    id: 72,
    status: "declined",
    hourSalary: "6 - 11",
    JobType: "Full Time",
    imgSrc: JobIMage13,
    url: "/jobs/jobDescription/72",
    form: "https://forms.monday.com/forms/embed/d11d47f9878c634345d39d8302deb5d8?r=use1",
    JobDescription: "We are seeking a versatile and experienced Executive Assistant in overseeing multiple company portfolios. This position offers a flexible work schedule, allowing you to adapt to the dynamic needs of the role. If you are a native English speaker with previous Executive Assistant experience and are ready to take on diverse daily responsibilities, we encourage you to apply for this opportunity.",
    ExperienceAndQualifications: [
      "Previous experience as an Executive Assistant is essential, showcasing your ability to handle diverse tasks and responsibilities.",
      "Flexibility, Be prepared to adapt to changing responsibilities on a day-to-day basis, demonstrating agility and resourcefulness in tackling new challenges."
    ],
    PreferredSkillsAndTools: [
      "Familiarity with Airbnb operations and property management would be advantageous."
    ],
    Tasks:[
      "Airbnb Communication, Act as the primary point of contact for Airbnb managers in Switzerland. Engage in effective and timely communication, track important information, and ensure smooth collaboration.",
      "Portfolio Oversight, Assist in managing multiple company portfolios, providing support in various administrative tasks related to these ventures.",
      "Portfolio Management, The position involves providing support in overseeing our client's multiple company portfolios, requiring an ability to juggle various administrative tasks efficiently."
    ],
    LanguageRequirements: ["Native-level English proficiency is required to effectively communicate and collaborate."],
  },
  {
    JobTitle: "Web Developer Expert",
    //The Rainmaker Institute
    id: 73,
    status: "open",
    hourSalary: "6 - 10",
    JobType: "Full Time",
    imgSrc: JobIMage11,
    url: "/jobs/jobDescription/73",
    form: "https://forms.monday.com/forms/embed/08ed428ed41e8aaac4a3dde2214c001c?r=use1",
    JobDescription: "We are looking for a dynamic and experienced web developer expert to join our expanding team. In this role, you will be responsible for managing and overseeing new website projects, as well as updates and changes to existing client websites. Technical SEO will be a key component at the launch of websites, with ongoing SEO responsibilities for various clients. Effective communication with the client's account manager for changes, results, and website strategies is essential. We are looking for a highly organized individual with a positive, approachable demeanor and the ability to balance client requests with their expectations.",
    ExperienceAndQualifications: [
      "Expert in WordPress, Webflow, and Photoshop, with at least 3 years of experience.",
      "Bachelor's degree in marketing, communication, or a related field.",
      "Proficiency in JavaScript, JQuery, PHP, HTML, HTML5, CSS, CSS3, and Web Programming.",
      "Knowledgeable about Google Tag Manager and other tracking code implementations.",
      "Solid understanding of SEO and SEM techniques for websites.",
      "Expertise in Web UI Design, security principles, multimedia content development, and APIs.",
      "Proficiency with project management tools like Asana, TeamWork, Trello, etc.",
      "Ability to edit content in WordPress.",
      "Excellent communication and interpersonal skills.",
      "Ability to work independently and stay focused while managing multiple clients.",
      "Strong organizational and time management skills.",
      "Dependable high-speed internet connection for conducting client calls via video."
    ],
    LanguageRequirements: ["High-level English understanding is required, although this is not a client-facing role. Communication skills should be sufficient to meet with clients if necessary, but the primary focus is on project delivery."],
    AdditionalDetails: [
      "A portfolio showcasing 5-10 of your latest website design and development projects.",
      "5 websites highlighting advanced SEO techniques."
    ]
  },
  {
    JobTitle: "Researcher/RDR (Recruitment and Database Researcher)",
    //Sandford Rose Associates
    id: 74,
    status: "open",
    hourSalary: "8.5 - 12",
    JobType: "Full Time",
    imgSrc: JobIMage13,
    url: "/jobs/jobDescription/74",
    form: "https://forms.monday.com/forms/embed/c8948d60f76b799cbe21b571df51300a?r=use1",
    JobDescription: "As a Researcher/RDR (Recruitment and Database Researcher), you will play a pivotal role in our dynamic team, contributing to the growth and success of our company. Leveraging your expertise in the automotive management industry and your strong communication skills, you will be responsible for supporting our recruitment efforts and enhancing our candidate database.",
    Tasks: [
      "Candidate Sourcing, You will play a critical role in supporting our recruitment team by proactively building a pipeline of highly qualified candidates through various sourcing methods.",
      "Cold Calling, Reach out to potential candidates to introduce them to exciting career opportunities with our clients, providing key details about the positions available.",
      "Database Management, Continuously improve and maintain our candidate database, ensuring accuracy, completeness, and organization."
    ],
    LanguageRequirements: [
      "Minimum English Proficiency, Intermediate to Proficient"
    ],
    Skills: [
      "Communication Skills, Strong communication skills are essential, both written and verbal. You should be able to articulate job opportunities effectively to potential candidates.",
      "Personable, Being approachable, friendly, and able to establish rapport with candidates is key to success in this role.",
      "Time Zone, Ability to work in the Pacific Time Zone (PST)."
    ],
  },
  {
    JobTitle: "Business Process Consultant (Analyst, Architect)",
    //Completing
    id: 75,
    status: "open",
    hourSalary: "up to 36",
    JobType: "Full Time",
    imgSrc: JobIMage15,
    url: "/jobs/jobDescription/75",
    form: "https://forms.monday.com/forms/embed/9abea6066779239025912c027e08235c?r=use1",
    JobDescription: "We are seeking a highly skilled and experienced Business Process Consultant to join our team. As a Business Process Consultant, you will play a pivotal role in improving and optimizing client functions by designing more efficient processes. You will collaborate directly with clients, utilizing your expertise in business process improvement within a technical environment. If you are a proactive problem solver with a strong background in workflow tools and project management, we encourage you to apply.",
    WorkSchedule: ["15 hours per week with a flexible schedule, available for 2-3 calls per week."],
    TimeAllocated: [
      "Process Design and Improvement: 70%",
      "Client Interaction: 25%",
      "Project Management (Preferred): 5%"
    ],
    PreferredSkillsAndTools: [
      "Project management experience."
    ],
    ExperienceAndQualifications: [
      "5+ years of experience in business process improvement in a technical environment.",
      "Proficiency in business workflow tools such as Asana.",
      "Exceptional communication skills, both written and verbal.",
      "Active listening skills.",
      "Confidence in advising clients on necessary improvements.",
    ],
    LanguageRequirements: [
      "Minimum English Level: Native English."
    ],
  },
  {
    JobTitle: "Social Media Manager",
    //Imagen Consultants
    id: 76,
    status: "open",
    hourSalary: "6.5 -  9",
    JobType: "Part Time",
    imgSrc: JobIMage17,
    url: "/jobs/jobDescription/76",
    form: "https://forms.monday.com/forms/embed/3bdf4d1950aa36a71f155c9bf9bd4f9a?r=use1",
    JobDescription: "We are seeking a creative and engaging Social Media Manager to join our team on a part-time basis. The ideal candidate will be responsible for curating visually appealing content, featuring designers from various countries, and creating captivating graphic images to elevate our online presence. If you have a knack for graphic design, reel creation, and are ready to infuse fun and personality into our brand, we want to hear from you!",
    TimeAllocated: [
      "Reel Creation: 60%",
      "Graphic Design: 40%"
    ],
    Tasks:[
      "Reel Creation, Produce engaging Reels that showcase the work of designers from around the world. Craft visually appealing and entertaining short videos that resonate with our target audience",
      "Graphic Design, Create eye-catching graphic images that align with our brand identity. These images should effectively convey our message and captivate our followers"
    ],
    ExperienceAndQualifications: [
      "Proficiency in Adobe Creative Suite",
      "Graphic design experience",
      "Ability to create compelling Reels",
    ],
    PreferredSkillsAndTools: [
      "Familiarity with social media management tools",
      "Experience in voice-over work"
    ],
    LanguageRequirements: [
      "Minimum C1 (Advanced) level of English"
    ],
    Skills: [
      "Must have an engaging and fun personality",
      "Enthusiastic about the role and the brand",
      "Ability to connect with the audience"
    ]
  },
  {
    JobTitle: "Appointment Setter",
    //Gem city
    id: 77,
    status: "open",
    hourSalary: "6 - 8",
    JobType: "Part Time",
    imgSrc: JobImage5,
    url: "/jobs/jobDescription/77",
    form: "https://forms.monday.com/forms/embed/d70beb785090f67ad8887af7b7d717c8?r=use1",
    JobDescription: "We are seeking a motivated individual with a fun and upbeat personality to engage with potential clients in a positive and engaging manner to join our team as an Appointment Setter, responsible for generating leads through cold calling and with a C2 English level, fully bilingual.",
    WorkSchedule: ["15 hours per week, flexible during American business hours"],
    Tasks: [
      "Conduct 50 Cold Calls per day",
      "Assist the sales team in prospecting and lead generation activities.",
      "Conduct research on potential clients, identifying key decision-makers, and gathering relevant information.",
      "Engage in outbound sales calls and emails to schedule appointments and generate new business opportunities.",
      "Support the sales team in preparing sales materials, presentations, and proposals.",
      "Maintain CRM database, update client information, and track sales activities."
    ],
    ExperienceAndQualifications: [
      "Previous experience with cold calling is essential for success in this role.",
      "Excellent spoken and written English communication skills.",
      "Motivated individual with a fun and upbeat personality.",
    ],
    PreferredSkillsAndTools: [
      "Strong prospecting and lead generation skills.",
      "Familiarity with CRM systems and sales tools.",
      "Self-motivated and goal-oriented mindset."
    ],
    LanguageRequirements: [
      "C2 English level, fully bilingual"
    ]
  },
  {
    JobTitle: "Inbound Sales - Customer Success Representative",
    //Gem city
    id: 78,
    status: "open",
    hourSalary: "6.5 -  9",
    JobType: "Full Time",
    imgSrc: JobIMage11,
    url: "/jobs/jobDescription/78",
    form: "https://forms.monday.com/forms/embed/c1c6b3e7c73de3615efdbbd09449c452?r=use1",
    JobDescription: "We are seeking a dynamic and motivated Inbound Sales - Customer Success Representative to join our team. This role offers a semi-flexible work schedule, competitive compensation, and an opportunity to work in a thriving industry.",
    WorkSchedule: ["Semi-Flexible, with hours ranging from 9:00 AM to 5:00 PM or 10:00 AM to 6:00 PM EST."],
    CompanyValues: [
      "Flexible hours.",
      "Competitive compensation.",
      "Career advancement opportunities.",
      "We are proud to be an Equal Employment Opportunity Employer. We do not discriminate based upon race, religion, color, national origin, gender (including pregnancy, childbirth, or related medical conditions), sexual orientation, gender identity, gender expression, age, status as a protected veteran, status as an individual with a disability, or other applicable legally protected characteristics."
    ],
    Tasks: [
      "New Customer Acquisition (50% of the day):",
      "Source and qualify new prospects seeking solutions to reach their internal goals.",
      "Conduct product demos for potential customers.",
      "Reach out to inbound prospects and guide them through the sales process.",
      "Utilize cold calling and cold emailing strategies to engage accounting firms.",
      "Meet or exceed sales revenue goals and objectives by closing new business as outlined by Management.",
      "Effectively coordinate all aspects of the sale from start to finish.",
      "Present and sell new products to new business opportunities.",
      "Preparing responses to RFP (request for proposals) and RFI (request for information).",
      "Customer Relationship Management (50% of the day):",
      "Provide comprehensive one-hour product demos.",
      "Ensure customer satisfaction and success by addressing inquiries and concerns.",
      "Collaborate with customers to understand their needs and tailor solutions accordingly.",
      "Manage and interpret customer requirements – speaking with clients to understand, anticipate and meet their needs and identify and resolve client concerns.",
      "Expand market awareness of our products and our industry experience by communicating features and benefits of our products and services.",
      "Maintaining and entering all prospects and new sales information into the applicable CRM.",
      "Develop and maintain product knowledge and be an industry expert.",
      "Work with training, billing, and support team members to ensure new customer handoff goes smoothly.",
      "Provide customer support to our existing users.",
      "Performs other job-related tasks, as assigned by Management.",
      "Adhere to all company policies and values."
    ],
    ExperienceAndQualifications: [
      "Basic understanding of accounting principles.",
      "Proficiency in Microsoft Office Suite.",
      "Strong understanding of the sales process.",
      "C2 level English proficiency (Fully Bilingual).",
      "Minimum 3+ years of SaaS sales experience in a business-to-business sales environment.",
    ],
    PreferredSkillsAndTools: [
      "Knowledge of non-profit accounting.",
      "A four-year college degree from an accredited institution is preferred."
    ],
    LanguageRequirements: [
      "C2 level English proficiency (Fully Bilingual)."
    ],
    Skills: [
      "Demonstrated ability to establish priorities and meet goals.",
      "Articulate, confident, driven professional with the ability to work with like-minded professionals in a results-oriented, fast-paced and growing environment.",
      "Professional with a proven capacity to exceed sales targets.",
      "Must have effective written and verbal communication skills.",
      "Must have demonstrated knowledge of sales processes and techniques.",
      "Must possess excellent customer service, time management, and organizational skills.",
      "Must be able to work autonomously with little guidance from management.",
      "Must be able to establish and maintain cooperative-working relationships with those contacted in the course of the work assignment."
    ],
  },
  {
    JobTitle: "Administrative Assistant",
    //Mokaram Law Firm
    id: 79,
    status: "open",
    hourSalary: "6 - 9.5",
    JobType: "Full Time",
    imgSrc: JobIMage12,
    url: "/jobs/jobDescription/79",
    form: "https://forms.monday.com/forms/embed/0e58cc6278ae874b3063d6773df0d3c9?r=use1",
    JobDescription: "As an Administrative Assistant, you will play a crucial role in supporting a dynamic legal team in the field of Personal Injury Law. Your responsibilities will encompass a wide range of administrative and case management tasks, with a focus on ensuring efficient communication, organization, and follow-up in a fast-paced legal environment.",
    WorkSchedule: ["8:30 am - 5:30 pm CST, Monday to Friday"],
    Tasks: [
      "Insurance Adjuster Follow-up, The Administrative Assistant will be responsible for diligently following up with insurance adjusters, ensuring that all necessary documentation and information are submitted and tracked effectively.",
      "Initial Offer Discussions, Engage in discussions with insurance companies regarding initial settlement offers. Strong negotiation skills and attention to detail are crucial in this role.",
      "Liens Management, Take charge of opening and managing liens, maintaining accurate records, and ensuring compliance with all legal requirements.",
      "Client Follow-up, Maintain regular communication with clients, addressing their inquiries and concerns, and providing updates on case progress.",
      "Reduction Work, Collaborate with legal professionals to work on reducing liens and maximizing client compensation.",
      "Clerical/Administrative Tasks, Perform general administrative duties, including but not limited to data entry, file organization, and other clerical tasks to support the legal team."
    ],
    TimeAllocated: [
      "Insurance Adjuster Follow-up: 20%",
      "Initial Offer Discussions: 15%",
      "Liens Management: 10%",
      "Client Follow-up: 15%",
      "Reduction Work: 15%",
      "Clerical/Administrative Tasks: 25%"
    ],
    ExperienceAndQualifications: [
      "Some experience in a law firm is necessary to excel in this role.",
    ],
    PreferredSkillsAndTools: [
        "Experience with Personal Injury Law Firms, especially in an American firm, is highly desirable."
    ],
    LanguageRequirements: [
      "Proficiency in English at a C2 level (Fully Bilingual)."
    ],
    Skills: [
      "Excellent communication skills, both written and verbal.",
      "Strong organizational skills with the ability to manage multiple tasks and priorities.",
      "Ability to work autonomously and take initiative.",
      "An outgoing personality and a pleasant voice (not a deal-breaker but advantageous)."
    ],
  },
  {
    JobTitle: "Sales Development Representative - Appointment Setting",
    //Rcor
    id: 80,
    status: "open",
    hourSalary: "6 - 8.5",
    JobType: "Part Time",
    imgSrc: JobIMage15,
    url: "/jobs/jobDescription/80",
    form: "https://forms.monday.com/forms/embed/adb59c1c94c7de8f197234e9dca3535b?r=use1",
    JobDescription: "We are seeking a Sales Development Representative (SDR) to join our team in a part-time role focused on appointment setting. As an SDR, you will play a vital role in our lead generation process by identifying and connecting with potential clients. This role is ideal for tech-savvy individuals who can communicate effectively, clean lead lists, and engage with potential clients via LinkedIn and email.",
    WorkSchedule: ["10:00 AM - 2:00 PM EST"],
    Tasks: [
      "Lead List Management, Call and clean lead lists to ensure accurate and up-to-date information.",
      "LinkedIn Outreach, Connect and target potential clients via LinkedIn to build relationships and generate leads.",
      "Email Follow-Up, Follow up on emails sent to potential clients, addressing any questions or inquiries."
    ],
    TimeAllocated: [
      "Lead List Management: 40%",
      "LinkedIn Outreach: 30%",
      "Email Follow-Up: 30%"
    ],
    ExperienceAndQualifications: [
      "Proficiency in English (Native English Speaker).",
      "Tech-savvy with a basic understanding of CRM systems (experience with Hubspot is a plus).",
    ],
    "PreferredSkillsAndTools": [
      "Previous experience in sales or lead generation."
    ],
    Skills: [
      "We are looking for candidates who are not pushy and avoid excessive sales tactics.",
      "A friendly and professional demeanor is essential for building positive client relationships."
    ],
  },
  {
    JobTitle: "Marketing Assistant",
    //Rcor
    id: 81,
    status: "open",
    hourSalary: "5 - 6",
    JobType: "Part Time",
    imgSrc: JobIMage13,
    url: "/jobs/jobDescription/81",
    form: "https://forms.monday.com/forms/embed/48340f6a97a6931d67978dd4357b35bc?r=use1",
    JobDescription: "As a Marketing Assistant, you will play a crucial role in supporting the client's marketing efforts.",
    WorkSchedule: ["9:00 AM - 12:00 PM EST"],
    Tasks: [
      "Email Marketing, Create and manage email marketing campaigns, including designing and sending emails, tracking metrics, and optimizing campaigns for engagement and conversions.",
      "Lead Verification and Cleaning, Verify and maintain the accuracy of lead lists, ensuring data quality and consistency.",
      "LinkedIn, Assist in LinkedIn outreach and engagement activities to connect with potential clients and partners."
    ],
    ExperienceAndQualifications: [
      "TBD"
    ],
    LanguageRequirements: [
      "Candidates should have a minimum English proficiency level of C1 (Advanced)."
    ],
  },
  {
    JobTitle: "Account Executive",
    //Nitro Pack
    id: 82,
    status: "open",
    hourSalary: "up to 28",
    JobType: "Full Time",
    imgSrc: JobIMage16,
    url: "/jobs/jobDescription/82",
    form: "https://forms.monday.com/forms/embed/dee6dfa0f805b802f37beaae4ba4f1ec?r=use1",
    JobDescription: "We are seeking a motivated and experienced Account Executive to join our team. As an Account Executive, you will play a pivotal role in our sales department, responsible for driving and managing the entire sales cycle, from prospecting to closing deals. This position offers the opportunity to earn a competitive base salary with substantial commission potential. If you are a self-driven individual with a passion for SaaS sales and are comfortable working in a dynamic start-up environment, we encourage you to apply.",
    WorkSchedule: ["8-hour workday, from 10 am to 6 or 7 pm EST."],
    Tasks: [
      "Drive and manage the entire sales cycle, from prospecting to closing deals.",
      "Identify and engage potential customers through various channels, including email, phone, and social media.",
      "Build and maintain strong relationships with prospects and customers.",
      "Provide exceptional customer service throughout the sales process and beyond.",
      "Participate in drafting and executing our mid-market plan to expand our customer base.",
      "Proactively identify and pursue potential clients through various lead generation methods.",
      "Handle inbound inquiries and conduct outbound cold calls to engage potential prospects.",
      "Conduct engaging product demonstrations to showcase the value and benefits of our SaaS products to clients.",
      "Analyze potential clients to understand their needs, challenges, and opportunities before meetings.",
      "Tailor your approach and solutions to address client-specific requirements.",
      "Utilize your sales skills and product knowledge to close deals successfully.",
      "Effectively address objections and concerns raised by clients during the sales process.",
      "Work independently and take ownership of your sales pipeline and performance.",
      "Be adaptable and willing to create or retool processes as necessary to improve sales efficiency."
    ],
    ExperienceAndQualifications: [
      "Minimum of 3 years of sales experience in the SaaS industry.",
      "Experience with Hubspot CRM and sales automation tools.",
      "Proven track record of exceeding sales quotas.",
      "Excellent communication, negotiation, and interpersonal skills.",
      "Ability to work independently and as part of a team.",
      "Bachelor's degree in Business, Marketing, or related field preferred.",
      "Knowledge of the WordPress or Shopify ecosystem is considered a big plus."
    ],
    PreferredSkillsAndTools: [
      "Proficiency in Hubspot.",
      "Exceptional communication and presentation skills.",
      "Strong negotiation and objection-handling abilities."
    ],
    LanguageRequirements: [
      "Native-level English proficiency."
    ]
  },
  {
    JobTitle: "Intake Specialist - Receptionist",
    //Nitro Pack
    id: 83,
    status: "open",
    hourSalary: "up to 28",
    JobType: "Full Time",
    imgSrc: JobIMage17,
    url: "/jobs/jobDescription/83",
    form: "https://forms.monday.com/forms/embed/86429be88d4b53ccc499df144a9e1b05?r=use1",
    JobDescription: "We are seeking a motivated and customer-oriented Intake Specialist - Receptionist to join our team. This position plays a crucial role in ensuring excellent customer service and efficient office operations. The selected candidate will be responsible for booking appointments, transferring calls, and conducting intake calls.",
    WorkSchedule: ["7:30am - 5pm EST or 8 - 5:30pm EST"],
    Tasks: [
      "Book Appointments, Schedule appointments into the system, ensuring accuracy and efficiency.",
      "Transfer Calls, Route incoming calls to the appropriate person or department.",
      "Complete Intake Calls, Conduct intake calls with a focus on delivering excellent customer service and gathering necessary information."
    ],
    TimeAllocated: [
      "Book Appointments: 40%",
      "Transfer Calls: 30%",
      "Complete Intake Calls: 30%",
    ],
    ExperienceAndQualifications: [
      "Sales experience, either outbound or inbound.",
    ],
    PreferredSkillsAndTools: [
      "Proficiency in Microsoft Office suite.",
      "Background or interest in the automotive industry."
    ],
    LanguageRequirements: [
      "Native-level English proficiency."
    ]
  },
  {
    JobTitle: "Recruiter",
    //Virtustant
    id: 84,
    status: "open",
    hourSalary: "up to 28",
    JobType: "Full Time",
    imgSrc: JobIMage19,
    url: "/jobs/jobDescription/84",
    form: "https://forms.monday.com/forms/embed/afd91709c0a7cb08485365d75d512d8a?r=use1",
    JobDescription: "As a Recruiter at Virtustant, you'll be instrumental in linking exceptional international talent with American companies, leveraging your insights into the LATAM market.",
    WorkSchedule: ["Remote (Argentina-based), Full-time (Monday to Friday, 10 am - 6 pm EST)"],
    Tasks: [
      "Interpret and adapt the staffing needs of American companies for the LATAM talent landscape.",
      "Evaluate potential candidates based on their qualifications, experience, and cultural alignment.",
      "Oversee the complete recruitment lifecycle, from sourcing to interviewing to decision facilitation.",
      "Maintain and update a dynamic database of prospective candidates, ensuring regular communication.",
      "Collaborate with internal departments to ensure efficient onboarding of selected candidates.",
      "Stay updated with current industry trends to ensure our recruitment strategies remain relevant.",
      "Share feedback and market insights with the leadership team about LATAM region dynamics.",
    ],
    ExperienceAndQualifications: [
      "Previous experience in recruitment, preferably within an international staffing context.",
      "Profound knowledge of the LATAM job market.",
      "Outstanding interpersonal and communication abilities.",
      "Fluent in both English and Spanish.",
      "Capable of thriving in a high-paced setting and meeting stringent deadlines.",
      "Driven, detail-focused, and collaborative in approach.",
    ],
    AdditionalDetails: [
      "Competitive compensation and benefits.",
      "Engage with a dynamic team in an expansive, growth-focused setting.",
      "Ongoing learning and professional advancement opportunities.",
      "Headquartered in the U.S., Virtustant is a leading international staffing firm focusing on the Canadian and LATAM regions. We excel in recruiting premier remote talent globally. Our unique strengths lie in our diverse multinational applicant database and our ability to fill positions swiftly, often within 1 to 2 weeks. At Virtustant, we seamlessly connect global talent with opportunity."
    ]
  },
  {
    JobTitle: "Graphic Designer",
    //Nick Ilev 
    id: 85,
    status: "open",
    hourSalary: "6 - 13",
    JobType: "Part Time",
    imgSrc: JobIMage17,
    url: "/jobs/jobDescription/85",
    form: "https://forms.monday.com/forms/embed/af47d6a647d065a50500c6bebae9a45b?r=use1",
    JobDescription: "We are seeking a talented Graphic Designer to join our team on a part-time basis. The selected candidate will play a crucial role in creating visually appealing and effective designs for landing pages, ad campaigns, brochures, e-books, PDFs, infographics, and banners. This position requires proficiency in design software, creativity, and a strong attention to detail. If you are a creative and reliable individual with web design experience and a flair for marketing, we want to hear from you.",
    WorkSchedule: ["Part-Time (20 hours per week, possibly more)"],
    Tasks: [
      "Design Landing Pages",
      "Design for Ad Campaigns",
      "Create Brochures",
      "Develop E-Books, PDFs, Infographics, and Banners"
    ],
    ExperienceAndQualifications: [
      "Web design experience, with a strong emphasis on creating effective landing pages.",
    ],
    PreferredSkillsAndTools: [
      "Marketing experience, especially in B2B contexts",
      "Video editing skills",
      "Figma",
      "Adobe Suite"
    ],
    LanguageRequirements: [
      "Minimum B2 (Upper Intermediate) level of English proficiency."
    ]
  },
  {
    JobTitle: "Editor & Writer",
    //Nick Ilev 
    id: 86,
    status: "open",
    hourSalary: "6 - 16",
    JobType: "Part Time",
    imgSrc: JobIMage16,
    url: "/jobs/jobDescription/86",
    form: "https://forms.monday.com/forms/embed/c358951afb37e42d3313ff84450e9d58?r=use1",
    JobDescription: "We are seeking a talented Editor & Writer to join our team. In this role, you will play a key part in refining and enhancing written content, ensuring it meets our high standards for quality. The ideal candidate is a native English speaker with a keen eye for detail, strong writing skills, and a passion for crafting compelling copy. While editing is a significant aspect of this role, we prioritize individuals who can also contribute creatively to content creation.",
    WorkSchedule: ["Part-Time (15-20 Hours), Flexible Schedule"],
    Tasks: [
      "Content Refinement, Transform initial drafts into polished, engaging final drafts.",
      "Editing, Review and edit content created by others to improve clarity, grammar, and overall quality.",
      "Content Creation, Contribute to content creation efforts, adding your creative flair to marketing copy."
    ],
    ExperienceAndQualifications: [
      "Previous experience in editing and proofreading marketing copy.",
    ],
    PreferredSkillsAndTools: [
      "Experience in B2B content.",
      "Proficiency with Grammarly is a bonus.",
      "Familiarity with Fintech or Health care industries."
    ],
    Skills: [
      "Organized",
      "Detail-oriented",
      "Strong interpersonal skills",
      "Process-driven mindset"
    ],
  },
  {
    JobTitle: "Remote Personal Trainer",
    //System2
    id: 87,
    status: "open",
    hourSalary: "6 - 8",
    JobType: "Full Time",
    imgSrc: JobIMage9,
    url: "/jobs/jobDescription/87",
    form: "https://forms.monday.com/forms/embed/7c71bc8aef19368b6509905e6f9e9cd8?r=use1",
    JobDescription: "As a Remote Personal Trainer, you will play a crucial role in supporting elite fitness influencers, helping clients reach their fitness goals, and promoting our vision of making fitness coaching accessible to everyone.",
    Tasks: [
      "Collaborate with Elite Influencers: Work closely with elite fitness influencers to understand and align with their coaching philosophy.",
      "Design Customized Workout Programs: Utilize the provided tools to design tailored workout programs that meet the individual needs and goals of clients.",
      "Client Engagement, Regularly check in with remote clients, providing motivation, guidance, and ensuring the highest quality of service.",
      "Client Support, Identify clients who require additional assistance and go the extra mile to help them achieve their fitness objectives.",
      "Social Media Outreach, Assist with various social media outreach tasks to promote fitness coaching in collaboration with influencers."
    ],
    ExperienceAndQualifications: [
      "Bachelor's degree in exercise science or a related field.",
      "Exceptional attention to detail.",
      "Dedication to client satisfaction and a commitment to continuous professional growth as a coach.",
      "Proficiency in promoting fitness on social media.",
    ],
  },
  {
    JobTitle: "Virtual Assistant",
    //System2
    id: 88,
    status: "open",
    hourSalary: "6 - 7",
    JobType: "Full Time",
    imgSrc: JobIMage13,
    url: "/jobs/jobDescription/88",
    form: "https://forms.monday.com/forms/embed/bededac4fdb4cbbafa2e24ec3fed0e48?r=use1",
    JobDescription: "We are seeking a Virtual Assistant to join our team with a strong background in insurance, legal, or medical fields. In this role, you will be responsible for assisting clients with their insurance claims, ensuring the efficient management of legal documents, and coordinating necessary medical treatments.",
    WorkSchedule: ["Monday to Friday, 8:30 AM to 5:30 PM CST"],
    Tasks: [
      "Police Report Management.",
      "Check for the availability of police reports.",
      "Order police reports when necessary.",
      "Defendant Information Research.",
      "Conduct research on 3rd party sites to gather defendant information, particularly related to insurance.",
      "Property Damage and Injury Documentation.",
      "Obtain photos of property damage and injury records if not already in the client's file.",
      "Discuss property damage details with the client.",
      "Insurance Claims Handling.",
      "Open and manage claims for both 1st and 3rd party insurance.",
      "Treatment Coordination.",
      "Ensure that the client has been scheduled for necessary medical treatment.",
      "Follow up with the progress of the client's treatment."
    ],
    ExperienceAndQualifications: [
      "Prior experience in roles related to insurance, such as insurance sales, insurance adjusting, law firms, or medical insurance.",
    ],
    LanguageRequirements: [
      "Minimum English Proficiency, C2 (Fully Bilingual)"
    ],
  }
  
  
]

export default CurrentPositions;