import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import defaultCardImage from "../../images/shield-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

// services
import customer from "../../assets/images/services/customer.png";
import data from "../../assets/images/services/data.png";
import digital from "../../assets/images/services/digital.png";
import everything from "../../assets/images/services/everything.png";
import graphic from "../../assets/images/services/graphic.png";
import media from "../../assets/images/services/media.png";
import operation from "../../assets/images/services/operation.png";
import sales from "../../assets/images/services/sales.png";
import socialmedia from "../../assets/images/services/socialmedia.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;



const Card = styled.div`
  ${tw`flex flex-col mx-auto hover:bg-primary-500 ease-in-out duration-300 hover:scale-125 hover:border-none max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-900 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-900 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-12 h-12`}
    }
  }
  &:hover {
    .title {
      ${tw`text-white`}
    }
    .description {
      ${tw`text-white`}
    }
    .imageContainer {
      ${tw` bg-white`}
    }
  }

  .textContainer {
     ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: sales,
      title: "Sales Representative",
      description:
        "Expert sales professional delivers exceptional customer service, boosts revenue through proven techniques and builds lasting relationships for business growth.",
    },
    {
      imageSrc: digital,
      title: "Account Manager for Digital Marketing Agencies",
      description:
        "Experienced account managers develop and implement effective marketing strategies that drive results for digital marketing agencies.",
    },
    {
      imageSrc: socialmedia,
      title: "Social Media Manager",
      description:
        "Customized social media solutions to build brand, engage audience, and drive conversions with compelling content by experienced management talent.",
    },
    {
      imageSrc: data,
      title: "Data Entry Specialist",
      description:
        "Highly trained data entry specialists handle large volumes with precision, offering customized solutions including cleansing, enrichment, and migration.",
    },
    {
      imageSrc: graphic,
      title: "Graphic Designer",
      description:
        "Experienced graphic designers create custom designs to bring your vision to life, from logos and branding to print and digital materials.",
    },
    {
      imageSrc: media,
      title: "Media Buyer",
      description:
        "Expert media buying team maximizes ad budget impact through strategic planning and buying across various channels with measurable results.",
    },
    {
      imageSrc: customer,
      title: "Customer Service Representative",
      description:
        "Dedicated customer service team provides exceptional support, handling all inquiries and feedback promptly and professionally to boost loyalty and retention.",
    },
    {
      imageSrc: operation,
      title: "Operations Manager",
      description:
        "Operations management team streamlines processes and enhances productivity with tailored solutions, including supply chain and inventory management to reduce costs.",
    },
    {
      imageSrc: everything,
      title: "Everything Else",
      description:
        "We do other things too, those ones were just the most common! Feel free to ask.",
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          Our Professional<i className="fa-regular fa-person-sign"></i>{" "}
          <span tw="text-primary-500">Services</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card className="group">
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
