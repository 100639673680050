import React from "react";
import { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import Options from "./PositionsInfo"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-gray-600 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-6xl font-bold text-white`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-primary-300 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-100`}
    }
  }
`;


const InputContainer = tw.div`relative py-5 mt-6 mr-4`;
const Label = tw.label`tracking-wide font-semibold text-lg`;
const Input = tw.input`border border-2 border-white p-2 rounded`;
const Select = tw.select`border border-2 border-white p-2 rounded`
const FormDiv = tw.div`flex flex-wrap sm:flex-nowrap`

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

const Optioninput = tw.option`bg-white text-primary-400`


export default () => {

  const [selectedPosition, setSelectedPostion] = useState("")
  const handleChange = event => {
    setSelectedPostion(event.target.value);
  };
  const [hiringNumber, setHiringNumber] = useState(0)
  const handleNumber = event => {
    setHiringNumber(event.target.value);
  };

  let position = Options.filter((option) => 
    option.name === selectedPosition
  )

  return (
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Calculate your savings!</h2>
                  <InputContainer>
                    <Label htmlFor="name-input">Looking to Hire</Label>
                    <Select value={selectedPosition} onChange={handleChange}>
                      <Optioninput>Select a postion</Optioninput>
                      {Options.map((option) => (
                        <Optioninput> {option.name} </Optioninput>
                      ))}
                    </Select>
                  </InputContainer>
                  {console.log(position[0])}
                  <FormDiv>
                  <InputContainer>
                    <Label htmlFor="email-input">Hourly Cost Through Virtustant</Label>
                    <Input id="email-input" type="text" name="email" value={position.length > 0? "$" + position[0].HourlyCostVirtustant: ""}/>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Average Hourly Rate in the US </Label>
                    <Input id="email-input" type="text" name="email" value={position.length > 0? "$" + position[0].HourlyRateUS: ""}/>
                  </InputContainer>
                  </FormDiv>
                  <InputContainer>
                    <Label htmlFor="email-input">Number of Full Time Staff You Want to Hire</Label>
                    <Input type="number" value={hiringNumber} onChange={handleNumber} min={0}/>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Estimated Monthly Cost Using Virtustant </Label>
                    <Input id="email-input" type="text" name="email" value={position.length > 0 && hiringNumber !== 0? "$" + (((((position[0].HourlyCostVirtustant)*40)*hiringNumber)*30/7)).toFixed(2) : "" }/>
                  </InputContainer>
                  <FormDiv>
                  <InputContainer>
                    <Label htmlFor="email-input">Savings Per Hour</Label>
                    <Input id="email-input" type="text" name="email" value={position.length > 0 && hiringNumber !== 0? "$" + (((position[0].HourlyRateUS)*hiringNumber - (position[0].HourlyCostVirtustant)*hiringNumber)).toFixed(2) : ""}/>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Savings Per Week </Label>
                    <Input id="email-input" type="text" name="email" value={position.length > 0 && hiringNumber !== 0? "$" +(((position[0].HourlyRateUS)*hiringNumber - (position[0].HourlyCostVirtustant)*hiringNumber)*40).toFixed(2): ""}/>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Savings Per Month</Label>
                    <Input id="email-input" type="text" name="email" value={position.length > 0 && hiringNumber !== 0? "$" +((((position[0].HourlyRateUS)*hiringNumber - (position[0].HourlyCostVirtustant)*hiringNumber)*40)*30/7).toFixed(2) : ""}/>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Savings Per Year</Label>
                    <Input id="email-input" type="text" name="email" value={position.length > 0 && hiringNumber !== 0? "$" +(((((position[0].HourlyRateUS)*hiringNumber - (position[0].HourlyCostVirtustant)*hiringNumber)*40)*30/7)*12).toFixed(2) : ""}/>
                  </InputContainer>
                  </FormDiv>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
