import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Subheading as ImportedSubHeading} from "components/misc/Headings.js";
import { PrimaryButton as ImportedButton} from "components/misc/Buttons.js";
import ThankYouImage from "assets/images/thank-you/ThankYouImage.png"

//Google Tag Manager
const GoogleTag = tw.iframe`hidden h-0 w-0`

const Container = styled.div`
  ${tw`relative -mx-8  bg-center bg-cover h-screen min-h-144`}
`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-secondary-900 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const Subheading= tw(ImportedSubHeading)`text-lg sm:text-xl md:text-2xl lg:text-4xl text-secondary-900 mr-2 text-center`;
const Span = tw.text`text-primary-500`
const Text = tw.p`text-gray-700 mt-3 opacity-75`
const ImgDiv = styled.img(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`bg-contain bg-no-repeat bg-center h-64 w-64 md:h-96 md:w-96 rounded rounded-b-none rounded mt-10`
  ]);
const Button = tw(ImportedButton)`mt-2 text-white`;

const HomeLink= tw.a``;

export default ({link}) => {

  const links = [<HomeLink href="/">Back Home</HomeLink>];
  link = link || links
  return (
    <Container>
        <HeroContainer>
          <Content>
            <Heading>
            Thanks! You are all set.
            </Heading>
            <Subheading>
                One of our advisors will contact <br/> you in <Span>less than 24 hours</Span>
            </Subheading>
            <Text>(remember to check spam)</Text>
            <ImgDiv src={ThankYouImage} />
            <Button>
                <HomeLink href="/">Back Home</HomeLink>
            </Button>
          </Content>
        </HeroContainer>
        <GoogleTag src="https://www.googletagmanager.com/ns.html?id=GTM-TM59XZLC"/>
    </Container>
  );
};
