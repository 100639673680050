import React from "react";
import Header from "../components/headers/Header.js";
import Referrals from "components/referrals-program/ReferralsStructure.js"
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from 'react';
import { CurrentPage } from '../components/context/CurentPage.js';

import { PopupModal } from "react-calendly";

export default () => {

  const [referralOpenCalendly, setReferralOpenCalendly] = useState(false);
  const [url, setUrl] = useState("");


  const {currentPage, setCurrentPage} = useContext(CurrentPage);

  useEffect(() => {
    setCurrentPage("Referrals");
    console.log(currentPage);  
  });

  return (
    <>
      <Header roundedHeaderButton={true} />
      <Referrals setReferralOpenCalendly={setReferralOpenCalendly} setUrl={setUrl}/>
      <PopupModal
        open={referralOpenCalendly}
        onModalClose={() => setReferralOpenCalendly(false)}
        rootElement={document.getElementById("root")}
        url={url}
      ></PopupModal>
    </>
  );
};