import React from "react";
import tw from "twin.macro";
import Header from "../../components/headers/Header";

import { useState, useEffect } from "react";
import { PopupModal } from "react-calendly";
import GetStarted from "components/Home/GetStarted";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import image from "assets/images/blogs/Remote-Employee-Retainment.png";
import cakeImage from "assets/images/blogs/cakeM&WEmployeeRetainment1.png";
import ageGroup from "assets/images/blogs/ageGroupEmployeeRetainment.png";
import mariela from "../../assets/images/about-us/mariela.png";

const Container = tw.div` relative my-8 lg:my-10 -mx-8 `;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 bg-gray-100 shadow border-primary-900`;
const HeadingContainer = tw.div`w-full`;
const Heading = tw.h1`text-gray-800 text-left lg:px-16 px-8 py-2 text-4xl lg:text-5xl`;
const Row = tw.div`px-8 py-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col text-center`;
const Image = tw.img`max-w-full bg-gray-lightest w-auto rounded-t sm:rounded relative z-20 place-items-center`;
const Intro = tw.h2`text-gray-800 text-left lg:px-16 px-8 text-2xl py-2`;
const Content = tw.p`mt-6 text-gray-900 text-left lg:px-16 px-8 py-2`;
const ImageDIv = tw.div`flex flex-nowrap md:mt-10 md:ml-10 md:mb-10 mt-3`
const TextDiv = tw.div`flex flex-wrap`
const Text100 = tw.b`w-full`


export default function BlogAiVsPeople() {

  const [openCalendly, setOpenCalendly] = useState(false);
  const [url, setUrl] = useState("");
  
  return (
    <>
    <Header roundedHeaderButton={true} />
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Heading>Which options can generate remote employee retainment?</Heading>

          <ImageDIv>
          <Image
              style={{ maxHeight: "50px", backgroundColor: "transparent", borderRadius: "200px", marginLeft:"30px",mfloat: "left", marginRight:"15px", float: "left",}}
              src={mariela}
              alt="author of unlock remote employee retainment"
          />
          <TextDiv>
          <Text100>Written by Mariela Oreskovic</Text100> 
          <br />
          <b>02/13/2024</b>
          </TextDiv>
          </ImageDIv>

          <Row>
            <Image
              style={{ maxHeight: "350px", backgroundColor: "transparent" }}
              src={image}
              alt="unlock remote employee retainment"
            />
          </Row>
        </HeadingContainer>
        <Intro>
          When companies roll out the red carpet of remote employment opportunities, workers are rolling out their yoga mats with all the time they save by not being in traffic. A staggering number of people are jumping on board.
          <br />
          <br />
          16% of companies globally are fully remote, while 63% offer hybrid work options. 
            <b><a href="https://www.demandsage.com/remote-work-statistics/#:~:text=Remote%20Work%202024%3A%20Top%20Picks,work%20in%20a%20hybrid%20model.)"> (source)</a></b>
          
        </Intro>

        <Content>
          <p>
            So, <b>which</b> are the best practices to get them to stay? We may have heard of the trend of job hopping, both in the office and remotely. It’s not the friendliest situation for your HR department or the company culture. Not to mention the cost.
          </p>
          <br />
          <p>Here are some ways to <b>retain your remote hires:</b></p>
          <br />
          <b>1. Fixing disparities</b>
          <br />
          <br />
          <p>
            61% Of the Men And 52% Of The Women Are Being Offered Remote Working Opportunities
            However, women have a higher preference for remote work, with 46% of women looking for a remote job. On the other hand, only 39% of the men prefer to work from home.
            Only 1 in 10 women prefer to work on-site.
            <b><a href="https://www.demandsage.com/remote-work-statistics/#:~:text=Remote%20Work%202024%3A%20Top%20Picks,work%20in%20a%20hybrid%20model.)">(source)</a></b>
          </p>
          <br />
          <Row>
            <Image
              style={{ maxHeight: "350px", backgroundColor: "transparent", }}
              src={cakeImage}
              alt="image"
            />
          </Row>
          <br />
          <p>When receiving applications from women looking for remote work, special care can be taken to open doors. For example, at Virtustant we use cognitive skill tests to make sure our candidates have the appropriate level of comprehension to work in a team carrying out complex tasks, but also it shows us where a candidate has strengths that might not come up on a resume. This can create an opportunity for them to take on a role that they might not have previously considered. Combined with being able to work from home, these women will certainly do their best to retain the positions and truly shine. </p>
          <br />

          <b>2. Older employees prefer to work from home.</b>
          <br />
          <br />
          <p>
            This sector of the workforce comes with experience, know-how, and often a deep disdain for water cooler gossip. They’ve been there and done that, truly just desiring to focus on their work. Many have established relationships, whereas the younger crowd can profit from face-to-face encounters, as they should.
            <Row>
            <Image
              style={{ maxHeight: "350px", backgroundColor: "transparent", class: "center" }}
              src={ageGroup}
              alt="image"
              />
              </Row>
            A company seeking to retain remote employees will hire older remote employees when possible.
          </p>

          <br />

          <b>3. Be the light at the end of the tunnel</b>
          <br />
          <br />
          <b>59% Of Workers Are Likelier To Choose An Employer That Allows Remote Work</b>
          <p>74% of the workers said that remote work opportunities make them less likely to leave a company. This can motivate them to work through difficulties and even retrain in order to stay, rather than job hop.
            Offering remote work opportunities enhances an employer’s brand and can attract top talents. Being at the cutting edge of the future of work can tip the scales in your favour.

            <b><a href="https://www.scienceofpeople.com/remote-work-statistics/">(source)</a></b>
          </p>
          <br />

          <b>4. Improved mental health</b>
          <br />
          <br />
          <p>Neurodivergent people have always existed, thankfully, because they are the people who move the world forward in terms of innovation. They famously work better in calm and often silent environments. Being in offices can lead to overstimulation, anxiety or depression, which do not pair well with productivity and health.
            <br />
            <br />
            Thankfully, in their own homes, remote employees are free to create the most comfortable environment for working. Some companies even provide a stipend to encourage this accommodation. The happier and more comfortable a remote employee is, the less desire they will have to seek out greener grass.
            <br />
            <br />
            “One useful way to support neurodiverse workers is through remote working. Thirty-four percent of neurodiverse workers say that <b><a href="https://news.virginmediao2.co.uk/archive/employers-urged-to-provide-support-for-neurodiverse-colleagues/">there are less distractions</a></b> when working from home (WFH), according to research conducted by O2.”
            <b><a href="https://www.euronews.com/next/2022/12/01/noisy-workplace-and-better-focus-how-can-neurodiverse-employees-benefit-from-working-remot">(source)</a></b>
            <br />
            <br />
            As diversity quotas include more kinds of people, hiring the neurodiverse can create a relationship of trust and long-term stability which in turn leads to excellent productivity.
          </p>
          <br />
          <br />
        </Content>
      </ContentWithPaddingXl>
      <GetStarted setOpenCalendly={setOpenCalendly} setUrl={setUrl}/>
      <PopupModal
        open={openCalendly}
        onModalClose={() => setOpenCalendly(false)}
        rootElement={document.getElementById("root")}
        url={url}
      ></PopupModal>
    </Container>
    </>
  );
}
