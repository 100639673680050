import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import Header from "../headers/Header.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import DesignIllustration from "assets/images/Landing.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-8 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl text-left`;
const Paragraph = tw.p`mt-5 text-xl font-medium text-gray-600 max-w-lg lg:mx-0 text-left`;
const Span = tw.text`text-primary-500 font-semibold`

//features 
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

//Google Tag Manager
const GoogleTag = tw.iframe`hidden h-0 w-0`

//trustpilot
const TrustBox = tw.div`hidden sm:flex mt-3 sm:justify-center sm:items-center ml-8`;

const Actions = tw.div`flex sm:flex-row lg:justify-start mt-8 flex-wrap sm:flex-nowrap`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-900 focus:shadow-outline focus:outline-none transition duration-300`;
const AplicantsPrimaryButton = tw.a`ml-5 font-bold px-8 lg:px-10 py-3 rounded bg-gray-500 text-gray-100 hocus:bg-gray-600 focus:shadow-outline focus:outline-none transition duration-300`;
const ButtonDiv = tw.div`flex flex-wrap`;
const ButtonText = tw.p`text-lg text-justify font-medium text-secondary-600 mb-3 w-full`

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

export default function NewHero(props) {
  const heading = "Hire Top Remote Latin American Talent And US Ex-Pats "
  const description="We help you find top talent you never knew existed for as low as $7 per hour. Hop on a <Span>free consultation</Span> and discover how we can help you"
  const primaryButtonText="Hire Talent"
  const aplicantsPrimaryButton="Find a Job"
  const primaryButtonUrl="#"
  const watchVideoYoutubeUrl="https://www.youtube.com/embed/_GuOjXYl5ew"
  const imageSrc=DesignIllustration
  const imageCss=null
  const imageDecoratorBlob = false
  const features = ["No service fees", "Hire in less than two weeks", "Satisfaction guaranteed"]

  const {setOpenCalendly} = props;
  const {setUrl} = props;

  const onNext = () => {
    setOpenCalendly(true);
    setUrl(
      `https://calendly.com/d/cpqt-ffk-q2s`
    );
  }

  return (
    <>
      <Header />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Paragraph>Hop on a <Span>Free Consultation</Span> and find top talent you never knew existed for as low as $7 per hour</Paragraph>
            <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
            </FeatureList>
            <Actions>
              <PrimaryButton onClick={onNext}>{primaryButtonText}</PrimaryButton>
              <AplicantsPrimaryButton href="/jobs">{aplicantsPrimaryButton}</AplicantsPrimaryButton>
            </Actions>
            <Actions>
            <TrustBox>
              <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="63fe142a6295ff0e0a72750e" data-style-height="24px" data-style-width="100%" data-theme="light">
              <a href="https://www.trustpilot.com/review/virtustant.com" target="_blank" rel="noreferrer noopener">Trustpilot</a>
              </div>
            </TrustBox>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                css={imageCss}
                src={imageSrc}
                alt="Hero"
              />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
      <GoogleTag src="https://www.googletagmanager.com/ns.html?id=GTM-TM59XZLC"/>
    </>
  );
};