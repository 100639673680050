

const Options = [
    {
        name: "Account Manager",
        HourlyCostVirtustant: 13,
        HourlyRateUS: 29.51
    },
    {
        name: "Admin / Executive Assistant",
        HourlyCostVirtustant: 8,
        HourlyRateUS: 21.66
    },
    {
        name: "Appointment Setters",
        HourlyCostVirtustant: 7,
        HourlyRateUS: 13.65
    },
    {
        name: "Customer Service Representative",
        HourlyCostVirtustant: 7.5,
        HourlyRateUS: 17.33
    },
    {
        name: "Graphic Designer and Video Editor",
        HourlyCostVirtustant: 9,
        HourlyRateUS: 24
    },
    {
        name: "Operations Manager",
        HourlyCostVirtustant: 15,
        HourlyRateUS: 55
    },
    {
        name: "Recruiters",
        HourlyCostVirtustant: 8,
        HourlyRateUS: 24.08
    },
    {
        name: "Sales Representatives",
        HourlyCostVirtustant: 10,
        HourlyRateUS: 36.88
    },
    {
        name: "Social Media Managers",
        HourlyCostVirtustant: 9,
        HourlyRateUS: 21.25
    },
  ]

export default Options;