import React from "react";
import styled from "styled-components";
import tw from "twin.macro";


import Forma1 from "../../images/newimages/Forma1Template.png"
import Forma2 from "../../images/newimages/Forma2Template.png"
import Forma3 from "../../images/newimages/Forma3Template.png"
import Forma4 from "../../images/newimages/Forma4Template.png"

const Container = tw.div`relative bg-gray-200`;

const FourColum = styled.div`
${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;

const Heading = tw.h2`text-2xl sm:text-3xl md:text-5xl tracking-wide text-center`;
const VerticalSpacer = tw.div`mt-10 w-full`
const Column = styled.div`
  ${tw`lg:w-1/2 mt-10 max-w-lg md:max-w-2xl flex flex-wrap`}
`;
const DivCard = tw.div``

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8 bg-white border  rounded-xl shadow-xl`}
  .imageContainer {
    ${tw`text-center rounded-full flex-shrink-0 sm:h-full flex items-center `}
    img {
      ${tw`w-32 h-32`}
    }
  }
  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }
  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }
  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose text-lg`}
  }
 `;

const DesktopText = tw.div``;
const MobileText = tw.div``;

const collapseBreakPointCssMap = {
    sm: {
        mobileText: tw`sm:hidden`,
        desktopText: tw`sm:block`,
        mobileTextContainer: tw`sm:hidden`,
    },
    md: {
        mobileText: tw`md:hidden`,
        desktopText: tw`md:block`,
        mobileTextContainer: tw`md:hidden`,
    },
    lg: {
        mobileText: tw`lg:hidden`,
        desktopText: tw`lg:block`,
        mobileTextContainer: tw`lg:hidden`,
    },
    xl: {
        mobileText: tw`xl:hidden`,
        desktopText: tw`hidden xl:block`,
        mobileTextContainer: tw`xl:hidden`,
    },
};


export default function CommonDescription(props) {
    const collapseBreakpointClass = "xl"

    const { setDialogOpen } = props;

    const heading = "Virtustant walks the line with you"

    //collpase brackpoints
    const collapseBreakpointCss =
        collapseBreakPointCssMap[collapseBreakpointClass];



    const defaultCards = [

        {
            key: 0,
            title: "",
            imageLogo: Forma1,
            description:
                "Pay up to 70% less in salary costs, it’s a win-win for our candidates who avoid emigrating and have a lower cost of living.",
        },

        {
            key: 1,
            imageLogo: Forma2,
            title: "",
            description:
                "We can help you hire in less than 2 weeks. We communicate effectively, so you can get back to business.",
        },
        {
            key: 2,
            imageLogo: Forma3,
            title: "",
            description:
                "Incorporate diversity into your team. Find people with experience outside the box.",
        },
        {
            key: 3,
            imageLogo: Forma4,
            title: "",
            description:
                "Avoid mis-hires. We use cognitive skill tests and language skill tests to ensure capable employees, with credentials and experience.",
        },


    ];
    const cards = defaultCards;



    return (
        <Container>
            <FourColum>
                <Heading>{heading}</Heading>
                <VerticalSpacer />
                {cards.map((card, i) => (
                    <Column key={i}>
                        <DivCard>
                            <Card>
                                <span className="imageContainer">
                                    <img src={card.imageLogo} alt="Hire-Remote" style={{margin: "12px",}} />
                                </span>
                                <MobileText css={collapseBreakpointCss.mobileText}>
                                    <p className="description">
                                        "{card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}"
                                    </p>
                                </MobileText>
                            </Card>
                        </DivCard>
                    </Column>
                ))}

            </FourColum>


        </Container>
    );
}
