import React from "react";
import { useRef, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "../../assets/images/newContactUs.png";
import validator from "validator";
import "./Forms.css"


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center  max-w-screen-xl mx-auto pt-5 py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 lg:w-6/12 flex-shrink-0 h-80 md:h-auto md:mb-20`;
const ImageColumnDiv = tw.div``;
const ImageDiv = tw.div``;
const TextColumn = styled(Column)(props => [
  tw`md:w-9/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

//Trust Pilot
const TrustBox = tw.div`flex justify-center text-blue-900 md:mb-10 md:mt-20`;


const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-4/6 hidden lg:block`,
]);
const TextContent = tw.div`text-center md:text-left `;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-0 font-black text-left text-5xl sm:text-5xl md:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 mb-8 text-center md:text-left text-lg md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-20 md:mt-10 text-xl flex flex-col max-w-sm md:max-w-lg mx-auto border-2 border-transparent rounded-xl p-6 bg-gray-100 shadow-2xl`
const Input = tw.input`first:mt-0 border-4 p-2 focus:outline-none font-medium rounded-md`
const Label = tw.label`text-left tracking-wide font-semibold text-lg text-blue-900 mt-4 mb-2`;
const Select = tw.select`border border-4 p-2 rounded-md`
const Optioninput = tw.option`bg-white`
const Span = tw.span`text-left text-sm text-red-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "",
  heading = <>Hire Top <span tw="text-primary-500">Latin American</span><wbr/> Talent and <span tw="text-primary-500">US</span> Ex-pats.</>,
  description = "We help you find top talent you never knew existed for as low as $7 per hour",
  submitButtonText = "Let's Talk",
  formAction = "#",
  formMethod = "get",
  textOnLeft = false,

  //Calendly props
  setOpenCalendly,
  setUrl,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

const CompanySizes = [
  {size: "1 - 10"},
  {size: "11 - 50"},
  {size: "51 - 250"},
  {size: "251 - 1k"},
  {size: "1k +"},
]
const Services = [
  {name: "Administration"},
  {name: "Sales"},
  {name: "Marketing"},
  {name: "Customer Service"},
  {name: "Finance"},
  {name: "Human Resources"},
  {name: "Engineering"},
  {name: "Project Management"},
  {name: "Legal"},
  {name: "Supply Chain and Logistics"},
  {name: "Operations"},
  {name: "Graphic Design"},
  {name: "Real Estate"},
  {name: "Media and Communications"},
  {name: "Data Analysis"},
]

// alfabetic order
Services.sort((x, y) => x.name.localeCompare(y.name));

    //form
    const form = useRef();
    const [fullname, setFullName] = useState("");
    const [companyemail, setCompanyEmail] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [companyname, setCompanyName] = useState("");
    const [companysize, setCompanySize] = useState("");
    const [service, setService] = useState("");

    //validation state
    const [errorfullname, setErrorFullName] = useState("");
    const [errorcompanyemail, setErrorCompanyEmail] = useState("");
    const [errorphonenumber, setErrorPhoneNumber] = useState("");
    const [errorcompanyname, setErrorCompanyName] = useState("");
    const [errorcompanysize, setErrorCompanySize] = useState("");
    const [errorservice, setErrorService] = useState("");

      //snackbar
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setOpen(false);
    };

    //blur validations
    function validateFullName() {
      if (fullname === "") {
        setErrorFullName("Full name missing")
      } else {
        setErrorFullName("")
      }
    }
    function validateCompanyEmail() {
      if (companyemail === "") {
        setErrorCompanyEmail("Company Email missing")
      } else if (!validator.isEmail(companyemail)) {
       setErrorCompanyEmail("Enter a valid email address")
      } else {
       setErrorCompanyEmail("")
      }
    }
    function validatePhoneNumber() {
      if (phonenumber === "") {
        setErrorPhoneNumber("Phone number missing")
      } else {
        setErrorPhoneNumber("")
      }
    }
    function validateCompanyName() {
      if (companyname === "") {
        setErrorCompanyName("Company name missing")
      } else {
        setErrorCompanyName("")
      }
    }
    function validateCompanySize() {
      if (companysize === "") {
        setErrorCompanySize("Please select an option from the dropdown menu")
      } else {
        setErrorCompanySize("")
      }
    }
    function validateService() {
      if (service === "") {
        setErrorService("Please select an option from the dropdown menu")
      } else {
        setErrorService("")
      }
    }

    //validations
    const sendEmail = async (e) => {
      e.preventDefault();

      if (fullname === "") {
        setErrorFullName("Full name missing")
        return;
      }
      if (!validator.isEmail(companyemail)) {
        setErrorCompanyEmail("Enter a valid email address")
        return;
      }
      if (phonenumber === "") {
        setErrorPhoneNumber("Phone number missing")
        return;
      }
      if (companyname === "") {
        setErrorCompanyName("Company name missing")
        return;
      }
      if (companysize === "") {
        setErrorCompanySize("Please select an option from the dropdown menu")
        return;
      }
      if (service === "") {
        setErrorService("Please select an option from the dropdown menu")
        return;
      }

      const scriptURL = 'https://script.google.com/macros/s/AKfycbz0mtrgu2Fm-UZhI2M9yE2HFrkJ3KubAVZR98ndSfZ3MmVaE1JjAkMm-O7Yq4AEE7yk_Q/exec'

      const form = document.forms['google-sheet']

        fetch(scriptURL, { method: 'POST', body: new FormData(form)})
          .then(response => console.error("You have successfully submitted."))
          .catch(error => console.error('Error!', error.message))

          
      setOpenCalendly(true);
      setUrl(
        `https://calendly.com/virtustantllc/virtustant-discovery-call-webpage?`
      );

      setFullName("");
      setCompanyEmail("");
      setPhoneNumber("");
      setCompanyName("");
      setCompanySize("");
      setService("");
    };
  

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
        <TrustBox>
            <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="63fe142a6295ff0e0a72750e" data-style-height="24px" data-style-width="100%" data-theme="light">
            <a href="https://www.trustpilot.com/review/virtustant.com" target="_blank" rel="noreferrer noopener">Trustpilot</a>
            </div>
        </TrustBox>
          <ImageColumnDiv>
            {subheading && <Subheading>{subheading}</Subheading>}
                <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
        </ImageColumnDiv>
       
          <Image imageSrc={EmailIllustrationSrc} />
       
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Form ref={form} method="post" name="google-sheet">
            <Label htmlFor="name-input">Full Name*</Label>
              <Input onBlur={validateFullName} onChange={(e) => setFullName(e.target.value)} 
              type="text" 
              name="fullname"
              value={fullname}
              className={errorfullname? "Invalid": ""}
              />
              <Span>{errorfullname}</Span>
              <Label>Your Company Email*</Label>
              <Input onBlur={validateCompanyEmail} onChange={(e) => setCompanyEmail(e.target.value)} 
              type="email"
              name="companyemail"
              value={companyemail}
              className={errorcompanyemail? "Invalid": ""}
              />
              <Span>{errorcompanyemail}</Span>
              <Label htmlFor="name-input">Phone Number*</Label>
              <Input onBlur={validatePhoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} 
              type="tel" 
              name="phonenumber"  
              value={phonenumber}
              className={errorphonenumber? "Invalid": ""}
              />
              <Span>{errorphonenumber}</Span>
              <Label htmlFor="name-input">Company Name*</Label>
              <Input onBlur={validateCompanyName} onChange={(e) => setCompanyName(e.target.value)} 
              type="text" 
              name="companyname" 
              value={companyname}
              className={errorcompanyname? "Invalid": ""}
              />
              <Span>{errorcompanyname}</Span>
              <Label htmlFor="name-input">Company Employee Size*</Label>
              <Select onBlur={validateCompanySize} onChange={(e) => setCompanySize(e.target.value)} 
              type="text" 
              name="companysize"
              value={companysize}
              className={errorcompanysize? "Invalid": ""}
              >
              <Optioninput></Optioninput>
                  {CompanySizes.map((company) => (
                    <Optioninput> {company.size} </Optioninput>
                  ))}
              </Select>
              <Span>{errorcompanysize}</Span>
              <Label htmlFor="name-input">What service are you looking to hire?*</Label>
              <Select onBlur={validateService} onChange={(e) => setService(e.target.value)} 
              type="text" 
              name="service"
              value={service}
              className={errorservice? "Invalid": ""}
              >
              <Optioninput></Optioninput>
                  {Services.map((service) => (
                    <Optioninput> {service.name} </Optioninput>
                  ))}
              </Select>
              <Span>{errorservice}</Span>
              <SubmitButton type="submit"onClick={sendEmail}>{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};