import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import Forma1 from "../../images/newimages/Forma1Template.png"
import Forma2 from "../../images/newimages/Forma2Template.png"
import Forma3 from "../../images/newimages/Forma3Template.png"
import Forma4 from "../../images/newimages/Forma4Template.png"

const Container = tw.div`relative `;

const Card = styled.div((props) => [
  tw`mt-24 flex flex-wrap md:flex-wrap justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);


const Details = tw.div`text-center md:text-left mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900 text-center flex-wrap`;
const Description = tw.p`mt-2 text-lg text-center flex-wrap `;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto `;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-8 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const ColumImage = tw.div` flex grid-cols-4 lg:flex-row md:items-stretch max-w-screen-xl mx-auto`
const Divider = tw.div` relative lg:flex-row  max-w-screen-xl mx-auto `

const ThreeColmn = tw.div`flex grid-cols-3 lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`

const FourColum = tw.div`flex grid-cols-4 lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;

export default function CommonDescription(props) {
  const { setDialogOpen } = props;
  const defaultCards = [
    {
      key: 0,
      title: "Keep your capital liquid, reducing salary budgeting while keeping quality high. ",
      description:
        "We serve 100+ companies with our unique pool of vetted talent that can diversify and help grow your systems. Reduce expenses while gaining profits, with the help of tried and tested remote employees.",

    },

    {
      key: 1,
      title: "",
      imageLogo: "Forma1",
      description:
        "Pay up to 70% less in salary costs, it’s a win-win for our candidates who avoid emigrating and have a lower cost of living.",
    },

    {
      key: 2,
      imageLogo: "Forma2",
      title: "",
      description:
        "We can help you hire in less than 2 weeks. We communicate effectively, so you can get back to business.",
    },
    {
      key: 3,
      imageLogo: "Forma3",
      title: "",
      description:
        "Incorporate diversity into your team. Find people with experience outside the box.",
    },
    {
      key: 4,
      imageLogo: "Forma4",
      title: "",
      description:
        "Avoid mis-hires. We use cognitive skill tests and language skill tests to ensure capable employees, with credentials and experience.",
    },
    {
      key: 5,
      imageLogo: "",
      title: "Discovery call",
      description:
        "In 30 minutes we ask the right questions and actively listen to what you require, and find the best range of talent to offer.",
    }, {
      key: 6,
      imageLogo: "",
      title: "Recruitment tracker",
      description:
        "Our skilled recruitment consultants gather resumes, recorded interviews and the results of the skills assessments that they believe will work best for you. You can choose who you’d like to interview by having that information at your fingertips.",
    }, {
      key: 7,
      imageLogo: "",
      title: "Your chosen candidate interviews",
      description:
        "We schedule time to meet face to face with the people you feel will fit with your team and requirements. Once you’re satisfied, the candidate signs with us, we set up payroll for you, and onboarding begins.",
    },

  ];

  const cards = defaultCards;



  return (
    <Container>


      <TwoColumn>
        <LeftColumn><Details><Title>{cards[0].title}</Title></Details></LeftColumn>
        <RightColumn><Description>{cards[0].description}</Description></RightColumn>
      </TwoColumn>


      <FourColum>
        <Container>
          <Card><Title> Virtustant walks the line with you </Title></Card>
          <ColumImage>
            <Divider>
              <img
                style={{ maxHeight: "50px", margin: "8px" }}
                src={Forma1} alt="Hire-Remote" />
            </Divider>

            <Divider>
              <img
                style={{ maxHeight: "50px", margin: "8px" }}
                src={Forma2} alt="Hire-Remote" />
            </Divider>

            <Divider>
              <img
                style={{ maxHeight: "50px", margin: "8px" }}
                src={Forma3} alt="Hire-Remote" />
            </Divider>

            <Divider>
              <img
                style={{ maxHeight: "50px", margin: "8px" }}
                src={Forma4} alt="Hire-Remote" />
            </Divider>



          </ColumImage>

          <Card>
            <img
              style={{ maxHeight: "50px", float: "left", margin: "8px" }}
              src={Forma1} alt="Hire-Remote" />
            <Details>
              <Description>{cards[1].description}</Description>
            </Details></Card>

          <Card>
            <img
              style={{ maxHeight: "50px", float: "left", margin: "8px" }}
              src={Forma2} alt="Hire-Remote" />
            <Details>
              <Description>{cards[2].description}</Description>
            </Details></Card>

          <Card>
            <img
              style={{ maxHeight: "50px", float: "left", margin: "8px" }}
              src={Forma3} alt="Hire-Remote" />
            <Details>
              <Description>{cards[3].description}</Description>
            </Details></Card>

          <Card>
            <img
              style={{ maxHeight: "50px", float: "left", margin: "8px" }}
              src={Forma4} alt="Hire-Remote" />
            <Details>
              <Description>{cards[4].description}</Description>
            </Details></Card>
        </Container>
      </FourColum>

      <Container>
        <Card><Details><Title> How do we do it? </Title></Details></Card>
        <ThreeColmn>
          <Card>
            <Details><Title>{cards[5].title}</Title></Details>
            <Details><Description>{cards[5].description}</Description></Details>
          </Card>
          <Card>
            <Details><Title>{cards[6].title}</Title></Details>
            <Details><Description>{cards[6].description}</Description></Details>
          </Card>
          <Card>
            <Details><Title>{cards[7].title}</Title></Details>
            <Details><Description>{cards[7].description}</Description></Details>
          </Card>

        </ThreeColmn>
      </Container>


    </Container>
  );
}
