import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import FastRecruitmentIcon from "assets/images/icons/FastRecruitmentIcon.png";
import LowPricesIcon from "assets/images/icons/LowPricesIcon.png";
import PersonalizedSuportIcon from "assets/images/icons/PersonalizedSuportIcon.png";
import LatinAmericanTalentIcon from "assets/images/icons/LatinAmericanTalentIcon.png";

const Container = tw.div`relative bg-gray-200`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

// button
const Actions = tw.div`flex flex-col sm:flex-row justify-center items-center pb-10`;
const PrimaryButton = tw.button`text-lg font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-900 focus:shadow-outline focus:outline-none transition duration-300 max-w-screen-md`;

const DesktopText = tw.div``;
const MobileText = tw.div``;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`lg:w-1/2 mt-10 max-w-lg md:max-w-2xl flex flex-wrap`}
`;
const DivCard = tw.div``

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8 bg-white border  rounded-xl shadow-xl`}
  .imageContainer {
    ${tw`text-center rounded-full flex-shrink-0 sm:h-full flex items-center `}
    img {
      ${tw`w-32 h-32`}
    }
  }
  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }
  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }
  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose text-lg`}
  }
  .author {
    ${tw`text-gray-800`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const collapseBreakPointCssMap = {
  sm: {
    mobileText: tw`sm:hidden`,
    desktopText: tw`sm:block`,
    mobileTextContainer: tw`sm:hidden`,
  },
  md: {
    mobileText: tw`md:hidden`,
    desktopText: tw`md:block`,
    mobileTextContainer: tw`md:hidden`,
  },
  lg: {
    mobileText: tw`lg:hidden`,
    desktopText: tw`lg:block`,
    mobileTextContainer: tw`lg:hidden`,
  },
  xl: {
    mobileText: tw`xl:hidden`,
    desktopText: tw`hidden xl:block`,
    mobileTextContainer: tw`xl:hidden`,
  },
};

export default function WhyUs(props) {
  const collapseBreakpointClass = "xl"
  const heading = "Why Choose Virtustant"
  const subheading = "" 
  const description = "" 


  //collpase brackpoints
  const collapseBreakpointCss =
  collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultCards = [
    {
      imageSrc: FastRecruitmentIcon,
      title: "Secure",
      DesktopDescription: <>Virtustant's recruitment solutions are incredibly <span tw="text-gray-800">fast and responsive</span>, making the hiring process a breeze. Their prompt assistance and seamless collaboration ensure efficiency and effectiveness.</>,
      description:"Virtustant excels in fast, responsive, and collaborative recruitment solutions for maximum efficiency.",
      author: "Sarah H."
    },
    { imageSrc: LowPricesIcon, 
      title: "24/7 Support",
      DesktopDescription: <>Not only are they professional and efficient, but they are also <span tw="text-gray-800">surprisingly affordable</span>. Discovering that Latin American talent comes at a friendly price for any company has been a pleasant surprise.</>,
      description:"Virtustant offers affordable, efficient access to top Latin American talent.",
      author: "Emily W." 
    },
    { imageSrc: LatinAmericanTalentIcon, 
      title: "Customizable",
      DesktopDescription: <>Virtustant's recruitment company has not only found <span tw="text-gray-800">top-notch talent in Latin America</span> but has also managed to fit within our budget. The candidates you presented have exceeded our expectations.</>,
      description:"Virtustant delivers exceptional, budget-friendly talent from Latin America that surpasses expectations.",
      author: "Mark T."
     },
    { imageSrc: PersonalizedSuportIcon, 
      title: "Reliable",
      DesktopDescription: <>I can't speak highly enough about Virtustant's <span tw="text-gray-800">professionalism and understanding</span> throughout our recruitment journey. They possess industry knowledge and offer great support, making the process <span tw="text-gray-800">smooth and enjoyable</span>.</>,
      description:"Virtustant showcases professionalism, industry knowledge, and unwavering support for a smooth recruitment journey.",
      author: "David S."
     },
  ];

  const cards = defaultCards;

  const {setOpenCalendly} = props;
  const {setUrl} = props;

  const onNext = () => {
    setOpenCalendly(true);
    setUrl(
      `https://calendly.com/d/cpqt-ffk-q2s`
    );
  }

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <DivCard>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <DesktopText css={collapseBreakpointCss.desktopText}>
                <p className="description">
                  "{card.DesktopDescription || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}"
                </p>
                <br />
                <p className="author">
                  -{card.author}
                </p>
                </DesktopText>
                <MobileText css={collapseBreakpointCss.mobileText}>
                <p className="description">
                  "{card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}"
                </p>
                </MobileText>
              </span>
            </Card>
            </DivCard>
          </Column>
        ))}
      </ThreeColumnContainer>
      <Actions>
        <PrimaryButton onClick={onNext} >Contact Us</PrimaryButton>
      </Actions>
      <DecoratorBlob />
    </Container>
  );

}  
  