import React from "react";
import tw from "twin.macro";
import { useState } from "react";
import { PrimaryButton as ButtonBase } from "components/misc/Buttons.js";
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, WhatsappShareButton, WhatsappIcon  } from 'react-share';
import CurrentPositions from "./CurrentPositions"
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Header from "../headers/Header";
import { useParams } from 'react-router-dom';

const Container = tw.div` relative my-8 lg:my-10 -mx-8 `;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 bg-gray-100 shadow border-primary-900`;
const HeadingContainer = tw.div`w-full`;
const Heading = tw.h1`text-gray-800 text-left lg:px-16 px-8 py-2 text-4xl lg:text-5xl`;
const Content = tw.p`mt-6 text-gray-900 text-left lg:px-16 px-8 py-2`;
const ApllyHereButton = tw(ButtonBase)`bg-primary-400 hover:bg-yellow-800 mt-8`;
const ShareButton = tw(ButtonBase)`bg-primary-400 hover:bg-yellow-800 mt-8 ml-5`;
const Form = tw.iframe`h-full w-full border-0 shadow-black`
const SocialDiv =tw.div`mt-5`

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BlogAiVsPeople() {

  const params = useParams();
  const paramID = Number(params.id)
  
  const jobID = paramID
  const job = CurrentPositions.filter(CurrentPosition => 
    CurrentPosition.id === jobID
    )
  
  //Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <>
    <Header roundedHeaderButton={true} />
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Heading>{job[0].JobTitle}</Heading>
        </HeadingContainer>
        <Content>
        <b>➡️Position Overview:</b>
        <br/>
        {job[0].JobDescription}
        <br/>
        
        {job[0].CompanyValues && job[0].CompanyValues.length > 0? <><br/><b>Company Values:</b></>: ""}
        {job[0].CompanyValues? job[0].CompanyValues.map((CompanyValue) =>
        <ul>
        <li>{CompanyValue}</li>
        </ul>
        ): null}

        {job[0].Skills && job[0].Skills.length > 0? <><br/><b>🔍Skills:</b></>: ""}
        {job[0].Skills? job[0].Skills.map((Skill) =>
        <ul>
        <li>{Skill}</li>
        </ul>
        ):null}

        {job[0].ExperienceAndQualifications && job[0].ExperienceAndQualifications.length > 0? <><br/><b>🔍Qualifications:</b></>: ""}
        {job[0].ExperienceAndQualifications? job[0].ExperienceAndQualifications.map((Qualification) =>
        <ul>
        <li>{Qualification}</li>
        </ul>
        ): null}

        {job[0].PreferredSkillsAndTools && job[0].PreferredSkillsAndTools.length > 0? <><br/><b>🔍Preferred Skills:</b></>: ""}
        {job[0].PreferredSkillsAndTools? job[0].PreferredSkillsAndTools.map((PreferredSkill) =>
        <ul>
        <li>{PreferredSkill}</li>
        </ul>
        ): null}

        {job[0].Tasks && job[0].Tasks.length > 0? <><br/><b>🚀Tasks:</b></>: ""}
        {job[0].Tasks? job[0].Tasks.map((Task) =>
        <ul>
        <li>{Task}</li>
        </ul>
        ): null}

        {job[0].WorkSchedule && job[0].WorkSchedule.length > 0? <><br/><b>🕚Work Schedule:</b></>: ""}
        {job[0].WorkSchedule? job[0].WorkSchedule.map((Time) =>
        <ul>
        <li>{Time}</li>
        </ul>
        ): null}

        {job[0].TimeAllocated && job[0].TimeAllocated.length > 0? <><br/><b>💻Time Allocated:</b></>: ""}
        {job[0].TimeAllocated? job[0].TimeAllocated.map((Time) =>
        <ul>
        <li>{Time}</li>
        </ul>
        ): null}
        
        {job[0].LanguageRequirements && job[0].LanguageRequirements.length > 0? <><br/><b>💻Language Requirements:</b></>: ""}
        {job[0].LanguageRequirements? job[0].LanguageRequirements.map((Language) =>
        <ul>
        <li>{Language}</li>
        </ul>
        ): null}
        
        {job[0].AdditionalDetails && job[0].AdditionalDetails.length > 0? <><br/><b>Additional Details:</b></>: ""}
        {job[0].AdditionalDetails? job[0].AdditionalDetails.map((Detail) =>
        <ul>
        <li>{Detail}</li>
        </ul>
        ): null}

        
        <ApllyHereButton onClick={handleOpen} >Apply here</ApllyHereButton>
        <SocialDiv>
          <FacebookShareButton
            url={`https://virtustant.com/jobs/jobDescription/${jobID}`}
            quote={`Check out this New Open Position in Virtustant: ${job[0].JobTitle}`}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <LinkedinShareButton
            url={`https://virtustant.com/jobs/jobDescription/${jobID}`}
            title={`Check out this New Open Position in Virtustant: ${job[0].JobTitle}`}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <WhatsappShareButton
            url={`https://virtustant.com/jobs/jobDescription/${jobID}`}
            title={`Check out this New Open Position in Virtustant: ${job[0].JobTitle}`}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </SocialDiv>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
              <Form
                title="Form"
                src={job[0].form}
              />
              </Box>
            </Fade>
        </Modal>
        </Content>
      </ContentWithPaddingXl>
    </Container>
    </>
  );
}
