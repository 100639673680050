import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";


import angelica from "../../assets/images/about-us/angelica.png";
import erik from "../../assets/images/about-us/eriknew.png";
import guillermo from "../../assets/images/about-us/guillermo.png";
import gimena from "../../assets/images/about-us/gimena.png";
import matias from "../../assets/images/about-us/matias.png";
import kevin from "../../assets/images/about-us/kevin.png";
import li from "../../assets/images/about-us/li.png";
import natalia from "../../assets/images/about-us/natalia.png";
import shaiel from "../../assets/images/about-us/shaiel.png";
import roni from "../../assets/images/about-us/roni.png";
import alan from "../../assets/images/about-us/alan.png";
import agustin from "../../assets/images/about-us/agustin.png";
import mercedes from "../../assets/images/about-us/mercedes.png";
import juanpablo from "../../assets/images/about-us/juanpablo.png";
import facundo from "../../assets/images/about-us/facundo.png";
import mariajose from "../../assets/images/about-us/mariajose.png";

import SofiaRousseau from "../../assets/images/about-us/SofiaRousseau.png";
import IgnacioNusimovich from "../../assets/images/about-us/IgnacioNusimovich.png";
import DanGutvay from "../../assets/images/about-us/DanGutvay.png";
import LauraLevitin from "../../assets/images/about-us/LauraLevitin.png";
import LautaroInostrosa from "../../assets/images/about-us/LautaroInostrosa.jpg";
import MicaelaKatzky from "../../assets/images/about-us/MicaelaKatzky.png";
import ilanDorfman from "../../assets/images/about-us/ilanDorfman.png";
import MarcosSansoulet from "../../assets/images/about-us/MarcosSansoulet.png";
import MijalSchnell from "../../assets/images/about-us/MijalSchnell.png";
import MicaelaSachi from "../../assets/images/about-us/MicaelaSachi.png";
import AgustinaCarral from "../../assets/images/about-us/AgustinaCarral.png";
import FabianaLoo from "../../assets/images/about-us/FabianaLoo.png";
import LaraWeinstein from "../../assets/images/about-us/LaraWeinstein.png"

import TobíasWinograd from "../../assets/images/about-us/TobíasWinograd.jpg"
import LuciaBirkganas from "../../assets/images/about-us/LuciaBirkganas.jpg"
import SofiaVictoriaFranco from "../../assets/images/about-us/SofiaVictoriaFranco.jpeg"
import ValentinoRizzi from "../../assets/images/about-us/ValentinoRizzi.jpg"

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mt-16 text-3xl`;
const Description = tw(SectionDescription)`mx-auto text-center`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-6xl mx-auto`;
const Card = tw.div`mt-10 w-full sm:w-1/2 lg:w-1/5 flex flex-col items-center xl:mx-4`;
const CardImage = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`w-48 h-48 xl:w-56 xl:h-56 bg-cover bg-center rounded-full border border-4 border-primary-500`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500 text-center`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`;

export default ({
  heading = "Meet Virtustant's team",
  description = "Our mission is to amaze you with the caliber of our applicants. We are dedicated to sourcing the highest-quality and most cost-effective talent globally. Through our expertise and passion, we have enabled business owners to access dependable talent at price points they never would have thought attainable.",
  areas = [
    "Executive Level", "Marketing Team", "IT", "Recruitment Team", "Finance and Analytics", "HR","Sales",
  ],
  cards = [
    [
      [{
      imageSrc: erik,
      position: "Co-Founder",
      name: "Erik Reid",
      },
      {
        imageSrc: kevin,
        position: "Co-Founder",
        name: "Kevin Wright",
      },]
    ],
    [ 
      [ {
        imageSrc: alan,
        position: "Marketing Operations Lead",
        name: "Alan Schultz",
      },
      {
        imageSrc: natalia,
        position: "Marketing Leader",
        name: "Natalia Graizman",
      },  
      ],
      [{
        imageSrc: shaiel,
        position: "Marketing Manager",
        name: "Shaiel Peregal",
      },
      {
        imageSrc: DanGutvay,
        position: "Email Marketing Specialist",
        name: "Dan Gutvay",
      },
      {
        imageSrc: IgnacioNusimovich,
        position: "Social Media Manager",
        name: "Ignacio Nusimovich",
      },
      {
        imageSrc: LaraWeinstein,
        position: "Graphic Designer",
        name: "Lara Weinstein",
      },
      {
        imageSrc: SofiaVictoriaFranco,
        position: "Email Marketing Specialist",
        name: "Sofia Victoria Franco", 
      },
      {
        imageSrc: ValentinoRizzi,
        position: "Closer",
        name: "Valentino Rizzi", 
      },
      
      
      
    ]
    ],
    [
      [{
        imageSrc: agustin,
        position: "IT Leader", // AKA Maestro Pizzero
        name: "Agustin Sturm",
      },   
      ],
      [{
        imageSrc: guillermo,
        position: "Data Manager",
        name: "Guillermo Tsau",
      },
      
      {
        imageSrc: juanpablo,
        position: "Data Entry",
        name: "Juan Pablo Mendiola",
      },
      {
        imageSrc: TobíasWinograd,
        position: "Data Entry",
        name: "Tobías Winograd",
      },
     
     
      ],
    ],
    [
      [ {
        imageSrc: roni,
        position: "Head of Recruitment Operations",
        name: "Roni Graizman",
      },
        {
          imageSrc: li,
          position: "Recruiter",
          name: "Li Luque",
        },
    
    ],
      [
      {
        imageSrc: facundo,
        position: "Recruiter",
        name: "Facundo Gamero",
      },
     
      {
        imageSrc: gimena,
        position: "Recruiter",
        name: "Gimena Mecca",
      },
      {
        imageSrc: mercedes,
        position: "Recruiter",
        name: "Mercedes Frias",
      },
      {
        imageSrc: LautaroInostrosa,
        position: "Recruiter",
        name: "Lautaro Inostrosa",
      },   
      {
        imageSrc: MarcosSansoulet, 
        position: "Recruiter",
        name: "Marcos Sansoulet",
      },
      {
        imageSrc: MijalSchnell, 
        position: "Recruiter",
        name: "Mijal Schnell",
      },
      {
        imageSrc: MicaelaSachi, 
        position: "Recruiter",
        name: "Micaela Sachi",
      },
      {
        imageSrc: LuciaBirkganas, 
        position: "Recruiter",
        name: "Lucia Birkganas",
      },     
      { 
        imageSrc: FabianaLoo,
        position: "Outsourcing Assistant",
        name: "Fabiana Loo",
      },

      
    ]
    ],
    [
      [{
        imageSrc: matias,
        position: "Accountant",
        name: "Matias Bekerman",
      },
    {
      imageSrc: LauraLevitin,
      position: "Accountant Assistant",
      name: "Laura Levitin",
    }],
    ],
    [
      [
      {
        imageSrc: MicaelaKatzky, 
        position: "HR Manager",
        name: "Micaela Katzky",
      },
      {
        imageSrc: SofiaRousseau,
        position: "HR Assistant",
        name: "Sofia Rousseau",
      },
      {
        imageSrc: ilanDorfman,
        position: "HR Assistant",
        name: "Ilan Dorfman",
      },
    ],
    ],
    [
      [
       {
        imageSrc: mariajose,
        position: "Sales Specialist",
        name: "Maria Jose Arias",
      }, 
      {
        imageSrc: angelica,
        position: "Account Manager",
        name: "Angelica Suarez",
      },
      {
        imageSrc: AgustinaCarral,
        position: "Account Manager",
        name: "Agustina Carral",
      },
    ],
    ],
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        {cards.map((departments, index) => (
          <>
          <Subheading key={index}>{areas[index]}</Subheading>
          {departments.map((department, index) => (
            <Cards>
              {department.map((area, index) => (
                <Card>
                  <CardImage imageSrc={area.imageSrc} />
                  <CardContent>
                    <span className="position">{area.position}</span>
                    <span className="name">{area.name}</span>
                  </CardContent>
                </Card>
              ))} 
            </Cards>
          ))}
          </>
        ))}
      </ContentWithPaddingXl>
    </Container>
  );
};
