import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "components/misc/Headings";

const TrustBox = tw.div`max-w-screen-xl mx-auto pb-10 mb-20`;
const Content = tw.div`max-w-screen-xl mx-auto pt-20 pb-10 lg:pt-24`;

export default () => {

  return (
    <>
      <Content>
         <HeadingTitle>See what our customers say</HeadingTitle>
      </Content>
      <TrustBox>
      <div class="trustpilot-widget" data-locale="en-US" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="63fe142a6295ff0e0a72750e" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="5" data-review-languages="en">
       <a href="https://www.trustpilot.com/review/virtustant.com" target="_blank" rel="noreferrer noopener">Trustpilot</a>
      </div>
      </TrustBox>
    </>
  );
};