import React from "react";
import tw from "twin.macro";
import Header from "../../components/headers/Header";

import { useState, useEffect } from "react";
import { PopupModal } from "react-calendly";
import GetStarted from "components/Home/GetStarted";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import EmployerOfRecordGlobalMarketSizeGrowthRate from "assets/images/blogs/EmployerOfRecordGlobalMarketSize&GrowthRate.jpg";
import LeidenRankingCWTS from "assets/images/blogs/LeidenRankingCWTS.png";
import BenefitsOfDiversityInTheWorkplace from "assets/images/blogs/BenefitsOfDiversityInTheWorkplace.png";
import MonthlyMinimunWageInUSDollars from "assets/images/blogs/MonthlyMinimunWageInUSDollars.png";
import LatinAmericaLoveOfDollars from "assets/images/blogs/LatinAmericaLoveOfDollars.png";
import WhyInternationalRemoteHiring from "assets/images/blogs/WhyInternationalRemoteHiring.jpg";

import mariela from "../../assets/images/about-us/mariela.png";

const Container = tw.div` relative my-8 lg:my-10 -mx-8 `;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 bg-gray-100 shadow border-primary-900`;
const HeadingContainer = tw.div`w-full`;
const Heading = tw.h1`text-gray-800 text-left lg:px-16 px-8 py-2 text-4xl lg:text-5xl`;
const Row = tw.div`px-8 py-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col text-center`;
const Image = tw.img`max-w-full bg-gray-lightest w-auto rounded-t sm:rounded relative z-20 place-items-center`;
const Intro = tw.h2`text-gray-800 text-left lg:px-16 px-8 text-2xl py-2`;
const Content = tw.p`mt-6 text-gray-900 text-left lg:px-16 px-8 py-2`;
const ImageDIv = tw.div`flex flex-nowrap md:mt-10 md:ml-10 md:mb-10 mt-3`
const TextDiv = tw.div`flex flex-wrap`
const Text100 = tw.b`w-full`
const ParaBold = tw.b`font-bold`

export default function BlogWhyInternationalRemoteHiring() {

  const [openCalendly, setOpenCalendly] = useState(false);
  const [url, setUrl] = useState("");
  
  return (
    <>
    <Header roundedHeaderButton={true} />
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Heading>Why international remote hiring can change business for the better</Heading>

          <ImageDIv>
          <Image
              style={{ maxHeight: "50px", backgroundColor: "transparent", borderRadius: "200px", marginLeft:"30px",mfloat: "left", marginRight:"15px", float: "left",}}
              src={mariela}
              alt="author of International Remote Hiring"
          />
          <TextDiv>
          <Text100>Written by Mariela Oreskovic</Text100> 
          <br />
          <b>02/13/2024</b>
          </TextDiv>
          </ImageDIv>

          <Row>
            <Image
              style={{ maxHeight: "450px", backgroundColor: "transparent" }}
              src={WhyInternationalRemoteHiring}
              alt="International Remote Hiring"
            />
          </Row>
        </HeadingContainer>
        <Intro>
        <b>Savings</b>
        <br />
        We are all well aware of the savings involved in commuting, in both time and money. Rent savings are also a huge plus for a company’s overheads. A new trend has begun in which remote workers will accept a pay cut of up to 8% to be able to work from home exclusively, in the local US market. 
          <br />
          What can save a company money exponentially is hiring remotely and internationally. Understanding how the economies of other countries work, and knowing the relationship between the USD and the foreign currency will open your eyes to the possibilities. 
          <br /><p><b><a href="https://www.reuters.com/markets/currencies/argentina-dollar-love-affair-agonizes-over-divorcing-peso-2023-09-05/"> (source)</a></b></p>
           <Row>
            <Image
              style={{ maxHeight: "450px", backgroundColor: "transparent", }}
              src={LatinAmericaLoveOfDollars}
              alt="remote hiring"
            />
          </Row>
            
            <Row>
            <Image
              style={{ maxHeight: "500px", backgroundColor: "transparent", }}
              src={MonthlyMinimunWageInUSDollars}
              alt="save in salary"
            />
          </Row>
        </Intro>
       
        <Content>
        <b>Diversity</b>
        <br />
        <p>
        There is so much to say about diversity. A simple Google search pulls up the following results: 
        </p>
        <Row>
            <Image
              style={{ maxHeight: "350px", backgroundColor: "transparent", }}
              src={BenefitsOfDiversityInTheWorkplace}
              alt="virtual hiring"
            />
          </Row>
          <br />
          <p>When focusing on cultural diversity, the simplest way to put it is that a culturally diverse team is the microcosmic reflection of your macrocosmic customer base and their needs. What one team member can’t perceive, through no fault of their own, another team member sees as obvious, due to having a different cultural perspective. Getting an outside perspective on your culture can dissolve blind spots.</p>
          <br />
          <p>It comes down to asking different questions. It is impossible to drum up metrics on concepts you have no notion exist. Through collaboration and open communication, much can be done to discover new sources of income and troubleshoot tough-to-solve problems. </p>
          <br />
          <p>The Harvard Business Review has written an article on this very subject:</p>
          <br />
          <ParaBold>
          “Emerging research suggests that multicultural individuals have innate abilities that make them exceptional candidates for global leadership -- yet they often pass under the radar of HR. Although companies are hiring more multicultural employees, it is frequently a token gesture to reflect society's growing ethnic diversity, or because they need their language abilities. What's really needed, say the authors, is a greater awareness and appreciation of the beneficial role of culture in the workplace. This article explains how companies might identify and make better use of the invaluable attributes offered by multicultural managers and employees. Drawing on research, interviews and case studies, the authors suggest how cultural intelligence might be developed as a vital managerial competence for the world we live in today.”
            <b><a href="https://store.hbr.org/product/cultural-competence-why-it-matters-and-how-you-can-acquire-it-multicultural-managers/iir145?sku=IIR145-PDF-ENG">(source)</a></b>
          </ParaBold>
          <br />
          <br />
          <b>Hire the Best candidates in the world not just the smartest person in the room.</b>
          <p>Egos aside, the best thing to do for yourself and your team is to hire people who are smarter than you and can do the job better than you could.  </p>
          <br />
          <p>Google’s list for hiring:</p>
          <br />
          <p><b> Do</b> hire people who are smarter and more knowledgeable than you are.</p>
          <p> <b> Don't</b>  hire people you can't learn from or be challenged by. </p>
          <p> <b>Do</b>   hire people who will add value to the product and our culture. </p>
          <p> <b> Don't</b>  hire people who won't contribute well to both. </p>
          <p> <b>Do</b>   hire people who will get things done. </p>
          <p>  <b> Don't</b>  hire people who think only about problems. </p>
          <p>  <b> Do</b>  hire people who are enthusiastic, self-motivated, and passionate. </p>
          <p>  <b> Don't</b>   hire people who just want a job.</p>
         <b><a href="https://www.businessinsider.com/google-hiring-dos-and-donts-2014-10">(source)</a></b>     
        
          <br />
          <br />
          <p>Hiring internationally and remotely gives you the chance to hire not just the smartest person in the room, but in the world. Barring time zone preferences, the pool you have to fish from just got considerably larger. These candidates bring it all to the table, smarts, diversity, salary range, flexible thinking, and more is left to discover as companies begin to prioritize the best, not only the closest best. 
          </p>
          <br />
          <p>Of course, North American universities are filled with international students with incredible output, but not all stay on the continent. For a vast variety of reasons, many choose or are forced to go back home or abroad. They can put all their talent to use working remotely and maintaining a quality of life that would be impossible with current salaries in own countries. It's an incredible opportunity to reward brilliance and create some justice too, not to mention results for your business. 
          <Row>
            <Image
              style={{ maxHeight: "450px", backgroundColor: "transparent", }}
              src={LeidenRankingCWTS}
              alt="virtual hiring"
            />
          </Row>
          <b><a href="https://www.leidenranking.com/ranking/2023/map">(source)</a></b>     
          </p>
          <br />
          <br />
          <b>Taxes & payroll  - EOR</b>
        <br />
        <p>
        Hiring internationally can be daunting when thinking about the different laws that apply in various countries, the expense involved in hiring lawyers or consultants and the bank transfer costs.  
        </p>
        <br />
        <p>
        Luckily, at this point, the solution that takes care of all of these aspects has been created in EOR. This acronym stands for Employer of Record. If your company doesn’t have a branch in many countries, an EOR can onboard talent and take care of payroll and contracts, freeing your business from administrative burdens and enabling you to focus on managing, creating and succeeding.   
        </p>
        <Row>
            <Image
              style={{ maxHeight: "350px", backgroundColor: "transparent", }}
              src={EmployerOfRecordGlobalMarketSizeGrowthRate}
              alt="remote talent"
            />
          </Row>
          <p>
          Book a call with us, we can act as your EOR and find the best candidate for your business, no fees involved, and up to 70% in salary savings.   
        </p>
        </Content>
      </ContentWithPaddingXl>
      <GetStarted setOpenCalendly={setOpenCalendly} setUrl={setUrl}/>
      <PopupModal
        open={openCalendly}
        onModalClose={() => setOpenCalendly(false)}
        rootElement={document.getElementById("root")}
        url={url}
      ></PopupModal>
    </Container>
    </>
  );
}
