import React from "react";
import Header from "../components/headers/Header.js";
import Pricing from "components/pricing/Pricing.js"
import GridTestimonials from "components/trutbox/GridTestimonials.js";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from 'react';
import { CurrentPage } from '../components/context/CurentPage.js';

import { PopupModal } from "react-calendly";

export default () => {

  const [openCalendly, setOpenCalendly] = useState(false);
  const [url, setUrl] = useState("");


  const {currentPage, setCurrentPage} = useContext(CurrentPage);

  useEffect(() => {
    setCurrentPage("Pricing");
    console.log(currentPage);  
  });

  return (
    <>
      <Header roundedHeaderButton={true} />
      <Pricing setOpenCalendly={setOpenCalendly} setUrl={setUrl}/>
      {/* <GridTestimonials /> */}
      <PopupModal
        open={openCalendly}
        onModalClose={() => setOpenCalendly(false)}
        rootElement={document.getElementById("root")}
        url={url}
      ></PopupModal>
    </>
  );
};