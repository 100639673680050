import React from 'react';
import Header from "../components/headers/Header.js";
import Jobs from "../components/jobs/Jobs"
import { useEffect } from "react";
import { useContext } from 'react';
import { CurrentPage } from '../components/context/CurentPage';

export default function JobsPage() {

  const {currentPage, setCurrentPage} = useContext(CurrentPage);

  useEffect(() => {
    setCurrentPage("Jobs");
    console.log(currentPage);  
  });

  return (
    <>
    <Header roundedHeaderButton={true} />
    <Jobs />
    </>
  );
}