import React from "react";
import tw from "twin.macro";
import Header from "../../components/headers/Header";

import { useState, useEffect } from "react";
import { PopupModal } from "react-calendly";


const Container = tw.div` relative my-8 lg:my-10 -mx-8 `;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 bg-gray-100 shadow border-primary-900`;
const HeadingContainer = tw.div`w-full`;
const Heading = tw.h1`text-gray-800 text-left lg:px-16 px-8 py-2 text-4xl lg:text-5xl`;
const Row = tw.div`px-8 py-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col text-center`;
const Image = tw.img`max-w-full bg-gray-lightest w-auto rounded-t sm:rounded relative z-20 place-items-center`;
const Intro = tw.h2`text-gray-800 text-left lg:px-16 px-8 text-2xl py-2`;
const Content = tw.p`mt-6 text-gray-900 text-left lg:px-16 px-8 py-2`;
const ImageDIv = tw.div`flex flex-nowrap md:mt-10 md:ml-10 md:mb-10 mt-3`
const TextDiv = tw.div`flex flex-wrap`
const Text100 = tw.b`w-full`


export default function PrivacyPolicy() {

    const [openCalendly, setOpenCalendly] = useState(false);
    const [url, setUrl] = useState("");

    return (
        <>
            <Header roundedHeaderButton={true} />
            <Container>
                <ContentWithPaddingXl>
                    <HeadingContainer>
                        <Heading>Privacy Policy</Heading>

                        <ImageDIv>

                            <TextDiv>
                                <Text100>Last Updated: 06/26/2024</Text100>
                            </TextDiv>
                        </ImageDIv>

                        <Row>

                        </Row>
                    </HeadingContainer>
                    <Intro>
                        Welcome to Virtustant. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website and use our services.                        <br />
                    </Intro>

                    <Content>

                        <br />
                        <b>1. Information We Collect</b>
                        <br />
                        <br />
                        <p>
                            We collect information that is necessary for the recruitment process and for our clients to select the best candidates. We use information we collect to provide the best and most personalized experience possible.
                            We keep information only for as long as is necessary to provide our services, comply with legal obligations or protect our interests
                        </p>
                        <Row>

                        </Row>
                        <b>2. How We Use Your Information
                        </b>
                        <br />
                        <br />
                        <p>
                            We use the information we collect for the following purposes:
                            <br />
                            - Recruitment Process: To match candidates with job openings, assess qualifications, and communicate with candidates regarding their applications.
                            <br />
                            - Client Services: To provide clients with suitable candidates for their job openings.
                            <br />
                            - Website Improvement: To analyze website usage and improve our services and user experience.
                            <br />
                            - Marketing:  You may receive marketing communications from us if you've given consent or shown interest in our products. We do not sell your personal information to third parties for marketing purposes.
                            <br />
                            - Compliance and Legal Obligations: To comply with legal requirements and protect our legal rights.

                        </p>

                        <br />

                        <b>3. Sharing Your Information</b>
                        <br />
                        <br />
                        <p>We may share your information with:
                            <br />
                            - Clients: To provide them with suitable candidates for their job openings.
                            <br />
                            - Service Providers: Third-party vendors who provide services on our behalf, such as IT support and data analytics.
                            <br />
                            - Legal Authorities: When required by law or to protect our rights.

                        </p>
                        <br />

                        <b>4. International Data Transfers
                        </b>
                        <br />
                        <br />
                        <p>As an international staffing agency, we may transfer your personal information to countries outside of your home country, including the United States. We ensure that such transfers are conducted in compliance with applicable data protection laws and take appropriate measures to protect your information.
                        </p>
                        <br />
                        <br />
                        <b>5. Data Security
                        </b>
                        <br />
                        <br />
                        <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no data transmission over the internet can be guaranteed to be completely secure.
                        </p>
                        <br />
                        <br /><b>6. Your Rights
                        </b>
                        <br />
                        <br />
                        <p>You have the following rights regarding your personal information:
                            <br />
                            - Access: To request a copy of the personal information we hold about you.
                            <br />
                            - Rectification: To request correction of any inaccurate or incomplete information.
                            <br />
                            - Deletion: To request deletion of your personal information, subject to legal obligations.
                            <br />
                            - Restriction: To request restriction of processing of your personal information.
                            <br />
                            - Objection: To object to the processing of your personal information for certain purposes.
                        </p>
                        <br />
                        <br /><b>7. Cookies and Tracking Technologies

                        </b>
                        <br />
                        <br />
                        <p>We use cookies and similar tracking technologies to enhance your experience on our website. For more information, please refer to our Cookie Policy.</p>
                        <br />
                        <br /><b>8. Changes to This Privacy Policy
                        </b>
                        <br />
                        <br />
                        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically.</p>
                        <br />
                        <br />
                    </Content>
                    <HeadingContainer>
                        <Heading>Cookie Policy</Heading>

                        <ImageDIv>

                            <TextDiv>
                                <Text100>Last Updated: 06/26/2024</Text100>
                            </TextDiv>
                        </ImageDIv>
                        <Intro>
                            This Cookie Policy explains how Virtustant uses cookies and similar tracking technologies on our website.  <br />
                        </Intro>
                    </HeadingContainer>
                    <Content>
                        <br />
                        <br />
                        <b>
                            1. What Are Cookies?
                        </b>
                        <br />
                        <br />
                        <p>
                            Cookies are small text files placed on your device by websites you visit. They are widely used to make websites work more efficiently and provide information to website owners.
                        </p>
                        <Row>

                        </Row>
                        <b>2. How We Use Cookies

                        </b>
                        <br />
                        <br />
                        <p>
                            We use cookies to:
                            <br />
                            - Enhance Website Functionality: Enable essential website functions and improve performance.
                            <br />
                            - Analyze Usage: Collect information about how visitors use our website to improve user experience.
                            <br />
                            - Marketing: Deliver relevant advertisements and track ad performance.


                        </p>

                        <br />

                        <b>3. Types of Cookies We Use
                        </b>
                        <br />
                        <br />
                        <p>
                            - Essential Cookies: Necessary for the website to function properly.
                            <br />
                            - Performance Cookies: Collect information about how visitors use our website.
                            <br />
                            - Functional Cookies: Allow the website to remember choices you make and provide enhanced features.
                            <br />
                            - Targeting Cookies: Used to deliver relevant ads and track the effectiveness of advertising campaigns.


                        </p>
                        <br />

                        <b>4. Managing Cookies
                        </b>
                        <br />
                        <br />
                        <p>You can manage your cookie preferences through your browser settings. However, disabling cookies may affect the functionality and performance of our website.

                        </p>
                        <br />
                        <br />
                        <b>5. Changes to This Cookie Policy
                        </b>
                        <br />
                        <br />
                        <p>We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated effective date.
                        </p>
                        <br />
                        <br />
                    </Content>
                </ContentWithPaddingXl>

            </Container>
        </>
    );
}
